<template>
    <div class="d-flex justify-content-center align-items-center">
        <div v-if="isLoading">
          <div class="loader" style="margin-top: 400px;"></div>
        </div>
        
        <div v-else class="card p-4 w-50">
            <h2 class="text-center mb-4">Login</h2>
            <form @submit.prevent="login">
            <div class="mb-3">
                <label for="userEmail" class="form-label">Email address</label>
                <input type="email" class="form-control" id="userEmail" v-model="formData.userEmail" @blur="emailTouched = true">
            </div>

            <div class="mb-3">
                <label for="userPassword" class="form-label">Password</label>
                <div class="input-group">
                    <input :type="passwordFieldType" class="form-control" id="userPassword" v-model="formData.userPassword" @blur="passwordTouched = true">
                    <button class="btn btn-outline-secondary btn-password-toggle" type="button" @click="togglePasswordVisibility">
                        <i :class="passwordIcon"></i>
                    </button>
            </div>
        </div>

        <div v-if="loginError" class="error-message">{{ loginError }}</div>

        <button type="submit" class="btn btn-primary">Login</button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, computed, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'

import image from "./icons/logo_storalytic.png"

import { useUserStore } from '@/stores/UserStore'
import { useCompanyStore } from '@/stores/CompanyStore'
import { useFlowStore } from '@/stores/FlowStore'

export default {
    name: 'Login',

    setup() {
        const router = useRouter()

        const userStore = useUserStore()
        const companyStore = useCompanyStore()
        const flowStore = useFlowStore()
        

        const passwordVisible = ref(false)
        const emailTouched = ref(false)
        const passwordTouched = ref(false)
        const loginError = ref(null)

        const formData = ref({
            userEmail: '',
            userPassword: ''
        })

        const isLoading = ref(false)

        // Reactive properties for password visibility
        const passwordFieldType = computed(() => passwordVisible.value ? 'text' : 'password')
        const passwordIcon = computed(() => passwordVisible.value ? 'bi bi-eye-fill' : 'bi bi-eye-slash-fill')

        // Method to toggle password visibility
        const togglePasswordVisibility = () => {
            passwordVisible.value = !passwordVisible.value
        }

        const login = async () => {
            try {
                isLoading.value = true

                await userStore.loginUser(formData.value)
                await companyStore.fetchCompanyDetails(userStore.user.CompanyId)
                await flowStore.fetchFlows()
                

                formData.value = {
                    userEmail: '',
                    userPassword: ''
                }

                router.push('/')
            } catch (error) {
                isLoading.value = false

                if (error.response && error.response.status === 401) {
                    loginError.value = "Invalid login credentials. Please try again."
                } else {
                    loginError.value = "An error occurred. Please try again later."
                }
                console.error("Login failed: ", error.message);
            }
        }

        onBeforeUnmount(() => {
                isLoading.value = false
        })

        return {
            logo_applicaite: image,
            formData,
            login,
            loginError,
            passwordFieldType,
            togglePasswordVisibility,
            passwordIcon,
            emailTouched,
            passwordTouched,
            isLoading,
        }
    }
}
</script>

<style scoped>

.btn {
  background-color: #4F7EB3;
  border: none;
  padding: 5px 10px; /* Adds padding inside the button */
  white-space: nowrap; /* Prevents text from wrapping inside the button */
}

.btn:hover {
  background-color: #B2C149;
}

.btn-password-toggle {
    background-color: white;
    border: 1px solid;
    border-color: #CED4DA;
}

.card {
  max-width: 600px;
  min-width: 450px;
}

.form-control {
  max-width: 500px;
}
.card h2, .card h5 {
  color: #333;
}

.error-message{
    margin-bottom: 15px;
    color: #C21500;
}

.loader {
  width: 80px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}
</style>