<template>
    <div>
  
      <div style="font-size: small;">
        <div class="form-check form-check-inline">
          <input type="radio" id="dailyBase" class="form-check-input" value="daily" v-model="selectedGranularity" @change="emitDataGranularity">
          <label class="form-check-label" for="dailyBase">Daily base</label>
        </div>
        <div class="form-check form-check-inline">
          <input type="radio" id="hourlyBase" class="form-check-input" value="hourly" v-model="selectedGranularity" @change="emitDataGranularity">
          <label class="form-check-label" for="hourlyBase">Hourly base</label>
        </div>
      </div>

    </div>
  </template>

<script>
import { ref, watch, } from 'vue'

export default {
    name: 'DataGranularity',

    components: {

    },

    props: {
      dataGranularity: {
          type: String,
          default: 'daily'
        },
      isTableTabEventList: {
          type: Boolean,
          default: false
      }
    },

    emits: ['updateDataGranularity'],
    
    setup(props, { emit }) {
        const selectedGranularity = ref(props.dataGranularity)
        const disableDataGranularity = ref(props.isTableTabEventList)

        watch(() => props.dataGranularity, (newValue) => {
            selectedGranularity.value = newValue
        }, { immediate: true } );

        watch(() => props.isTableTabEventList, (newValue) => {
          disableDataGranularity.value = newValue
        }, { immediate: true } );

        const emitDataGranularity = () => {
            emit('updateDataGranularity', selectedGranularity.value);
        };

        return {
          selectedGranularity,
          emitDataGranularity,
          disableDataGranularity,
        }
    }
}
</script>

<style scoped>

</style>