<template>
    <div class="card" style="margin-top: 10px; margin-bottom: 10px;">
        <div class="card-header card-main-header d-flex align-items-center">
            <h5 class="card-title card-main-title" style="color: #B2C149">Highlights by Storalytic:</h5>
            <duration-dropdown
                :dateRangeMode="dateRangeMode" 
                :selectedDuration="selectedDuration" 
                @updateDuration="handleUpdateDuration">
            </duration-dropdown>
        </div>

        <div class="card-body">
            <div class="card" :class="{ 'group-disabled': !isEntranceZonePresent() && !isCombinedEntranceExitZonePresent()}">
                <div class="card-header">
                    <h6 class="card-title" :style="{ color: !isEntranceZonePresent() && !isCombinedEntranceExitZonePresent() ? 'gray' : '#36910d' }">Store Visitor Intelligence</h6>
                </div>

                <!-- Heatmap and Entrance Chart Row -->
                <div class="card-container-heatmap-row" :style="{ height: `${computedHeight}px` }">
                    <div class="heatmap-container">
                        <heatmap-dashboard :heatmapData="heatmapData" :selectedDuration="selectedDuration"></heatmap-dashboard>
                    </div>
                    <div class="entrance-chart-container">
                        <charts-dashboard-entrance :chartData="entranceChartData" :selectedDuration="selectedDuration"></charts-dashboard-entrance>
                    </div>
                </div>

                <!-- Cards for Store Visitor Intelligence -->
                <div class="grid-container-store-visitor-data">
                    <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(diffTodayAboveAverage) }">
                        <div class="card-header counter-card-header">
                            <p class="card-title counter-card-title">
                                <b>Average</b> Visitors
                            </p>
                        </div>
                        <div class="card-body count">
                            <div v-if="isLoadingAverage">
                                <div class="loader"></div>
                            </div>
                            <div v-else-if="!isEntranceZonePresent() && !isCombinedEntranceExitZonePresent()">
                                <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Entrance Zones Defined</p>
                            </div>
                            <div v-else-if="isCardEmpty(averageValue)">
                                <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                            </div>
                            <div v-else style="text-align: center; color: #36910d">
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p style="margin-top: 10px;"><b> {{ averageValue }} </b></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(diffTodayAboveSameWeekdayAverage) }">
                        <div class="card-header counter-card-header">
                            <p class="card-title counter-card-title">
                                <b>{{ currentWeekdayString }}'s Average</b> Visitors
                            </p>
                        </div>
                        <div class="card-body count">
                            <div v-if="isLoadingAverageSameWeekday">
                                <div class="loader"></div>
                            </div>
                            <div v-else-if="!isEntranceZonePresent() && !isCombinedEntranceExitZonePresent()">
                                <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Entrance Zones Defined</p>
                            </div>
                            <div v-else-if="isCardEmpty(averageValueSameWeekday)">
                                <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                            </div>
                            <div v-else style="text-align: center; color: #36910d">
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p style="margin-top: 10px;"><b> {{ averageValueSameWeekday }} </b></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(trend) || (!isEntranceZonePresent()&& !isCombinedEntranceExitZonePresent())}">
                        <div class="card-header counter-card-header">
                            <p class="card-title counter-card-title">
                                Visitor <b>Trend</b>
                            </p>
                        </div>
                        <div class="card-body count">
                            <div v-if="isLoadingTrend">
                                <div class="loader"></div>
                            </div>
                            <div v-else-if="!isEntranceZonePresent() && !isCombinedEntranceExitZonePresent()">
                                <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Entrance Zones Defined</p>
                            </div>
                            <div v-else-if="isCardEmpty(trend) || (!isEntranceZonePresent() && !isCombinedEntranceExitZonePresent())">
                                <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                            </div>
                            <div v-else style="text-align: center;">
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p v-if="trend == 'high upward'" style="color: #2a6041; margin-top: 0px;">
                                        <i class="bi bi-arrow-up-square" style="margin-right: 8px; font-size: x-large;"></i>
                                        <b style="font-size: large;">Up High</b>
                                    </p>
                                    <p v-if="trend == 'medium upward'" style="color: #36910d; margin-top: 0px;">
                                        <i class="bi bi-arrow-up-square" style="margin-right: 8px; font-size: x-large;"></i>
                                        <b style="font-size: large;">Up</b>
                                    </p>
                                    <p v-else-if="trend == 'light upward'" style="color: #2ceaa3; margin-bottom: 0;">
                                        <i class="bi bi-arrow-up-square" style="margin-right: 8px; font-size: x-large;"></i>
                                        <b style="font-size: large;">Up Light</b>
                                    </p>
                                    <p v-else-if="trend == 'stable'" style="color: #000000; margin-bottom: 0;">
                                        <i class="bi bi-arrow-right-square" style="margin-right: 8px; font-size: x-large;"></i>
                                        <b style="font-size: large;">Stable</b>
                                    </p>
                                    <p v-else-if="trend == 'light downward'" style="color: #a7333f; margin-bottom: 0;">
                                        <i class="bi bi-arrow-down-square" style="margin-right: 8px; font-size: x-large;"></i>
                                        <b style="font-size: large;">Down Light</b>
                                    </p>
                                    <p v-else-if="trend == 'medium downward'" style="color: #C21500; margin-bottom: 0;">
                                        <i class="bi bi-arrow-down-square" style="margin-right: 8px; font-size: x-large;"></i>
                                        <b style="font-size: large;">Down</b>
                                    </p>
                                    <p v-else-if="trend == 'high downward'" style="color: #8b0000; margin-bottom: 0;">
                                        <i class="bi bi-arrow-down-square" style="margin-right: 8px; font-size: x-large;"></i>
                                        <b style="font-size: large;">Down High</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(formattedHighestTotalHour.formattedDate) }">
                        <div class="card-header counter-card-header">
                            <p class="card-title counter-card-title">
                                Top Visitor <b>Hour</b>
                            </p>
                        </div>
                        <div class="card-body count">
                            <div v-if="isLoadingHighestHour">
                                <div class="loader"></div>
                            </div>
                            <div v-else-if="!isEntranceZonePresent() && !isCombinedEntranceExitZonePresent()">
                                <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Entrance Zones Defined</p>
                            </div>
                            <div v-else-if="isCardEmpty(formattedHighestTotalHour.formattedDate)">
                                <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                            </div>
                            <div v-else style="text-align: center;">
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p style="margin-bottom: 0px; color: #36910d"><b> {{ formattedHighestTotalHour.formattedDate }} | {{ formattedHighestTotalHour.formattedTime }}</b></p>
                                    <span style="margin-top: 8px;">{{ highestTotalHourCount }} Visitors</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(formattedHighestTotalDay.formattedDate) }">
                        <div class="card-header counter-card-header">
                            <p class="card-title counter-card-title">
                                Top Visitor <b>Day</b>
                            </p>
                        </div>
                        <div class="card-body count">
                            <div v-if="isLoadingHighestDay">
                                <div class="loader"></div>
                            </div>
                            <div v-else-if="!isEntranceZonePresent() && !isCombinedEntranceExitZonePresent()">
                                <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Entrance Zones Defined</p>
                            </div>
                            <div v-else-if="isCardEmpty(formattedHighestTotalDay.formattedDate)">
                                <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                            </div>
                            <div v-else style="text-align: center;">
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p style="margin-bottom: 0px; color: #36910d"><b> {{ formattedHighestTotalDay.formattedDate }} </b></p>
                                    <span style="margin-top: 8px;">{{ highestTotalDayCount }} Visitors</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(busiestHour) }">
                        <div class="card-header counter-card-header">
                            <p class="card-title counter-card-title">
                                <b>Busiest</b> Hour on Average
                            </p>
                        </div>
                        <div class="card-body count">
                            <div v-if="isLoadingBusiestHour">
                                <div class="loader"></div>
                            </div>
                            <div v-else-if="!isEntranceZonePresent() && !isCombinedEntranceExitZonePresent()">
                                <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Entrance Zones Defined</p>
                            </div>
                            <div v-else-if="isCardEmpty(busiestHour)">
                                <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                            </div>
                            <div v-else style="text-align: center;">
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p style="margin-bottom: 0px; color: #36910d"><b> {{ formattedBusiestHour }} </b></p>
                                    <span style="margin-top: 8px;">{{ highestAverageHourlyCount }} Visitors</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="!isCardEmpty(averageVisitDuration)" class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(averageVisitDuration) }">
                        <div class="card-header counter-card-header">
                            <p class="card-title counter-card-title">
                                Average Visit Duration
                            </p>
                        </div>
                        <div class="card-body count">
                            <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                <div v-if="isLoadingleastUsedCheckout">
                                    <div class="loader"></div>
                                </div>
                                <div v-else-if="!isExitZonePresent() && !isCombinedEntranceExitZonePresent()">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Exit Zones Defined</p>
                                </div>
                                <div v-else-if="isCardEmpty(averageVisitDuration)">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                </div>
                                <div v-else style="text-align: center;">
                                    <p style="margin-top: 0px;">No exit camera<br>connected</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-container-second-row">

                <div class="card" :class="{ 'group-disabled': !isCheckoutWaitingZonePresent() }">
                    <div class="card-header">
                        <h6 class="card-title" :style="{ color: !isCheckoutWaitingZonePresent() ? 'gray' : '#AD3935' }">Checkout Waiting Zone Intelligence</h6>
                    </div>

                    <div class="card-body grid-container-entrance-data">
                        <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(averageWaitingTime) }">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Average</b> Waiting Time
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoadingaverageWaitingTime">
                                    <div class="loader"></div>
                                </div>
                                <div v-else-if="!isCheckoutWaitingZonePresent()">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Checkout Waiting Zones Defined</p>
                                </div>
                                <div v-else-if="isCardEmpty(averageWaitingTime)">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                </div>
                                <div v-else style="text-align: center; color: #AD3935">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-top: 10px;"><b> {{ formattedDurationTime(averageWaitingTime) }} </b></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(maxWaitingDuration) }">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Highest</b> Waiting Time
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoadingmaxWaitingDuration">
                                    <div class="loader"></div>
                                </div>
                                <div v-else-if="!isCheckoutWaitingZonePresent()">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Checkout Waiting Zones Defined</p>
                                </div>
                                <div v-else-if="isCardEmpty(maxWaitingDuration)">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                </div>
                                <div v-else style="text-align: center; color: #AD3935">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-bottom: 0px;"><b> {{ formattedDurationTime(maxWaitingDuration) }} </b></p>
                                        <span style="margin-top: 8px;">at {{ maxWaitingZoneName }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(mostTypicalWaitingTime) }">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Typical</b> Waiting Time
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoadingmostTypicalWaitingTime">
                                    <div class="loader"></div>
                                </div>
                                <div v-else-if="!isCheckoutWaitingZonePresent()">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Checkout Waiting Zones Defined</p>
                                </div>
                                <div v-else-if="isCardEmpty(mostTypicalWaitingTime)">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                </div>
                                <div v-else style="text-align: center; color: #AD3935">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-bottom: 0px"><b> {{ formattedDurationTime(mostTypicalWaitingTime) }} </b></p>
                                        <span style="margin-top: 8px;">{{ amountMostTypicalWaitingTime }} times</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(fastestCheckoutZone) }">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Fastest</b> Waiting Line
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoadingfastestCheckoutZone">
                                    <div class="loader"></div>
                                </div>
                                <div v-else-if="!isCheckoutWaitingZonePresent()">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Checkout Waiting Zones Defined</p>
                                </div>
                                <div v-else-if="isCardEmpty(fastestCheckoutZone)">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                </div>
                                <div v-else style="text-align: center; color: #AD3935">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-top: 10px"><b> {{ fastestCheckoutZone }} </b></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(slowestCheckoutZone) }">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Slowest</b> Waiting Line
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoadingslowestCheckoutZone">
                                    <div class="loader"></div>
                                </div>
                                <div v-else-if="!isCheckoutWaitingZonePresent()">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Checkout Waiting Zones Defined</p>
                                </div>
                                <div v-else-if="isCardEmpty(slowestCheckoutZone)">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                </div>
                                <div v-else style="text-align: center; color: #AD3935">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-top: 10px;"><b> {{ slowestCheckoutZone }} </b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card" :class="{ 'group-disabled': !isCheckoutInteractionZonePresent() }">
                    <div class="card-header">
                        <h6 class="card-title" :style="{ color: !isCheckoutInteractionZonePresent() ? 'gray' : '#D3A741' }">Checkout Interaction Zone Intelligence</h6>
                    </div>

                    <div class="card-body grid-container-entrance-data">
                        <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(averageInteractionTime) }">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Average</b> Interaction Time
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoadingaverageInteractionTime">
                                    <div class="loader"></div>
                                </div>
                                <div v-else-if="!isCheckoutInteractionZonePresent()">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Checkout Interaction Zones Defined</p>
                                </div>
                                <div v-else-if="isCardEmpty(averageInteractionTime)">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                </div>
                                <div v-else style="text-align: center; color: #D3A741">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-top: 10px;"><b> {{ formattedDurationTime(averageInteractionTime) }} </b></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(maxInteractionDuration) }">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Highest</b> Interaction Time
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoadingmaxInteractionDuration">
                                    <div class="loader"></div>
                                </div>
                                <div v-else-if="!isCheckoutInteractionZonePresent()">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Checkout Interaction Zones Defined</p>
                                </div>
                                <div v-else-if="isCardEmpty(maxInteractionDuration)">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                </div>
                                <div v-else style="text-align: center; color: #D3A741">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-bottom: 0px;"><b> {{ formattedDurationTime(maxInteractionDuration) }} </b></p>
                                        <span style="margin-top: 8px;">at {{ maxInteractionZoneName }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(mostTypicalInteractionTime) }">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Typical</b> Interaction Time
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoadingmostTypicalInteractionTime">
                                    <div class="loader"></div>
                                </div>
                                <div v-else-if="!isCheckoutInteractionZonePresent()">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Checkout Interaction Zones Defined</p>
                                </div>
                                <div v-else-if="isCardEmpty(mostTypicalInteractionTime)">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                </div>
                                <div v-else style="text-align: center; color: #D3A741">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-bottom: 0px;"><b> {{ formattedDurationTime(mostTypicalInteractionTime) }} </b></p>
                                        <span style="margin-top: 8px;">{{ amountMostTypicalInteractionTime }} times</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(fastestInteractionZone) }">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Fastest</b> Interaction Checkout
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoadingfastestInteractionZone">
                                    <div class="loader"></div>
                                </div>
                                <div v-else-if="!isCheckoutInteractionZonePresent()">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Checkout Interaction Zones Defined</p>
                                </div>
                                <div v-else-if="isCardEmpty(fastestInteractionZone)">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                </div>
                                <div v-else style="text-align: center; color: #D3A741">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-top: 10px"><b> {{ fastestInteractionZone }} </b></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(slowestInteractionZone) }">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Slowest</b> Interaction Checkout
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoadingslowestInteractionZone">
                                    <div class="loader"></div>
                                </div>
                                <div v-else-if="!isCheckoutInteractionZonePresent()">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Checkout Interaction Zones Defined</p>
                                </div>
                                <div v-else-if="isCardEmpty(slowestInteractionZone)">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                </div>
                                <div v-else style="text-align: center; color: #D3A741">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-top: 10px;"><b> {{ slowestInteractionZone }} </b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="card" :class="{ 'group-disabled': !isCheckoutInteractionZonePresent() }">
                    <div class="card-header">
                        <h6 class="card-title" :style="{ color: !isCheckoutInteractionZonePresent() ? 'gray' : '#dd8725' }">General Checkout Intelligence</h6>
                    </div>

                    <div class="card-body grid-container-entrance-data">
                        <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(mostUsedCheckout.zone_name) }">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Most</b> Used Checkout
                                </p>
                            </div>
                            <div class="card-body count">
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <div v-if="isLoadingmostUsedCheckout">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else-if="!isCheckoutInteractionZonePresent()">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Checkout Interaction Zones Defined</p>
                                    </div>
                                    <div v-else-if="isCardEmpty(mostUsedCheckout.zone_name)">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                    </div>
                                    <div v-else style="text-align: center; color: #dd8725">
                                        <p style="margin-bottom: 0px"><b> {{ mostUsedCheckout.zone_name }} </b></p>
                                        <span style="margin-top: 8px;">{{ mostUsedCheckout.visitor_count }} Interactions</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(leastUsedCheckout.zone_name) }">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Least</b> Used Checkout
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoadingleastUsedCheckout">
                                    <div class="loader"></div>
                                </div>
                                <div v-else-if="!isCheckoutInteractionZonePresent()">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Checkout Interaction Zones Defined</p>
                                </div>
                                <div v-else-if="isCardEmpty(leastUsedCheckout.zone_name)">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                </div>
                                <div v-else style="text-align: center; color:#dd8725">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-bottom: 0px"><b> {{ leastUsedCheckout.zone_name }} </b></p>
                                        <span style="margin-top: 8px;">{{ leastUsedCheckout.visitor_count }} Interactions</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="isProductZonePresent()" class="product-zone-block">
                <div class="card" :class="{ 'group-disabled': !isProductZonePresent() }">
                    <div class="card-header">
                        <h6 class="card-title" :style="{ color: !isProductZonePresent() ? 'gray' : '#004F6E' }">Product Zone Intelligence</h6>
                    </div>

                    <div class="card-body grid-container-product-data">
                        <div class="card-stack">
                            <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(bestProductZoneByAvgDuration.zone_name) }">
                                <div class="card-header counter-card-header">
                                    <p class="card-title counter-card-title">
                                        Top <b>Avg. Duration</b>
                                    </p>
                                </div>
                                <div class="card-body count">
                                    <div v-if="isLoadingbestProductZoneInCounts">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else-if="!isProductZonePresent()">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Product Zones Defined</p>
                                    </div>
                                    <div v-else-if="isCardEmpty(bestProductZoneByAvgDuration.zone_name)">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                    </div>
                                    <div v-else style="text-align: center; color: #004F6E">
                                        <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                            <p style="margin-bottom: 0px;"><b> {{ bestProductZoneByAvgDuration.zone_name }} </b></p>
                                            <span style="margin-top: 8px;">Average Duration: {{ formattedDurationTime(bestProductZoneByAvgDuration.avg_duration) }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(bestProductZoneByVisitors.zone_name) }">
                                <div class="card-header counter-card-header">
                                    <p class="card-title counter-card-title">
                                        <b>Top</b> Visitor Count
                                    </p>
                                </div>
                                <div class="card-body count">
                                    <div v-if="isLoadingbestProductZoneInCounts">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else-if="!isProductZonePresent()">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Product Zones Defined</p>
                                    </div>
                                    <div v-else-if="isCardEmpty(bestProductZoneByVisitors.zone_name)">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                    </div>
                                    <div v-else style="text-align: center; color: #004F6E">
                                        <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                            <p style="margin-bottom: 0px;"><b> {{ bestProductZoneByVisitors.zone_name }} </b></p>
                                            <span style="margin-top: 8px;">{{ bestProductZoneByVisitors.visitor_count }} Visitors</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(worstProductZoneByVisitors.zone_name) }">
                                <div class="card-header counter-card-header">
                                    <p class="card-title counter-card-title">
                                        <b>Lowest</b> Visitor Count
                                    </p>
                                </div>
                                <div class="card-body count">
                                    <div v-if="isLoadingworstProductZoneInCounts">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else-if="!isProductZonePresent()">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Product Zones Defined</p>
                                    </div>
                                    <div v-else-if="isCardEmpty(worstProductZoneByVisitors.zone_name)">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                    </div>
                                    <div v-else style="text-align: center; color: #004F6E">
                                        <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                            <p style="margin-bottom: 0px;"><b> {{ worstProductZoneByVisitors.zone_name }} </b></p>
                                            <span style="margin-top: 8px;">{{ worstProductZoneByVisitors.visitor_count }} Visitors</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="chart-container">
                            <div v-if="isLoadingworstProductZoneInCounts" class="loader-overlay">
                                <div class="loader"></div>
                            </div>
                            <div v-else>
                                <charts-fixed-widgets-product class="full-width-chart" :totalDurationPerProductZone="totalDurationPerProductZone" :percentageByDurationRangeInProductZone="percentageByDurationRangeInProductZone" :productZonePresent="isProductZonePresent()"></charts-fixed-widgets-product>
                            </div>
                        </div>
                    </div>

                    <!-- Chart Row -->
                    <div class="card-container-pdd-row">
                        <div class="entrance-chart-container">
                            <charts-dashboard-pdd :chartData="bestProductAvgDur" :selectedDuration="selectedDuration" :zoneType="'Product'" :chartType="'AvgDur'"></charts-dashboard-pdd>
                        </div>
                        <div class="entrance-chart-container">
                            <charts-dashboard-pdd :chartData="bestProductCount" :selectedDuration="selectedDuration" :zoneType="'Product'" :chartType="'Counts'"></charts-dashboard-pdd>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="isDemoZonePresent()" class="product-zone-block" style="margin-top: 100px;">
                <div class="card" :class="{ 'group-disabled': !isDemoZonePresent() }">
                    <div class="card-header">
                        <h6 class="card-title" :style="{ color: !isDemoZonePresent() ? 'gray' : '#62B5D9' }">Demo Zone Intelligence</h6>
                    </div>

                    <div class="card-body grid-container-product-data">
                        <div class="card-stack">
                            <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(bestDemoZoneByAvgDuration.zone_name) }">
                                <div class="card-header counter-card-header">
                                    <p class="card-title counter-card-title">
                                        Top <b>Avg. Duration</b>
                                    </p>
                                </div>
                                <div class="card-body count">
                                    <div v-if="isLoadingbestDemoZoneInCounts">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else-if="!isDemoZonePresent()">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Demo Zones Defined</p>
                                    </div>
                                    <div v-else-if="isCardEmpty(bestDemoZoneByAvgDuration.zone_name)">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                    </div>
                                    <div v-else style="text-align: center; color: #62B5D9">
                                        <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                            <p style="margin-bottom: 0px;"><b> {{ bestDemoZoneByAvgDuration.zone_name }} </b></p>
                                            <span style="margin-top: 8px;">Average Duration: {{ formattedDurationTime(bestDemoZoneByAvgDuration.avg_duration) }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(bestDemoZoneByVisitors.zone_name) }">
                                <div class="card-header counter-card-header">
                                    <p class="card-title counter-card-title">
                                        <b>Top</b> Visitor Count
                                    </p>
                                </div>
                                <div class="card-body count">
                                    <div v-if="isLoadingbestDemoZoneInCounts">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else-if="!isDemoZonePresent()">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Demo Zones Defined</p>
                                    </div>
                                    <div v-else-if="isCardEmpty(bestDemoZoneByVisitors.zone_name)">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                    </div>
                                    <div v-else style="text-align: center; color: #62B5D9">
                                        <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                            <p style="margin-bottom: 0px;"><b> {{ bestDemoZoneByVisitors.zone_name }} </b></p>
                                            <span style="margin-top: 8px;">{{ bestDemoZoneByVisitors.visitor_count }} Visitors</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(worstDemoZoneByVisitors.zone_name) }">
                                <div class="card-header counter-card-header">
                                    <p class="card-title counter-card-title">
                                        <b>Lowest</b> Visitor Count
                                    </p>
                                </div>
                                <div class="card-body count">
                                    <div v-if="isLoadingworstDemoZoneInCounts">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else-if="!isDemoZonePresent()">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Demo Zones Defined</p>
                                    </div>
                                    <div v-else-if="isCardEmpty(worstDemoZoneByVisitors.zone_name)">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                    </div>
                                    <div v-else style="text-align: center; color: #62B5D9">
                                        <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                            <p style="margin-bottom: 0px;"><b> {{ worstDemoZoneByVisitors.zone_name }} </b></p>
                                            <span style="margin-top: 8px;">{{ worstDemoZoneByVisitors.visitor_count }} Visitors</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="chart-container">
                            <div v-if="isLoadingworstDemoZoneInCounts" class="loader-overlay">
                                <div class="loader"></div>
                            </div>
                            <div v-else>
                                <charts-fixed-widgets-demo class="full-width-chart" :totalDurationPerDemoZone="totalDurationPerDemoZone" :percentageByDurationRangeInDemoZone="percentageByDurationRangeInDemoZone" :demoZonePresent="isDemoZonePresent()"></charts-fixed-widgets-demo>
                            </div>
                        </div>
                    </div>

                    <!-- Chart Row -->
                    <div class="card-container-pdd-row">
                        <div class="entrance-chart-container">
                            <charts-dashboard-pdd :chartData="bestDemoAvgDur" :selectedDuration="selectedDuration" :zoneType="'Demo'" :chartType="'AvgDur'"></charts-dashboard-pdd>
                        </div>
                        <div class="entrance-chart-container">
                            <charts-dashboard-pdd :chartData="bestDemoCount" :selectedDuration="selectedDuration" :zoneType="'Demo'" :chartType="'Counts'"></charts-dashboard-pdd>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="isDisplayZonePresent()" class="product-zone-block" style="margin-top: 100px;">
                <div class="card" :class="{ 'group-disabled': !isDisplayZonePresent() }">
                    <div class="card-header">
                        <h6 class="card-title" :style="{ color: !isDisplayZonePresent() ? 'gray' : '#4F7EB3' }">Display Zone Intelligence</h6>
                    </div>

                    <div class="card-body grid-container-product-data">
                        <div class="card-stack">
                            <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(bestDisplayZoneByAvgDuration.zone_name) }">
                                <div class="card-header counter-card-header">
                                    <p class="card-title counter-card-title">
                                        Top <b>Avg. Duration</b>
                                    </p>
                                </div>
                                <div class="card-body count">
                                    <div v-if="isLoadingbestDisplayZoneInCounts">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else-if="!isDisplayZonePresent()">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Display Zones Defined</p>
                                    </div>
                                    <div v-else-if="isCardEmpty(bestDisplayZoneByAvgDuration.zone_name)">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                    </div>
                                    <div v-else style="text-align: center; color: #4F7EB3">
                                        <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                            <p style="margin-bottom: 0px;"><b> {{ bestDisplayZoneByAvgDuration.zone_name }} </b></p>
                                            <span style="margin-top: 8px;">Average Duration: {{ formattedDurationTime(bestDisplayZoneByAvgDuration.avg_duration) }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(bestDisplayZoneByVisitors.zone_name) }">
                                <div class="card-header counter-card-header">
                                    <p class="card-title counter-card-title">
                                        <b>Top</b> Visitor Count
                                    </p>
                                </div>
                                <div class="card-body count">
                                    <div v-if="isLoadingbestDisplayZoneInCounts">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else-if="!isDisplayZonePresent()">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Display Zones Defined</p>
                                    </div>
                                    <div v-else-if="isCardEmpty(bestDisplayZoneByVisitors.zone_name)">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                    </div>
                                    <div v-else style="text-align: center; color: #4F7EB3">
                                        <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                            <p style="margin-bottom: 0px;"><b> {{ bestDisplayZoneByVisitors.zone_name }} </b></p>
                                            <span style="margin-top: 8px;">{{ bestDisplayZoneByVisitors.visitor_count }} Visitors</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card counter-card-small" :class="{ 'card-disabled': isCardEmpty(worstDisplayZoneByVisitors.zone_name) }">
                                <div class="card-header counter-card-header">
                                    <p class="card-title counter-card-title">
                                        <b>Lowest</b> Visitor Count
                                    </p>
                                </div>
                                <div class="card-body count">
                                    <div v-if="isLoadingworstDisplayZoneInCounts">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else-if="!isDisplayZonePresent()">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No Display Zones Defined</p>
                                    </div>
                                    <div v-else-if="isCardEmpty(worstDisplayZoneByVisitors.zone_name)">
                                        <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                    </div>
                                    <div v-else style="text-align: center; color: #4F7EB3">
                                        <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                            <p style="margin-bottom: 0px;"><b> {{ worstDisplayZoneByVisitors.zone_name }} </b></p>
                                            <span style="margin-top: 8px;">{{ worstDisplayZoneByVisitors.visitor_count }} Visitors</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="chart-container">
                            <div v-if="isLoadingworstDisplayZoneInCounts" class="loader-overlay">
                                <div class="loader"></div>
                            </div>
                            <div v-else>
                                <charts-fixed-widgets-display class="full-width-chart" :totalDurationPerDisplayZone="totalDurationPerDisplayZone" :percentageByDurationRangeInDisplayZone="percentageByDurationRangeInDisplayZone" :displayZonePresent="isDisplayZonePresent()"></charts-fixed-widgets-display>
                            </div>
                        </div>

                    </div>

                    <!-- Chart Row -->
                    <div class="card-container-pdd-row">
                        <div class="entrance-chart-container">
                            <charts-dashboard-pdd :chartData="bestDisplayAvgDur" :selectedDuration="selectedDuration" :zoneType="'Display'" :chartType="'AvgDur'"></charts-dashboard-pdd>
                        </div>
                        <div class="entrance-chart-container">
                            <charts-dashboard-pdd :chartData="bestDisplayCount" :selectedDuration="selectedDuration" :zoneType="'Display'" :chartType="'Counts'"></charts-dashboard-pdd>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeatmapDashboard from './HeatmapDashboard.vue'
import ChartsDashboardEntrance from './ChartsDashboardEntrance.vue'
import ChartsDashboardPdd from './ChartsDashboardPdd.vue'
import ChartsFixedWidgetsProduct from './ChartsFixedWidgetsProduct.vue'
import ChartsFixedWidgetsDemo from './ChartsFixedWidgetsDemo.vue'
import ChartsFixedWidgetsDisplay from './ChartsFixedWidgetsDisplay.vue'

import DurationDropdown from './DurationDropdown.vue'

import { getHeatmapByDuration, getEntranceChartDataByDuration, getTopPDDChartData } from "@/api/eventsAPI.js"

import { useDetectionZoneStore } from '@/stores/DetectionZone'

import { onMounted, onBeforeUnmount, ref, computed, watch } from 'vue'

export default {
    name: 'FixedWidgets',

    components: {
        HeatmapDashboard,
        ChartsDashboardEntrance,
        ChartsDashboardPdd,
        ChartsFixedWidgetsProduct,
        ChartsFixedWidgetsDemo,
        ChartsFixedWidgetsDisplay,
        DurationDropdown
    },

    props: {
        fixedWidgetsData: {
            type: Object,
            required: true
        }
    },

    setup(props, { emit }) {
        const detectionZoneStore = useDetectionZoneStore()

        const windowWidth = ref(window.innerWidth)

        const dateRangeMode = ref("duration")
        const selectedDuration  = ref(30)

        const heatmapData = ref([])
        const entranceChartData = ref({})
        const uniqueDetectionZoneTypeIds = ref([])
        const currentWeekday = ref()
        const todaysCurrentCount = ref(0)
        const todaysCurrentCombinedCountStoreIn = ref(0)
        const todaysCurrentCombinedCountStoreOut = ref(0)
        const todaysCurrentExit = ref(0)
        const averageVisitDuration = ref(0)
        const comparedTodayvsAverageSameWeekday = ref()
        const averageValueSameWeekday = ref(0)
        const comparedTodayvsAverage = ref()
        const averageValue = ref(0)
        const highestTotalHour = ref()
        const highestTotalHourCount = ref(0)
        const highestTotalDay = ref()
        const highestTotalDayCount = ref(0)
        const busiestHour = ref()
        const highestAverageHourlyCount = ref(0)
        const trend = ref("")
        
        const bestProductZoneByVisitors = ref({})
        const bestProductZoneByAvgDuration = ref({})
        const worstProductZoneByVisitors = ref({})
        const totalDurationPerProductZone = ref({})
        const percentageByDurationRangeInProductZone = ref({})

        const bestDemoZoneByVisitors = ref({})
        const bestDemoZoneByAvgDuration = ref({})
        const worstDemoZoneByVisitors = ref({})
        const totalDurationPerDemoZone = ref({})
        const percentageByDurationRangeInDemoZone = ref({})

        const bestDisplayZoneByVisitors = ref({})
        const bestDisplayZoneByAvgDuration = ref({})
        const worstDisplayZoneByVisitors = ref({})
        const totalDurationPerDisplayZone = ref({})
        const percentageByDurationRangeInDisplayZone = ref({})

        const bestProductAvgDur = ref({})
        const bestProductCount = ref({})
        const bestDemoAvgDur = ref({})
        const bestDemoCount = ref({})
        const bestDisplayAvgDur = ref({})
        const bestDisplayCount = ref({})

        const averageWaitingTime = ref(0)
        const maxWaitingDuration = ref(0)
        const maxWaitingZoneName = ref("")
        const mostTypicalWaitingTime = ref(0)
        const amountMostTypicalWaitingTime = ref(0)
        const averageInteractionTime = ref(0)
        const maxInteractionDuration = ref(0)
        const maxInteractionZoneName = ref("")
        const mostTypicalInteractionTime = ref(0)
        const amountMostTypicalInteractionTime = ref(0)
        const fastestCheckoutZone = ref("")
        const slowestCheckoutZone = ref("")
        const fastestInteractionZone = ref("")
        const slowestInteractionZone = ref("")
        const mostUsedCheckout = ref("")
        const leastUsedCheckout = ref("")

        // Separate loading states for each widget
        const isLoadingAverageSameWeekday = ref(true)
        const isLoadingAverage = ref(true)
        const isLoadingHighestHour = ref(true)
        const isLoadingHighestDay = ref(true)
        const isLoadingBusiestHour = ref(true)
        const isLoadingTrend = ref(true)
        
        const isLoadingbestProductZoneInCounts = ref(true)
        const isLoadingbestProductZoneInAvgDuration = ref(true)
        const isLoadingworstProductZoneInCounts = ref(true)

        const isLoadingbestDemoZoneInCounts = ref(true)
        const isLoadingbestDemoZoneInAvgDuration = ref(true)
        const isLoadingworstDemoZoneInCounts = ref(true)

        const isLoadingbestDisplayZoneInCounts = ref(true)
        const isLoadingbestDisplayZoneInAvgDuration = ref(true)
        const isLoadingworstDisplayZoneInCounts = ref(true)

        const isLoadingaverageWaitingTime = ref(true)
        const isLoadingmaxWaitingDuration = ref(true)
        const isLoadingmaxWaitingZoneName = ref(true)
        const isLoadingmostTypicalWaitingTime = ref(true)
        const isLoadingaverageInteractionTime = ref(true)
        const isLoadingmaxInteractionDuration = ref(true)
        const isLoadingmaxInteractionZoneName = ref(true)
        const isLoadingmostTypicalInteractionTime = ref(true)
        const isLoadingfastestCheckoutZone = ref(true)
        const isLoadingslowestCheckoutZone = ref(true)
        const isLoadingfastestInteractionZone = ref(true)
        const isLoadingslowestInteractionZone = ref(true)
        const isLoadingmostUsedCheckout = ref(true)
        const isLoadingleastUsedCheckout = ref(true)

        onMounted(async () => {
            window.addEventListener('resize', updateWindowWidth)

            await detectionZoneStore.fetchDetectionZones()
            uniqueDetectionZoneTypeIds.value = [...new Set(detectionZoneStore.detectionzones.map(zone => zone.DetectionZoneTypeId))]

            heatmapData.value = await getHeatmapByDuration(selectedDuration.value)
            let entrance_chart_result = await getEntranceChartDataByDuration(selectedDuration.value)
            entranceChartData.value = entrance_chart_result.entrance_chart_data
            let pddChartData = await getTopPDDChartData(selectedDuration.value)
            bestProductAvgDur.value = pddChartData.pdd_chart_data.bestProductAvgDur
            bestProductCount.value = pddChartData.pdd_chart_data.bestProductCount
            bestDemoAvgDur.value = pddChartData.pdd_chart_data.bestDemoAvgDur
            bestDemoCount.value = pddChartData.pdd_chart_data.bestDemoCount
            bestDisplayAvgDur.value = pddChartData.pdd_chart_data.bestDisplayAvgDur
            bestDisplayCount.value = pddChartData.pdd_chart_data.bestDisplayCount
        })

        // Computed property to format the highestTotalHour value
        const formattedHighestTotalHour = computed(() => {
    
            if (!highestTotalHour.value) return { formattedDate: "", formattedTime: "" }

            const date = new Date(highestTotalHour.value)

            if (isNaN(date.getTime())) {
                console.error("Invalid Date Format:", highestTotalHour.value)
                return { formattedDate: "Invalid Date", formattedTime: "Invalid Time" }
            }

            // Extract date components
            const weekday = date.toLocaleDateString('en-GB', { weekday: 'short' })
            const day = date.getDate()
            const month = date.toLocaleDateString('en-GB', { month: 'short' })

            const formattedDate = `${weekday}, ${day} ${month}`
            
            // Extract time and compute the next hour
            const startHour = date.getHours()
            
            const endHour = (startHour + 1) % 24 // Ensure it stays within 24 hours

            const formattedTime = `${startHour}h-${endHour}h`

            return {
                formattedDate,
                formattedTime
            }
        })

        // Computed property to format the highestTotalDay value
        const formattedHighestTotalDay = computed(() => {
    
            if (!highestTotalDay.value) return { formattedDate: ""}

            const date = new Date(highestTotalDay.value)

            if (isNaN(date.getTime())) {
                console.error("Invalid Date Format:", highestTotalDay.value)
                return { formattedDate: "Invalid Date"}
            }

            // Extract date components
            const weekday = date.toLocaleDateString('en-GB', { weekday: 'short' })
            const day = date.getDate()
            const month = date.toLocaleDateString('en-GB', { month: 'short' })

            const formattedDate = `${weekday}, ${day} ${month}`

            return {
                formattedDate
            }
        })

        const formattedBusiestHour = computed(() => {
            if (busiestHour.value === undefined || busiestHour.value === null) {
                return ""
            }

            const startHour = parseInt(busiestHour.value, 10)
            const endHour = (startHour + 1) % 24 // Calculate the end hour, wrapping around at 24

            return `${startHour}h-${endHour}h`
        })

        const currentWeekdayString = computed(() => {
            if (currentWeekday.value >= 0 && currentWeekday.value <= 6) {
                const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
                return daysOfWeek[currentWeekday.value]
            } else {
                return 'Same Weekday'
            }
        })

        const diffTodayAboveSameWeekdayAverage = computed(() => {
            let diff = averageValueSameWeekday.value - todaysCurrentCount.value - todaysCurrentCombinedCountStoreIn.value

            if (diff < 0) {
                diff = -1 * diff;
                return `+${diff}`
            } else if (diff > 0) {
                return `-${diff}`
            } else {
                return `${diff}`
            }
        })

        const diffTodayAboveAverage = computed(() => {
            let diff = averageValue.value - todaysCurrentCount.value - todaysCurrentCombinedCountStoreIn.value

            if (diff < 0) {
                diff = -1 * diff;
                return `+${diff}`
            } else if (diff > 0) {
                return `-${diff}`
            } else {
                return `${diff}`
            }
        })

        const formattedAverageDurationTime = computed (() => {
            if (bestProductZoneByAvgDuration.value.avg_duration < 60) {
                return `${bestProductZoneByAvgDuration.value.avg_duration.toFixed(0)} sec`
            } else if (bestProductZoneByAvgDuration.value.avg_duration < 3600) {
                const minutes = Math.floor(bestProductZoneByAvgDuration.value.avg_duration / 60)
                const remainingSeconds = bestProductZoneByAvgDuration.value.avg_duration % 60
                return `${minutes} min ${remainingSeconds.toFixed(0)} sec`
            } else {
                const hours = Math.floor(bestProductZoneByAvgDuration.value.avg_duration / 3600)
                const remainingMinutes = Math.floor((bestProductZoneByAvgDuration.value.avg_duration % 3600) / 60)
                const remainingSeconds = bestProductZoneByAvgDuration.value.avg_duration % 60
                return `${hours} h ${remainingMinutes} min ${remainingSeconds.toFixed(0)} sec`
            }
        })

        const formattedDurationTime = computed(() => {
            return (seconds) => {
                if (seconds < 60) {
                    return `${seconds.toFixed(0)} sec`
                } else if (seconds < 3600) {
                    const minutes = Math.floor(seconds / 60)
                    const remainingSeconds = seconds % 60
                    return `${minutes} min ${remainingSeconds.toFixed(0)} sec`
                } else {
                    const hours = Math.floor(seconds / 3600)
                    const remainingMinutes = Math.floor((seconds % 3600) / 60)
                    const remainingSeconds = seconds % 60
                    return `${hours} h ${remainingMinutes} min ${remainingSeconds.toFixed(0)} sec`
                }
            }
        })

        const computedHeight = computed(() => {
            const baseHeight = 50 // Fixed height for title and fixed stuff
            const additionalHeight = 25 // Height per array member
            const rowCount = heatmapData.value?.heatmap_matrix?.[0]?.length || 0

            const calculatedHeight = baseHeight + additionalHeight * rowCount

            let additionalHeightForChart

            if (rowCount < 12 ) {
                additionalHeightForChart = 300 - (rowCount * 25)
            } else {
                additionalHeightForChart = 0
            }

            // Add 300px when width is below 1500px
            return windowWidth.value < 1500 ? calculatedHeight + 400 : calculatedHeight + additionalHeightForChart
        })

        const updateWindowWidth = () => {
            windowWidth.value = window.innerWidth;
        }


        const handleUpdateDuration = async (newDuration) => {
            // Separate loading states for each widget
            isLoadingAverageSameWeekday.value = true
            isLoadingAverage.value = true
            isLoadingHighestHour.value = true
            isLoadingHighestDay.value = true
            isLoadingBusiestHour.value = true
            isLoadingTrend.value = true
            
            isLoadingbestProductZoneInCounts.value = true
            isLoadingbestProductZoneInAvgDuration.value = true
            isLoadingworstProductZoneInCounts.value = true

            isLoadingbestDemoZoneInCounts.value = true
            isLoadingbestDemoZoneInAvgDuration.value = true
            isLoadingworstDemoZoneInCounts.value = true

            isLoadingbestDisplayZoneInCounts.value = true
            isLoadingbestDisplayZoneInAvgDuration.value = true
            isLoadingworstDisplayZoneInCounts.value = true

            isLoadingaverageWaitingTime.value = true
            isLoadingmaxWaitingDuration.value = true
            isLoadingmaxWaitingZoneName.value = true
            isLoadingmostTypicalWaitingTime.value = true
            isLoadingaverageInteractionTime.value = true
            isLoadingmaxInteractionDuration.value = true
            isLoadingmaxInteractionZoneName.value = true
            isLoadingmostTypicalInteractionTime.value = true
            isLoadingfastestCheckoutZone.value = true
            isLoadingslowestCheckoutZone.value = true
            isLoadingfastestInteractionZone.value = true
            isLoadingslowestInteractionZone.value = true
            isLoadingmostUsedCheckout.value = true
            isLoadingleastUsedCheckout.value = true

            selectedDuration.value  = newDuration
            emit('selectedDuration', newDuration)
            heatmapData.value = await getHeatmapByDuration(selectedDuration.value)
            let entrance_chart_result = await getEntranceChartDataByDuration(selectedDuration.value)
            entranceChartData.value = entrance_chart_result.entrance_chart_data
            let pddChartData = await getTopPDDChartData(selectedDuration.value)
            bestProductAvgDur.value = pddChartData.pdd_chart_data.bestProductAvgDur
            bestProductCount.value = pddChartData.pdd_chart_data.bestProductCount
            bestDemoAvgDur.value = pddChartData.pdd_chart_data.bestDemoAvgDur
            bestDemoCount.value = pddChartData.pdd_chart_data.bestDemoCount
            bestDisplayAvgDur.value = pddChartData.pdd_chart_data.bestDisplayAvgDur
            bestDisplayCount.value = pddChartData.pdd_chart_data.bestDisplayCount
        }

        const isCardEmpty = (...values) => {
            // Check if all values in the card are null, 0, empty string, or empty object
            return values.every(
                (val) =>
                    val === null ||
                    val === "null" ||
                    val === 0 ||
                    val === "0" ||
                    val === "" ||
                    (typeof val === "object" && Object.keys(val).length === 0)
            )
        }

        const isEntranceZonePresent = () => {
            return uniqueDetectionZoneTypeIds.value.includes(1)
        }

        const isExitZonePresent = () => {
            return uniqueDetectionZoneTypeIds.value.includes(2)
        }

        const isCombinedEntranceExitZonePresent = () => {
            return uniqueDetectionZoneTypeIds.value.includes(3)
        }

        const isCheckoutWaitingZonePresent = () => {
            return uniqueDetectionZoneTypeIds.value.includes(4)
        }

        const isCheckoutInteractionZonePresent = () => {
            return uniqueDetectionZoneTypeIds.value.includes(5)
        }

        const isProductZonePresent = () => {
            return uniqueDetectionZoneTypeIds.value.includes(6)
        }

        const isDemoZonePresent = () => {
            return uniqueDetectionZoneTypeIds.value.includes(7)
        }

        const isDisplayZonePresent = () => {
            return uniqueDetectionZoneTypeIds.value.includes(8)
        }

        // Watch for result (Today's Weekday)
        watch(() => props.fixedWidgetsData.current_weekday, (newValue) => {
            if (newValue !== undefined) {
                currentWeekday.value = newValue
            }
        }, { deep: true, immediate: true })


        // Watch for average (verage of the Same Weekday)
        watch(() => props.fixedWidgetsData.average_same_weekday, (newValue) => {
            if (newValue !== undefined) {
                averageValueSameWeekday.value = Math.round(newValue)
                isLoadingAverageSameWeekday.value = false
            }
        }, { deep: true, immediate: true })

        // Watch for average
        watch(() => props.fixedWidgetsData.average_visitors, (newValue) => {
            if (newValue !== undefined) {
                averageValue.value = Math.round(newValue)
                isLoadingAverage.value = false
            }
        }, { deep: true, immediate: true })


        // Watch for highest_total_hour (Top Visitor Hour)
        watch(() => props.fixedWidgetsData.highest_total_hour, (newValue) => {
            if (newValue !== undefined) {
                highestTotalHour.value = newValue
                isLoadingHighestHour.value = false // Turn off the loader for Highest Total Hour widget
            }
        }, { deep: true, immediate: true })

        // Watch for highest_total_hour_count (Number of Visitors in Top Visitor Hour)
        watch(() => props.fixedWidgetsData.highest_total_hour_count, (newValue) => {
            if (newValue !== undefined) {
                highestTotalHourCount.value = newValue
            }
        }, { deep: true, immediate: true })

        // Watch for highest_total_day (Top Visitor Day)
        watch(() => props.fixedWidgetsData.highest_total_day, (newValue) => {
            if (newValue !== undefined) {
                highestTotalDay.value = newValue
                isLoadingHighestDay.value = false // Turn off the loader for Highest Total Day widget
            }
        }, { deep: true, immediate: true })

        // Watch for highest_total_day_count (Number of Visitors in Top Visitor Day)
        watch(() => props.fixedWidgetsData.highest_total_day_count, (newValue) => {
            if (newValue !== undefined) {
                highestTotalDayCount.value = newValue
            }
        }, { deep: true, immediate: true })

        // Watch for busiest_hour_past_7_days (Busiest Hour on Average of Past 7 Days)
        watch(() => props.fixedWidgetsData.busiest_hour, (newValue) => {
            if (newValue !== undefined) {
                busiestHour.value = `${newValue}`
                isLoadingBusiestHour.value = false // Turn off the loader for Busiest Hour widget
            }
        }, { deep: true, immediate: true })

        // Watch for highest_average_hourly_count (Highest Average Hourly Count in Past 7 Days)
        watch(() => props.fixedWidgetsData.average_on_busiest_hour, (newValue) => {
            if (newValue !== undefined) {
                if (Math.round(newValue) < 1) {
                    highestAverageHourlyCount.value = '#';
                } else {
                    highestAverageHourlyCount.value = Math.round(newValue);
                }
            }
        }, { deep: true, immediate: true })

        // Watch for trend (Visitors Trend)
        watch(() => props.fixedWidgetsData.trend, (newValue) => {
            if (newValue !== undefined) {
                trend.value = newValue
                isLoadingTrend.value = false // Turn off the loader for Visitors Trend
            }
        }, { deep: true, immediate: true })

        // Watch for best_product_zone_by_visitors (Best Preforming Zone in Counts)
        watch(() => props.fixedWidgetsData.best_product_zone_by_visitors, (newValue) => {
            if (newValue !== undefined) {
                bestProductZoneByVisitors.value = newValue
                isLoadingbestProductZoneInCounts.value = false // Turn off the loader for Best Performing Product Zone
            }
        }, { deep: true, immediate: true })

        // Watch for best_product_zone_by_avg_duration (Best Preforming Zone in Average Duration)
        watch(() => props.fixedWidgetsData.best_product_zone_by_avg_duration, (newValue) => {
            if (newValue !== undefined) {
                bestProductZoneByAvgDuration.value = newValue
                isLoadingbestProductZoneInAvgDuration.value = false // Turn off the loader for Best Performing Product Zone
            }
        }, { deep: true, immediate: true })

        // Watch for worst_product_zone_by_visitors (Worst Preforming Zone in Counts)
        watch(() => props.fixedWidgetsData.worst_product_zone_by_visitors, (newValue) => {
            if (newValue !== undefined) {
                worstProductZoneByVisitors.value = newValue
                isLoadingworstProductZoneInCounts.value = false // Turn off the loader for Worst Performing Product Zone
            }
        }, { deep: true, immediate: true })

        // Watch for total_duration_per_product_zone (Chart of Total Duration in Product Zones)
        watch(() => props.fixedWidgetsData.total_duration_per_product_zone, (newValue) => {
            if (newValue !== undefined) {
                totalDurationPerProductZone.value = newValue
            }
        }, { deep: true, immediate: true })

        // Watch for percentage_by_duration_range_in_product_zones (average duration distribution per product zone)
        watch(() => props.fixedWidgetsData.percentage_by_duration_range_in_product_zone, (newValue) => {
            if (newValue !== undefined) {
                percentageByDurationRangeInProductZone.value = newValue
            }
        }, { deep: true, immediate: true })

        // Watch for best_demo_zone_by_visitors (Best Preforming Zone in Counts)
        watch(() => props.fixedWidgetsData.best_demo_zone_by_visitors, (newValue) => {
            if (newValue !== undefined) {
                bestDemoZoneByVisitors.value = newValue
                isLoadingbestDemoZoneInCounts.value = false // Turn off the loader for Best Performing Demo Zone
            }
        }, { deep: true, immediate: true })

        // Watch for best_demo_zone_by_avg_duration (Best Preforming Zone in Average Duration)
        watch(() => props.fixedWidgetsData.best_demo_zone_by_avg_duration, (newValue) => {
            if (newValue !== undefined) {
                bestDemoZoneByAvgDuration.value = newValue
                isLoadingbestDemoZoneInAvgDuration.value = false // Turn off the loader for Best Performing Demo Zone
            }
        }, { deep: true, immediate: true })

        // Watch for worst_demo_zone_by_visitors (Worst Preforming Zone in Counts)
        watch(() => props.fixedWidgetsData.worst_demo_zone_by_visitors, (newValue) => {
            if (newValue !== undefined) {
                worstDemoZoneByVisitors.value = newValue
                isLoadingworstDemoZoneInCounts.value = false // Turn off the loader for Past Worst Performing Demo Zone
            }
        }, { deep: true, immediate: true })

        // Watch for total_duration_per_demo_zone (Chart of Total Duration in Demo Zones)
        watch(() => props.fixedWidgetsData.total_duration_per_demo_zone, (newValue) => {
            if (newValue !== undefined) {
                totalDurationPerDemoZone.value = newValue
            }
        }, { deep: true, immediate: true })

        // Watch for percentage_by_duration_range_in_demo_zones (average duration distribution per demo zone)
        watch(() => props.fixedWidgetsData.percentage_by_duration_range_in_demo_zone, (newValue) => {
            if (newValue !== undefined) {
                percentageByDurationRangeInDemoZone.value = newValue
            }
        }, { deep: true, immediate: true })

        // Watch for best_display_zone_by_visitors (Best Preforming Zone in Counts)
        watch(() => props.fixedWidgetsData.best_display_zone_by_visitors, (newValue) => {
            if (newValue !== undefined) {
                bestDisplayZoneByVisitors.value = newValue
                isLoadingbestDisplayZoneInCounts.value = false // Turn off the loader for Best Performing Display Zone
            }
        }, { deep: true, immediate: true })

        // Watch for best_display_zone_by_avg_duration (Best Preforming Zone in Average Duration)
        watch(() => props.fixedWidgetsData.best_display_zone_by_avg_duration, (newValue) => {
            if (newValue !== undefined) {
                bestDisplayZoneByAvgDuration.value = newValue
                isLoadingbestDisplayZoneInAvgDuration.value = false // Turn off the loader for Best Performing Display Zone
            }
        }, { deep: true, immediate: true })

        // Watch for worst_display_zone_by_visitors (Worst Preforming Zone in Counts)
        watch(() => props.fixedWidgetsData.worst_display_zone_by_visitors, (newValue) => {
            if (newValue !== undefined) {
                worstDisplayZoneByVisitors.value = newValue
                isLoadingworstDisplayZoneInCounts.value = false // Turn off the loader for Worst Performing Display Zone
            }
        }, { deep: true, immediate: true })

        // Watch for total_duration_per_display_zone (Chart of Total Duration in Display Zones)
        watch(() => props.fixedWidgetsData.total_duration_per_display_zone, (newValue) => {
            if (newValue !== undefined) {
                totalDurationPerDisplayZone.value = newValue
            }
        }, { deep: true, immediate: true })

        // Watch for percentage_by_duration_range_in_display_zones (average duration distribution per display zone)
        watch(() => props.fixedWidgetsData.percentage_by_duration_range_in_display_zone, (newValue) => {
            if (newValue !== undefined) {
                percentageByDurationRangeInDisplayZone.value = newValue
            }
        }, { deep: true, immediate: true })

        // Watch for average checkout waiting time
        watch(() => props.fixedWidgetsData.average_waiting_time, (newValue) => {
            if (newValue !== undefined) {
                averageWaitingTime.value = newValue
                isLoadingaverageWaitingTime.value = false
            }
        }, { deep: true, immediate: true })

        // Watch for maximum checkout waiting time
        watch(() => props.fixedWidgetsData.max_waiting_duration, (newValue) => {
            if (newValue !== undefined) {
                maxWaitingDuration.value = newValue
                isLoadingmaxWaitingDuration.value = false
            }
        }, { deep: true, immediate: true })

        // Watch for checkout name of maximum checkout waiting time
        watch(() => props.fixedWidgetsData.max_waiting_zone, (newValue) => {
            if (newValue !== undefined) {
                maxWaitingZoneName.value = newValue
                isLoadingmaxWaitingZoneName.value = false
            }
        }, { deep: true, immediate: true })

        // Watch for most typical checkout waiting time
        watch(() => props.fixedWidgetsData.most_typical_waiting_time, (newValue) => {
            if (newValue !== undefined) {
                if (newValue) {
                    mostTypicalWaitingTime.value = newValue[0]
                    amountMostTypicalWaitingTime.value = newValue[1]
                }
                else {
                    mostTypicalWaitingTime.value = null
                    amountMostTypicalWaitingTime.value = null
                }
                isLoadingmostTypicalWaitingTime.value = false
            }
        }, { deep: true, immediate: true })

        // Watch for average checkout interaction time
        watch(() => props.fixedWidgetsData.average_interaction_time, (newValue) => {
            if (newValue !== undefined) {
                averageInteractionTime.value = newValue
                isLoadingaverageInteractionTime.value = false
            }
        }, { deep: true, immediate: true })

        // Watch for maximum checkout interaction time
        watch(() => props.fixedWidgetsData.max_interaction_duration, (newValue) => {
            if (newValue !== undefined) {
                maxInteractionDuration.value = newValue
                isLoadingmaxInteractionDuration.value = false
            }
        }, { deep: true, immediate: true })

        // Watch for checkout name of maximum checkout interaction time
        watch(() => props.fixedWidgetsData.max_interaction_zone, (newValue) => {
            if (newValue !== undefined) {
                maxInteractionZoneName.value = newValue
                isLoadingmaxInteractionZoneName.value = false
            }
        }, { deep: true, immediate: true })

        // Watch for most typical checkout interaction time
        watch(() => props.fixedWidgetsData.most_typical_interaction_time, (newValue) => {
            if (newValue !== undefined) {
                if (newValue) {
                    mostTypicalInteractionTime.value = newValue[0]
                    amountMostTypicalInteractionTime.value = newValue[1]
                }
                else {
                    mostTypicalInteractionTime.value = null
                    amountMostTypicalInteractionTime.value = null
                }
                isLoadingmostTypicalInteractionTime.value = false
            }
        }, { deep: true, immediate: true })

        // Watch for fastest checkout zone in waiting time
        watch(() => props.fixedWidgetsData.fastest_checkout_zone, (newValue) => {
            if (newValue !== undefined) {
                fastestCheckoutZone.value = newValue
                isLoadingfastestCheckoutZone.value = false
            }
        }, { deep: true, immediate: true })

        // Watch for slowest checkout zone in waiting time
        watch(() => props.fixedWidgetsData.slowest_checkout_zone, (newValue) => {
            if (newValue !== undefined) {
                slowestCheckoutZone.value = newValue
                isLoadingslowestCheckoutZone.value = false
            }
        }, { deep: true, immediate: true })

        // Watch for fastest checkout zone in interaction time
        watch(() => props.fixedWidgetsData.fastest_interaction_zone, (newValue) => {
            if (newValue !== undefined) {
                fastestInteractionZone.value = newValue
                isLoadingfastestInteractionZone.value = false
            }
        }, { deep: true, immediate: true })

        // Watch for slowest checkout zone in interaction time
        watch(() => props.fixedWidgetsData.slowest_interaction_zone, (newValue) => {
            if (newValue !== undefined) {
                slowestInteractionZone.value = newValue
                isLoadingslowestInteractionZone.value = false
            }
        }, { deep: true, immediate: true })

        // Most Used Checkout
        watch(() => props.fixedWidgetsData.most_used_interaction_zone, (newValue) => {
            if (newValue !== undefined) {
                mostUsedCheckout.value = newValue
                isLoadingmostUsedCheckout.value = false // Turn off the loader for Worst Performing Product Zone
            }
        }, { deep: true, immediate: true })

        // Least Used Checkout
        watch(() => props.fixedWidgetsData.least_used_interaction_zone, (newValue) => {
            if (newValue !== undefined) {
                leastUsedCheckout.value = newValue
                isLoadingleastUsedCheckout.value = false // Turn off the loader for Worst Performing Product Zone
            }
        }, { deep: true, immediate: true })

        onBeforeUnmount(() => {
            window.removeEventListener('resize', updateWindowWidth);
        })

        return {
            selectedDuration,
            dateRangeMode,
            handleUpdateDuration,
            heatmapData,
            entranceChartData,
            computedHeight,
            currentWeekdayString,
            todaysCurrentCount,
            todaysCurrentCombinedCountStoreIn,
            todaysCurrentCombinedCountStoreOut,
            todaysCurrentExit,
            averageVisitDuration,
            comparedTodayvsAverageSameWeekday,
            averageValueSameWeekday,
            diffTodayAboveSameWeekdayAverage,
            comparedTodayvsAverage,
            averageValue,
            diffTodayAboveAverage,
            highestTotalHour,
            highestTotalHourCount,
            highestTotalDay,
            highestTotalDayCount,
            busiestHour,
            highestAverageHourlyCount,
            trend,
            bestProductZoneByVisitors,
            bestProductZoneByAvgDuration,
            worstProductZoneByVisitors,
            totalDurationPerProductZone,
            percentageByDurationRangeInProductZone,
            bestDemoZoneByVisitors,
            bestDemoZoneByAvgDuration,
            worstDemoZoneByVisitors,
            totalDurationPerDemoZone,
            percentageByDurationRangeInDemoZone,
            bestDisplayZoneByVisitors,
            bestDisplayZoneByAvgDuration,
            worstDisplayZoneByVisitors,
            totalDurationPerDisplayZone,
            percentageByDurationRangeInDisplayZone,
            bestProductAvgDur,
            bestProductCount,
            bestDemoAvgDur,
            bestDemoCount,
            bestDisplayAvgDur,
            bestDisplayCount,
            averageWaitingTime,
            maxWaitingDuration,
            maxWaitingZoneName,
            mostTypicalWaitingTime,
            amountMostTypicalWaitingTime,
            averageInteractionTime,
            maxInteractionDuration,
            maxInteractionZoneName,
            mostTypicalInteractionTime,
            amountMostTypicalInteractionTime,
            fastestCheckoutZone,
            slowestCheckoutZone,
            fastestInteractionZone,
            slowestInteractionZone,
            mostUsedCheckout,
            leastUsedCheckout,
            formattedAverageDurationTime,
            isLoadingAverageSameWeekday,
            isLoadingAverage,
            isLoadingHighestHour,
            isLoadingHighestDay,
            isLoadingBusiestHour,
            isLoadingTrend,
            isLoadingbestProductZoneInCounts,
            isLoadingbestProductZoneInAvgDuration,
            isLoadingworstProductZoneInCounts,
            isLoadingbestDemoZoneInCounts,
            isLoadingbestDemoZoneInAvgDuration,
            isLoadingworstDemoZoneInCounts,
            isLoadingbestDisplayZoneInCounts,
            isLoadingbestDisplayZoneInAvgDuration,
            isLoadingworstDisplayZoneInCounts,
            isLoadingaverageWaitingTime,
            isLoadingmaxWaitingDuration,
            isLoadingmaxWaitingZoneName,
            isLoadingmostTypicalWaitingTime,
            isLoadingaverageInteractionTime,
            isLoadingmaxInteractionDuration,
            isLoadingmaxInteractionZoneName,
            isLoadingmostTypicalInteractionTime,
            isLoadingfastestCheckoutZone,
            isLoadingslowestCheckoutZone,
            isLoadingfastestInteractionZone,
            isLoadingslowestInteractionZone,
            isLoadingmostUsedCheckout,
            isLoadingleastUsedCheckout,
            formattedHighestTotalHour,
            formattedHighestTotalDay,
            formattedBusiestHour,
            formattedDurationTime,
            isCardEmpty,
            isEntranceZonePresent,
            isExitZonePresent,
            isCombinedEntranceExitZonePresent,
            isCheckoutWaitingZonePresent,
            isCheckoutInteractionZonePresent,
            isProductZonePresent,
            isDemoZonePresent,
            isDisplayZonePresent
        }
    }
}

</script>

<style scoped>
.card-main-header {
    display: flex;
    align-items: center; /* Align elements vertically in the center */
    gap: 25px; /* Add space between the title and dropdown */
}

.card-main-title {
    margin: 0; /* Ensure no extra margin */
    font-size: 1.25rem;
}

.grid-container-store-visitor-data {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive layout */
    gap: 20px;
    padding:20px;
}

.card-container-heatmap-row {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns: Heatmap (2 parts) and New Item (1 part) */
    gap: 20px; /* Space between columns */
    padding: 20px;
}

.card-container-pdd-row {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns: Heatmap (2 parts) and New Item (1 part) */
    gap: 100px; /* Space between columns */
    padding: 20px;
}

.heatmap-container {
    height: 100%; /* Ensure the heatmap fills its container */
    width: 100%; /* Full width */
}

.entrance-chart-container {
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    display: flex; /* Flexbox ensures proper scaling */
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Prevent overflow issues */
}

.card-container-first-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns for each block */
    gap: 20px;
}

.card-container-second-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns for each block */
    gap: 20px;
    margin-top: 20px;
}

.counter-card {
    width: 290px;
    height: 140px;
    box-sizing: border-box;
}

.counter-card-large {
    width: 340px;
    height: 140px;
    box-sizing: border-box;
}

.counter-card-small {
    width: 100%; /* Full width within each block */
    height: 105px;
    box-sizing: border-box;
}

.counter-card-header {
    padding: 2px;
    text-align: center;
}

.counter-card-title {
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: small;
    color: #000000;
    white-space: nowrap; /* Prevent text from wrapping */
    font-size: clamp(11px, 0.6vw, 14px) !important; /* Dynamically adjust font size */
}

.count {
    display: flex;
    justify-content: center;
    height: 30px;
    padding: 10px; /* Add padding to reduce gap */
}

.current-counter {
    font-size: clamp(48px, 2vw, 52px) !important; /* Dynamically adjust font size */
}

.count p {
    white-space: nowrap; /* Prevent text from wrapping */
    font-size: clamp(14px, 0.8vw, 18px) !important; /* Dynamically adjust font size */
}

.count span{
    white-space: nowrap; /* Prevent text from wrapping */
    font-size: clamp(10px, 0.8vw, 12px) !important; /* Dynamically adjust font size */
}

.grid-container-entrance-data {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    gap: 10px; /* Space between the grid items */
}

.grid-container-product-data {
    display: grid;
    grid-template-columns: 1fr 5fr;
    gap: 10px; /* Space between the grid items */
}

.product-zone-block {
    width: 100%;
    margin-top: 20px;
}

.card-stack {
    display: flex;
    flex-direction: column; /* Stack cards vertically */
    gap: 10px;
    margin-right: 20px;
}

.full-width-chart {
    height: 100%;
}

.chart-container {
    height: 100%;
    position: relative; /* Ensure the loader-overlay is scoped to this container */
}

.card-disabled {
    opacity: 0.5;
    pointer-events: none; /* Optional: Disable interactions */
    background-color: #ffffff; /* Light gray background */
}

.group-disabled {
    border: 2px dashed #dcdcdc; /* Dashed border to indicate unavailability */
    background-color: #ffffff;
    opacity: 0.7;
}

.loader-overlay {
    position: absolute; /* Cover the entire parent container */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; /* Vertically center the loader */
    justify-content: center; /* Horizontally center the loader */
    background-color: rgba(255, 255, 255, 0.8); /* Optional: semi-transparent background */
    z-index: 1000; /* Ensure the loader appears above other elements */
}

.loader {
  width: 20px;
  margin-top: 20px;
  padding: 2px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

/* Responsive wrapping for screen width below 1500px */
@media (max-width: 1500px) {
    .card-container-first-row,
    .card-container-second-row {
        grid-template-columns: 1fr; /* Wrap blocks on smaller screens */
    }
    .grid-container-entrance-data{
        grid-template-columns: repeat(2, 1fr); /* Wrap counter-cards within blocks */
    }
    .grid-container-product-data {
        grid-template-columns: 1fr; /* Wrap counter-cards within blocks */
    }

    .card-container-heatmap-row {
        display: grid; /* Ensure grid layout */
        grid-template-columns: 1fr; /* Single column layout */
        grid-template-rows: calc(var(--computed-height, 300px)) calc(var(--computed-height, 300px)); /* Divide height equally */
        gap: 20px; /* Small gap between items */
        height: calc(2 * var(--computed-height, 300px) + 20px); /* Total height accounting for gap */
    }

    .heatmap-container
    .entrance-chart-container {
        width: 100%; /* Ensure full width */
        height: auto; /* Adjust height automatically */
    }
    .card-stack {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two cards per row in the stack */
        gap: 10px; /* Space between cards */
        margin-right: 0; /* Reset right margin for the stacked layout */
    }
    .counter-card-small {
        width: 100%; /* Make sure each card takes full available width within grid */
    }
}

/* Make charts in the product zone block wrap fully below 1200px */
@media (max-width: 1400px) {
    .chart-container {
        flex-direction: column; /* Stack charts vertically */
        gap: 1rem;
    }
    .chart-wrapper {
        flex: 1 1 100%; /* Make charts take full width */
        max-width: 100%;
    }
}

/* Further wrap counter cards within blocks for even smaller screens */
@media (max-width: 600px) {
    .grid-container-entrance-data,
    .grid-container-product-data {
        grid-template-columns: 1fr; /* Single column layout for counter-cards */
    }

    .card-stack {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px; /* Space between cards */
        margin-right: 0; /* Reset right margin for the stacked layout */
    }
}

@media (max-width: 850px) {
    .product-zone-block {
        grid-template-columns: 1fr; /* Stack Product Zone Intelligence and Charts vertically */
    }
}

/* Media query for screens less than 950px */
@media (max-width: 120px) {
    .card-container {
        justify-content: center;
    }
}

</style>