<template>
    <div>
        
        <div class="container">
            <div class="container">
                <div class="card card-header" style="margin-bottom: 10px">
                    <div class="d-flex flex-wrap" style="gap: 15px">

                        <div v-if="serverConnection">
                            <h5 class="card-text" style="font-size: small; color: #4F7EB3">Connection with local device: <span class="card-text status-indicator status-running"></span></h5>
                        </div>

                        <div v-if="!serverConnection">
                            <h5 class="card-text" style="font-size: small; color: #4F7EB3">Connection with local device: <span class="card-text status-indicator status-stopped"></span></h5>
                        </div>

                        <div v-if="streamStatus">
                            <h5 class="card-text" style="font-size: small; color: #4F7EB3">Detections on/off: <span class="card-text status-indicator status-running"></span></h5>
                        </div>

                        <div v-if="!streamStatus" style="margin-right: 15px;">
                            <h5 class="card-text" style="font-size: small; color: #4F7EB3">Detections on/off: <span class="card-text status-indicator status-stopped"></span></h5>
                        </div>

                        <div v-if="!streamStatus" style="margin-right: 15px;">
                            <h5 class="card-text" style="font-size: small; color: #b4b4b4">Status Detection Stream: <span class="card-text status-indicator status-grayed-out"></span></h5>
                        </div>
                        
                        <div v-else>
                            <div>
                                <div v-if="cameraHeartBeat[flowId]" style="margin-right: 15px;">
                                    <h5 class="card-text" style="font-size: small; color: #4F7EB3">Status Detection Stream: <span class="card-text status-indicator status-running"></span></h5>
                                </div>

                                <div v-if="!cameraHeartBeat[flowId]" style="margin-right: 15px;">
                                    <h5 class="card-text" style="font-size: small; color: #4F7EB3">Status Detection Stream: <span class="card-text status-indicator status-stopped"></span></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="card">

                    <div class="card-header d-flex justify-content-between align-items-center">
                        <div>
                            <h3 class="card-title">{{ flowStore.currentFlow.FlowName }}</h3>
                        </div>
                    
                        <div>
                            <router-link
                                v-if="!isSmallScreen && userStore.user.userRole !== 'User' && flowStore.currentFlow.FlowId"
                                :to="`/flow_settings/${flowStore.currentFlow.FlowId}`"
                                type="button"
                                class="btn btn-primary"
                                >
                                <span class="btn-label"><i class="bi bi-gear"></i></span>
                            </router-link>
                        </div>
                    </div>

                    <div class="card-body ">
                        
                        <div v-if="flowStore.currentFlow.UserRole !== 'User'" class="button-container" style="padding-left: 8px;">
                            <button v-if="!streamStatus" type="button" class="btn btn-primary-start" @click="startDetection" :disabled="isLoading || !socket">
                                <span class="btn-label" style="color: #FFF;"><i class="bi bi-play-btn-fill"> Start Detection</i></span>
                            </button>
                            
                            <button v-else type="button" class="btn btn-primary" @click="stopDetection" :disabled="isLoading || !socket">
                                <span class="btn-label"><i class="bi bi-stop-btn-fill"> Stop Detection</i></span>
                            </button>
                        </div>
                        

                        <div class="stream-counter-container">
                            <div v-if="socket" class="live-stream-wrapper">
                                <button @click="toggleLiveStream" class="toggle-button">
                                    <span><b>{{ isLiveStreamOpen ? 'Close Live Stream ' : 'Open Live Stream ' }}</b></span>
                                    <i :class="isLiveStreamOpen ? 'bi bi-chevron-up' : 'bi bi-chevron-down'"></i>
                                </button>
                                <transition name="roll">
                                    <div v-if="isLiveStreamOpen" class="live-stream-frame">
                                        <live-stream :stream-link="cameraStreamUrl" :flow-id="flowId" :stream-id="streamId" :stream-status="streamStatus" :socket="socket" @streamstatusChange="handleStreamStatus"></live-stream>
                                    </div>
                                </transition>
                            </div>

                            <div class="cards-container">
                                
                                <div class="card card-header sticky-section" style="flex: 1 1 200px">
                                    <div class="top-row" style="padding-bottom: 10px;">
                                        <div class="form-switch data-mode-switch">
                                            <input class="form-check-input" type="checkbox" id="dataModeSwitch" v-model="isTodaySelected">
                                            <label class="form-check-label" for="dataModeSwitch">{{ isTodaySelected ? "Today's Real-Time Data" : "Historical Data" }}</label>
                                        </div>
                                    </div>
                                    <div v-if="!isTodaySelected" class="top-row" style="margin-top: 20px;">
                                        <duration-dropdown :dateRangeMode="dateRangeMode" :selectedDuration="duration" @updateDateRangeMode="changeDateRangeMode" @updateDuration="changeDuration" @clearDatePicker="updateClearDatePicker"></duration-dropdown>
                                        <date-picker :clear-date-picker="clearDatePicker" @updateStartDate="updateStartDate" @updateEndDate="updateEndDate" @updateDateRangeMode="changeDateRangeMode" @updateClearDatePicker="updateClearDatePicker"></date-picker>
                                    </div>
                                    <div v-if="isTodaySelected">
                                        <!-- <threshold-slider @update:threshold="handleMinimuDurationThresholdUpdate"></threshold-slider> -->
                                    </div>
                                </div>

                                <div class="flex-break"></div>

                                <div v-if="isTodaySelected" class="cards-container">
                                    <div v-for="(count, zoneName) in zoneCounters" :key="zoneName" class="card counter-card" :class="{ 'greyed-out': !streamStatus }">
                                        <div class="card-header counter-card-header">
                                            <h6 class="card-title counter-card-title" style="font-size: small;">Current in <span :style="{ color: getColorForZone(zoneName) }">{{ zoneName }}</span></h6>
                                        </div>
                                        <div class="card-body count" style="background-color: #f0f8ff;">
                                            <p style="font-size: small"><b>{{ streamStatus ? count : 'N/A' }}</b></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex-break"></div>
                                <counters :flow-id="flowId" :start-date="startDate" :end-date="endDate" :duration="duration" :is-today-selected="isTodaySelected" :date-range-mode="dateRangeMode" :minimum-duration-threshold="minimuDurationThreshold"></counters>

                                <div class="flex-break" style="padding-bottom: 50px;"></div>
                                
                            </div>

                        </div>
                        <div class="card card-header" style="margin-left: 40px; margin-right: 15px; padding-top: 0px; text-align: center">
                            <data-granularity style="margin-top: 20px;" :is-table-tab-event-list="isTableTabEventList" @updateDataGranularity="updateDataGranularity"></data-granularity>
                        </div>
                        <chart style="margin-top: 20px;" :flow-id="flowId" :start-date="startDate" :end-date="endDate" :data-granularity="dataGranularity" :duration="duration" :is-today-selected="isTodaySelected" :date-range-mode="dateRangeMode" :minimum-duration-threshold="minimuDurationThreshold" @update-data="handleChartData"></chart>
                        <div v-if="!isTodaySelected">
                            <table-view style="margin-top: 50px;" :flow-id="flowId" :start-date="startDate" :end-date="endDate" :data-granularity="dataGranularity" :duration="duration" :date-range-mode="dateRangeMode" :minimum-duration-threshold="minimuDurationThreshold" @tabStatusChange="handleTabStatusChange"></table-view>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { ref, onBeforeMount, onBeforeUnmount, onMounted, watchEffect, watch } from 'vue'
import { MDBTabs, MDBTabNav, MDBTabContent, MDBTabItem, MDBTabPane } from 'mdb-vue-ui-kit'
import { useRoute } from 'vue-router'

import { useToast } from 'vue-toastification'

import { detection_zone_colors_by_name_solid } from '@/constants.js'
import { useFlowStore } from '@/stores/FlowStore'
import { useCompanyStore } from '@/stores/CompanyStore'
import { useUserStore } from '@/stores/UserStore'
import { useCameraStreamStore } from '@/stores/CameraStreamStore'
import { useDetectionZoneStore } from '@/stores/DetectionZone'

import { useGlobalState } from '@/composables/useGlobalState'
import io from 'socket.io-client'

import LiveStream from './LiveStream.vue'
import ThresholdSlider from './ThresholdSlider.vue'
import Counters from './Counters.vue'
import DurationButtons from './DurationButtons.vue'
import DatePicker from './DatePicker.vue'
import DurationDropdown from './DurationDropdown.vue'
import DataGranularity from './DataGranularityRadioButton.vue'
import Chart from './Chart.vue'
import TableView from './TableView.vue'
import SmartAnalytics from './SmartAnalytics.vue'

export default {
    components: {
        LiveStream,
        ThresholdSlider,
        Counters,
        DurationButtons,
        DurationDropdown,
        DatePicker,
        DataGranularity,
        Chart,
        TableView,
        SmartAnalytics,
        MDBTabs,
        MDBTabNav,
        MDBTabContent,
        MDBTabItem,
        MDBTabPane,
    },

    setup() {
        const socket = ref(null)
        const isConnectingSocket = ref(false)
        const toast = useToast()

        const { serverConnection } = useGlobalState()
        const cameraHeartBeat = ref({})
        const heartbeatTimeouts = ref({})

        const activeTab = ref('chart-view')

        const flowStore = useFlowStore()
        const companyStore = useCompanyStore()
        const userStore = useUserStore()
        const CameraStreamStore = useCameraStreamStore()
        const DetectionZoneStore = useDetectionZoneStore()
        const route = useRoute()

        const flowId = ref(parseInt(route.params.id))
        const streamId = ref(0)
        const entranceCam = ref(false)
        const cameraStreamUrl = ref(null)
        const streamStatus = ref(null)

        const isLiveStreamOpen = ref(false)

        const isTodaySelected = ref(true)

        const zoneCounters = ref({})
        const detectionZones = ref([])
        const detectionZoneNames = ref({})

        const dateRangeMode = ref('duration')

        const clearDatePicker = ref(null)
        const startDate = ref(null)
        const endDate = ref(null)
        const dataGranularity = ref('daily')
        const duration = ref()
        const minimuDurationThreshold = ref(0)

        const resetSignal = ref(null)

        const openChartViewStatus = ref(false)
        const openSmartAnalyticsStatus = ref(false)
        const openTableViewStatus = ref(false)
        const isTableTabEventList = ref(false)
        const chartData = ref({})

        const isSmallScreen = ref(window.innerWidth <= 768)

        const isLoading = ref(false)

        const detectNetworkContext = async (publicURL) => {
            try {
                const controller = new AbortController()
                const signal = controller.signal
                const timeout = 5000 // 5 seconds timeout for checking public IP

                console.log('Trying public URL:', publicURL)
                const fetchPromise = fetch(publicURL, { signal })
                setTimeout(() => controller.abort(), timeout)

                const response = await fetchPromise
                if (response.ok) {
                    console.log('Public URL is accessible')
                    return publicURL
                }
            } catch (error) {
                console.error('Public Socket URL is not accessible')
                toast.error('Local device is not accessible.')
                throw new Error('Public Socket URL is not accessible')
            }
            throw new Error('Public Socket URL is not accessible')
        }

        const establishSocketConnection = async (publicURL) => {
            if (socket.value !== null) {
                console.log("Returning existing socket")
                return socket.value
            }

            if (isConnectingSocket.value) {
                console.log('Already connecting, waiting...')
                while (isConnectingSocket.value) {
                    await new Promise(r => setTimeout(r, 500))
                }
                return socket.value
            }

            console.log("Connecting socket ...")
            isConnectingSocket.value = true

            try {
                const url = await detectNetworkContext(publicURL)
                console.log('Network context detected, URL:', url)

                socket.value = io(url, {
                    transports: ['websocket'],
                    reconnection: true,
                    reconnectionAttempts: 5,
                    reconnectionDelay: 1000,
                    debug: true,
                    perMessageDeflate: false,
                    timeout: 120000,
                    maxPayload: 50 * 1024 * 1024,
                })

                setupSocketEvents(socket.value)

                return socket.value

            } catch (error) {
                console.error('Error establishing socket connection:', error)
                throw error
            } finally {
                console.log('Resetting serverConnection flag')
                isConnectingSocket.value = false
            }
        }

        const setupSocketEvents = (socketInstance) => {
            socketInstance.on('zone_counter', (data) => {
                if (String(data.stream_id) === String(streamId.value)) {
                    for (const zone in data.zone_counter) {
                        zoneCounters.value[zone] = data.zone_counter[zone] // Update the existing keys in zoneCounters
                    }
                }
            })

            socketInstance.on('heartbeat', (data) => {
                const { streamId, heartbeat } = data

                const cameraStream = CameraStreamStore.inputcamerastream

                if (cameraStream.CameraStreamId === Number(streamId)) {
                    const flowId = cameraStream.FlowId

                    if (heartbeat && flowId) {
                        cameraHeartBeat.value[flowId] = true
                        clearTimeout(heartbeatTimeouts.value[flowId])
                        heartbeatTimeouts.value[flowId] = setTimeout(() => {
                            cameraHeartBeat.value[flowId] = false
                        }, 5000)
                    }
                }
            })

            socketInstance.on('disconnection_message', (data) => {
                console.log('message on disconnection: ', data)
            })

            socketInstance.on('error', (error) => {
                console.error('Error received from server:', error)
                toast.error(`Error: ${error.message}`)
            })
        }

        onBeforeMount(async () => {
            try {
                const user = await userStore.fetchUserDetails()
                await companyStore.fetchCompanyDetails(user.CompanyId)

                if (companyStore.company.SocketioURLPublic) {
                    socket.value = await establishSocketConnection(companyStore.company.SocketioURLPublic)
                    console.log('Socket connection Flow Output established')
                } else {
                    console.error('socketioURL is not defined')
                    toast.error('socketioURL is not defined')
                }

                window.addEventListener('resize', handleResize)
                handleResize() // Set initial state based on current window size
            
            } catch (error) {
                console.error('Failed to establish Flow Output socket connection::', error)
            }
        })


        const loadData = async () => {
            // Load your data here based on the flowId
            try {
                // Reset zoneCounters and detectionZones before loading new data
                zoneCounters.value = {}
                detectionZones.value = []

                await flowStore.fetchCurrentFlow(parseInt(route.params.id))

                await CameraStreamStore.fetchInputCameraStreambyFlowId(parseInt(route.params.id))
                detectionZones.value = await DetectionZoneStore.fetchDetectionZonesByFlowId(parseInt(route.params.id))

                // Set up local state based on fetched flow data
                flowId.value = flowStore.currentFlow.FlowId
                cameraStreamUrl.value = flowStore.currentFlow.CameraStreamLink
                detectionZoneNames.value = flowStore.currentFlow.DetectionZone

                // Initialize zone counters
                for (const zone in detectionZoneNames.value) {
                    zoneCounters.value[zone] = 0 // Initialize with 0
                }

                // Set initial duration
                changeDuration(30)

                // Watch stream status
                watchEffect(() => {
                    if (CameraStreamStore.inputcamerastream.StreamStatus !== undefined) {
                        streamStatus.value = CameraStreamStore.inputcamerastream.StreamStatus
                        streamId.value = CameraStreamStore.inputcamerastream.CameraStreamId
                    }
                })
            } catch (error) {
                console.error("Error initializing FlowOutput component:", error)
            } finally {
                isLoading.value = false
            }
        }

        // Watch for changes in flow ID and reload data if it changes
        watch(() => route.params.id, async (newId, oldId) => {
            if (newId !== oldId) {
                flowId.value = parseInt(newId)
                await loadData() // Reload data when the route ID changes
            }
        })

        watch(() => isTodaySelected.value, (newValue) => {
            if (newValue) {
                //await loadData() // Reload data when the route ID changes
            } else {
                duration.value = 30
            }
        })

        onMounted(async () => {
            isLoading.value = true
            loadData()
        })

        const toggleLiveStream = () => {
            isLiveStreamOpen.value = !isLiveStreamOpen.value
        }

        const startDetection = () => {
            streamStatus.value = true
            const streamDetails = createStreamDetails(streamId.value, cameraStreamUrl.value, entranceCam.value, detectionZones.value)
            socket.value.emit('start_stream', streamDetails)
            
            CameraStreamStore.editInputCameraStream(CameraStreamStore.inputcamerastream.CameraStreamId, cameraStreamUrl.value, flowId.value, streamStatus.value)
        }

        const stopDetection = () => {
            streamStatus.value = false
            const streamDetails = createStreamDetails(streamId.value, cameraStreamUrl.value, entranceCam.value, detectionZones.value)
            socket.value.emit('stop_stream', streamDetails)
            
            CameraStreamStore.editInputCameraStream(CameraStreamStore.inputcamerastream.CameraStreamId, cameraStreamUrl.value, flowId.value, streamStatus.value)
        }

        const createStreamDetails = (streamId, streamLink, entranceCam, detectionZones) => {
            const detection_zones = detectionZones.reduce((acc, zone) => {
                const zoneName = zone.DetectionZoneName
                acc[zoneName] = JSON.parse(zone.DetectionZonePointArray).map(point => point.map(coord => Math.round(coord)))
                return acc
            }, {})

            return {
                stream_id: streamId,
                stream_url: streamLink,
                entrance_cam: entranceCam,
                detection_zones: detection_zones
            };
        }

        const handleStreamStatus = (data) => {
            streamStatus.value = data
        }

        const openChartView = () => {
            openChartViewStatus.value = true
            openTableViewStatus.value = false
            openSmartAnalyticsStatus.value = false
        }

        const handleChartData = (data) => {
            chartData.value = data
        }

        const changeDuration = (newDuration) => {
            if (!isTodaySelected.value){
                duration.value = newDuration
            }
        }

        const changeDateRangeMode = (newMode) => {
            dateRangeMode.value = newMode
        }

        const updateClearDatePicker = (newValue) => {
            clearDatePicker.value = newValue
        }
        
        const updateStartDate = (date) => {
            startDate.value = date
        }

        const updateEndDate = (date) => {
            endDate.value = date
        }

        const updateDataGranularity = (granularity) => {
            dataGranularity.value = granularity
        }

        const handleMinimuDurationThresholdUpdate = (data) => {
            minimuDurationThreshold.value = data
        }

        const activateResetSignal = (newValue) => {
            resetSignal.value = newValue
        }

        const openSmartAnalyticsView = () => {
            openSmartAnalyticsStatus.value = true
            openChartViewStatus.value = false
            openTableViewStatus.value = false
        }

        const openTableView = () => {
            openTableViewStatus.value = true
            openChartViewStatus.value = false
            openSmartAnalyticsStatus.value = false
        }

        const handleTabStatusChange = (status) => {
            isTableTabEventList.value = status
        }

        const getColorForZone = (zoneName) => {
            const zoneType = detectionZoneNames.value[zoneName]?.type
            return detection_zone_colors_by_name_solid[zoneType] || 'black'
        }

        const handleResize = () => {
            isSmallScreen.value = window.innerWidth <= 768
        }

        onBeforeUnmount(() => {
            // Optionally clear data when component unmounts
            zoneCounters.value = {}
            detectionZones.value = []
            
            if (socket.value) {
                socket.value.off('zone_counter')
                socket.value.off('heartbeat')
                socket.value.off('error')
                socket.value.off('connect')
                socket.value.disconnect()
            }
            Object.values(heartbeatTimeouts.value).forEach(clearTimeout)

            window.removeEventListener('resize', handleResize)
        })

        return {
            serverConnection,
            cameraHeartBeat,
            activeTab,
            flowStore,
            userStore,
            flowId,
            streamId,
            cameraStreamUrl,
            streamStatus,
            startDetection,
            stopDetection,
            handleStreamStatus,
            toggleLiveStream,
            isLiveStreamOpen,
            socket,
            isTodaySelected,
            changeDateRangeMode,
            changeDuration,
            changeDateRangeMode,
            updateClearDatePicker,
            clearDatePicker,
            updateStartDate,
            updateEndDate,
            updateDataGranularity,
            startDate,
            endDate,
            dataGranularity,
            duration,
            dateRangeMode,
            handleMinimuDurationThresholdUpdate,
            minimuDurationThreshold,
            zoneCounters,
            detectionZoneColorsSolid: detection_zone_colors_by_name_solid,
            getColorForZone,
            activateResetSignal,
            resetSignal,
            openChartView,
            openChartViewStatus,
            handleChartData,
            chartData,
            isLoading,
            openSmartAnalyticsView,
            openSmartAnalyticsStatus,
            openTableView,
            openTableViewStatus,
            handleTabStatusChange,
            isTableTabEventList,
            isSmallScreen
        }
    }
}

</script>

<style scoped>
/* Active tab with red background and white text */
.nav-tabs {
  --bs-nav-tabs-link-active-color: #ffffff !important;
  --bs-nav-tabs-link-active-bg: #4F7EB3 !important;
  --bs-nav-tabs-border-color: #000000 !important;
  --bs-nav-tabs-link-hover-border-color: #000000 !important;
}

/* Inactive tab with white background and black text */
.nav {
  --bs-nav-tabs-link-active-border-color: #000000 !important;
  --bs-nav-link-hover-color: #4F7EB3 !important;
  --bs-nav-link-color: #425461 !important;
}

.card-title {
  color: #4F7EB3 !important;
}

.stream-counter-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 45px;
    margin-left: 15px;
    box-sizing: border-box;
}

.cards-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    flex: 1;
    box-sizing: border-box; /* Ensure padding/margin do not affect the size */
}

.counter-card {
    flex: 1 1 200px; /* Fixed width for each counter card */
    box-sizing: border-box;
    min-width: 150px;
    max-height: 55px;
}

.counter-card.greyed-out {
    background-color: #f0f0f0; /* Light grey background */
    color: #a0a0a0; /* Grey text color */
}

.counter-card-header {
    padding: 2px;
    text-align: center;
}

.counter-card-title {
    margin: 0; /* Remove default margin */
    font-size: small;
    color: #000000 !important
}

.count {
    display: flex;
    justify-content: center;
    height: 30px; 
    padding: 5px;
}

.form-check-label, .form-check-input {
    margin: 0;
    display: flex;
    align-items: center; /* Align label and switch vertically */
}

.data-mode-switch {
    font-size: 14px;
    padding-top: 0; /* Remove padding top to align with the button */
    padding-left: 0;
    display: flex;
    align-items: center; /* Center the toggle switch vertically */
}

.form-check-input {
    margin-right: 5px; /* Space between the switch and the label */
}

.flex-break {
    flex-basis: 100%; /* Forces a new line */
    height: 0; /* No height */
}

.live-stream-wrapper {
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
}

.live-stream-frame {
    align-items: center;
    padding: 5px;
    background-color: #f9f9f9;
}

/*Set a width for the live-stream and live-stream-processed components */
live-stream {
    flex: 1 1 auto;
    min-width: 0; /* Allows the live stream to shrink */
    display: block;
    max-width: 100%; /* Ensures it doesn't exceed the container's width */
}

.btn-primary-start{
  background-color: #6C9046 !important;
  border: none !important;
}

.btn-primary-start:disabled {
    background-color: #a9a9a9 !important;
    cursor: not-allowed !important;
    border: none !important;
}

.btn-primary{
  background-color: #4F7EB3 !important;
  border: none !important;
}

.btn-primary:hover {
  background-color: #B2C149 !important;
}

.btn-primary-data{
  background-color: white !important;
  border: solid 1px #4F7EB3 !important;
  color: #4F7EB3;
  white-space: nowrap;
}

.btn-primary-data:hover{
    background-color: #4F7EB3 !important;
    color: white;
}

.button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: flex-start;
}

.outer-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.data-button-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: space-between; /* Change to flex-start since outer-container handles the alignment */
}

.data-button-container .btn {
    flex: 1 1 auto;
    min-width: 200px; /* Adjust as necessary */
    max-width: 200px; /* Adjust as necessary */
}

.status-indicator {
    display: inline-block;
    width: 20px;
    height: 10px;
    border-radius: 2px;
}

.status-grayed-out {
    background-color: #b4b4b4;
}

.status-running {
    background-color: #6C9046;
}

.status-stopped {
    background-color: #C21500;
}

/* Transition for live stream open/close */
.roll-enter-active, .roll-leave-active {
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.roll-enter, .roll-leave-to {
    transform: scaleY(0);
    opacity: 0;
    transform-origin: top;
}

.toggle-button {
    width: 100%;
    border: none;
    background-color: #f2f2f2;
    color: #4F7EB3;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
}

@media (max-width: 768px) {
    .stream-counter-container {
        flex-direction: column;
    }

    .cards-container {
        gap: 5px; /* Reduce gap on smaller screens */
        justify-content: flex-start; /* Align items to start on smaller screens */
    }

    .counter-card {
        flex: 0 0 calc(100% - 10px); /* Ensure cards fit within the container on smaller screens */
        margin-bottom: 10px; /* Add spacing between cards */
        min-width: 0; /* Allow cards to shrink to fit smaller screens */
    }
}

.sticky-section {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensures it stays on top of other elements */
  background: #F8F8F8; /* Optional: Adds background to avoid overlay issues */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for better visibility */
}

.top-row {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap in the top row */
    gap: 30px;
}

</style>