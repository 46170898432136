<template>
    <div class="card" style="margin-top: 10px; margin-bottom: 10px;">
        <div class="card-header card-main-header d-flex align-items-center">
            <h5 class="card-title card-main-title" style="color: #B2C149">Highlights by Storalytic:</h5>
            <duration-dropdown
                :dateRangeMode="dateRangeMode" 
                :selectedDuration="selectedDuration" 
                @updateDuration="handleUpdateDuration">
            </duration-dropdown>
        </div>

        <div class="card-body">
            <div class="card">
                <div class="card-header">
                    <h6 class="card-title" style="color: #36910d">Store Visitor Intelligence</h6>
                </div>

                <!-- Heatmap and Entrance Chart Row -->
                <div class="card-container-heatmap-row" :style="{ height: `${computedHeight}px` }">
                    <div class="heatmap-container">
                        <heatmap-dashboard :heatmapData="heatmapData" :selectedDuration="selectedDuration"></heatmap-dashboard>
                    </div>
                    <div class="entrance-chart-container">
                        <charts-dashboard-entrance :chartData="entranceChartData" :selectedDuration="selectedDuration"></charts-dashboard-entrance>
                    </div>
                </div>

                <!-- Cards for Store Visitor Intelligence -->
                <div class="grid-container-store-visitor-data">
                    <div class="card counter-card-small">
                        <div class="card-header counter-card-header">
                            <p class="card-title counter-card-title">
                                <b>Average</b> Visitors
                            </p>
                        </div>
                        <div class="card-body count">
                            <div v-if="isLoading">
                                <div class="loader"></div>
                            </div>
                            <div v-else style="text-align: center; color: #36910d">
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p style="margin-top: 10px;"><b> {{ fixedWidgetsData.average_visitors }} </b></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card counter-card-small">
                        <div class="card-header counter-card-header">
                            <p class="card-title counter-card-title">
                                <b>{{ currentWeekdayString }}'s Average</b> Visitors
                            </p>
                        </div>
                        <div class="card-body count">
                            <div v-if="isLoading">
                                <div class="loader"></div>
                            </div>
                            <div v-else-if="fixedWidgetsData.average_same_weekday === 0">
                                    <p style="color: gray; word-wrap: break-word; white-space: normal; max-width: 100%; text-align: center;">No data available</p>
                                </div>
                            <div v-else style="text-align: center; color: #36910d">
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p style="margin-top: 10px;"><b> {{ fixedWidgetsData.average_same_weekday }} </b></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card counter-card-small">
                        <div class="card-header counter-card-header">
                            <p class="card-title counter-card-title">
                                Visitor <b>Trend</b>
                            </p>
                        </div>
                        <div class="card-body count">
                            <div v-if="isLoading">
                                <div class="loader"></div>
                            </div>
                            <div v-else style="text-align: center;">
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p v-if="fixedWidgetsData.trend == 'high upward'" style="color: #2a6041; margin-top: 0px;">
                                        <i class="bi bi-arrow-up-square" style="margin-right: 8px; font-size: x-large;"></i>
                                        <b style="font-size: large;">Up High</b>
                                    </p>
                                    <p v-if="fixedWidgetsData.trend == 'medium upward'" style="color: #36910d; margin-top: 0px;">
                                        <i class="bi bi-arrow-up-square" style="margin-right: 8px; font-size: x-large;"></i>
                                        <b style="font-size: large;">Up</b>
                                    </p>
                                    <p v-else-if="fixedWidgetsData.trend == 'light upward'" style="color: #2ceaa3; margin-bottom: 0;">
                                        <i class="bi bi-arrow-up-square" style="margin-right: 8px; font-size: x-large;"></i>
                                        <b style="font-size: large;">Up Light</b>
                                    </p>
                                    <p v-else-if="fixedWidgetsData.trend == 'stable'" style="color: #000000; margin-bottom: 0;">
                                        <i class="bi bi-arrow-right-square" style="margin-right: 8px; font-size: x-large;"></i>
                                        <b style="font-size: large;">Stable</b>
                                    </p>
                                    <p v-else-if="fixedWidgetsData.trend == 'light downward'" style="color: #a7333f; margin-bottom: 0;">
                                        <i class="bi bi-arrow-down-square" style="margin-right: 8px; font-size: x-large;"></i>
                                        <b style="font-size: large;">Down Light</b>
                                    </p>
                                    <p v-else-if="fixedWidgetsData.trend == 'medium downward'" style="color: #C21500; margin-bottom: 0;">
                                        <i class="bi bi-arrow-down-square" style="margin-right: 8px; font-size: x-large;"></i>
                                        <b style="font-size: large;">Down</b>
                                    </p>
                                    <p v-else-if="fixedWidgetsData.trend == 'high downward'" style="color: #8b0000; margin-bottom: 0;">
                                        <i class="bi bi-arrow-down-square" style="margin-right: 8px; font-size: x-large;"></i>
                                        <b style="font-size: large;">Down High</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card counter-card-small">
                        <div class="card-header counter-card-header">
                            <p class="card-title counter-card-title">
                                Top Visitor <b>Hour</b>
                            </p>
                        </div>
                        <div class="card-body count">
                            <div v-if="isLoading">
                                <div class="loader"></div>
                            </div>
                            <div v-else style="text-align: center;">
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p style="margin-bottom: 0px; color: #36910d"><b> {{ formattedHighestTotalHour.formattedDate }} | {{ formattedHighestTotalHour.formattedTime }}</b></p>
                                    <span style="margin-top: 8px;">{{ fixedWidgetsData.highest_total_hour_count }} Visitors</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card counter-card-small">
                        <div class="card-header counter-card-header">
                            <p class="card-title counter-card-title">
                                Top Visitor <b>Day</b>
                            </p>
                        </div>
                        <div class="card-body count">
                            <div v-if="isLoading">
                                <div class="loader"></div>
                            </div>
                            <div v-else style="text-align: center;">
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p style="margin-bottom: 0px; color: #36910d"><b> {{ formattedHighestTotalDay.formattedDate }} </b></p>
                                    <span style="margin-top: 8px;">{{ fixedWidgetsData.highest_total_day_count }} Visitors</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card counter-card-small">
                        <div class="card-header counter-card-header">
                            <p class="card-title counter-card-title">
                                <b>Busiest</b> Hour on Average
                            </p>
                        </div>
                        <div class="card-body count">
                            <div v-if="isLoading">
                                <div class="loader"></div>
                            </div>
                            <div v-else style="text-align: center;">
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p style="margin-bottom: 0px; color: #36910d"><b> {{ formattedBusiestHour }} </b></p>
                                    <span style="margin-top: 8px;">{{ fixedWidgetsData.average_on_busiest_hour }} Visitors</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-container-second-row">

                <div class="card">
                    <div class="card-header">
                        <h6 class="card-title" style="color: #AD3935">Checkout Waiting Zone Intelligence</h6>
                    </div>

                    <div class="card-body grid-container-entrance-data">
                        <div class="card counter-card-small">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Average</b> Waiting Time
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoading">
                                    <div class="loader"></div>
                                </div>
                                <div v-else style="text-align: center; color: #AD3935">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-top: 10px;"><b> {{ formattedDurationTime(fixedWidgetsData.average_waiting_time) }} </b></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card counter-card-small">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Highest</b> Waiting Time
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoading">
                                    <div class="loader"></div>
                                </div>
                                <div v-else style="text-align: center; color: #AD3935">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-bottom: 0px;"><b> {{ formattedDurationTime(fixedWidgetsData.max_waiting_duration) }} </b></p>
                                        <span style="margin-top: 8px;">at {{ fixedWidgetsData.max_waiting_zone }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card counter-card-small">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Typical</b> Waiting Time
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoading">
                                    <div class="loader"></div>
                                </div>
                                <div v-else style="text-align: center; color: #AD3935">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-bottom: 0px;"><b> {{ formattedDurationTime(fixedWidgetsData.most_typical_waiting_time[0]) }} </b></p>
                                        <span style="margin-top: 8px;">{{ fixedWidgetsData.most_typical_waiting_time[1] }} times</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card counter-card-small">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Fastest</b> Waiting Line
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoading">
                                    <div class="loader"></div>
                                </div>
                                <div v-else style="text-align: center; color: #AD3935">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-top: 10px"><b> {{ fixedWidgetsData.fastest_checkout_zone }} </b></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card counter-card-small">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Slowest</b> Waiting Line
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoading">
                                    <div class="loader"></div>
                                </div>
                                <div v-else style="text-align: center; color: #AD3935">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-top: 10px;"><b> {{ fixedWidgetsData.slowest_checkout_zone }} </b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h6 class="card-title" style="color: #D3A741">Checkout Interaction Zone Intelligence</h6>
                    </div>

                    <div class="card-body grid-container-entrance-data">
                        <div class="card counter-card-small">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Average</b> Interaction Time
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoading">
                                    <div class="loader"></div>
                                </div>
                                <div v-else style="text-align: center; color: #D3A741">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-top: 10px;"><b> {{ formattedDurationTime(fixedWidgetsData.average_interaction_time) }} </b></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card counter-card-small">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Highest</b> Interaction Time
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoading">
                                    <div class="loader"></div>
                                </div>
                                <div v-else style="text-align: center; color: #D3A741">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-bottom: 0px;"><b> {{ formattedDurationTime(fixedWidgetsData.max_interaction_duration) }} </b></p>
                                        <span style="margin-top: 8px;">at {{ fixedWidgetsData.max_interaction_zone }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card counter-card-small">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Typical</b> Interaction Time
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoading">
                                    <div class="loader"></div>
                                </div>
                                <div v-else style="text-align: center; color: #D3A741">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-bottom: 0px;"><b> {{ formattedDurationTime(fixedWidgetsData.most_typical_interaction_time[0]) }} </b></p>
                                        <span style="margin-top: 8px;">{{ fixedWidgetsData.most_typical_interaction_time[1] }} times</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card counter-card-small">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Fastest</b> Interaction Checkout
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoading">
                                    <div class="loader"></div>
                                </div>
                                <div v-else style="text-align: center; color: #D3A741">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-top: 10px"><b> {{ fixedWidgetsData.fastest_interaction_zone }} </b></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card counter-card-small">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Slowest</b> Interaction Checkout
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoading">
                                    <div class="loader"></div>
                                </div>
                                <div v-else style="text-align: center; color: #D3A741">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-top: 10px;"><b> {{ fixedWidgetsData.slowest_interaction_zone }} </b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="card">
                    <div class="card-header">
                        <h6 class="card-title" style="color: #dd8725">General Checkout Intelligence</h6>
                    </div>

                    <div class="card-body grid-container-entrance-data">
                        <div class="card counter-card-small">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Most</b> Used Checkout
                                </p>
                            </div>
                            <div class="card-body count">
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <div v-if="isLoading">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else style="text-align: center; color: #dd8725">
                                        <p style="margin-bottom: 0px"><b> {{ fixedWidgetsData.most_used_interaction_zone.zone_name }} </b></p>
                                        <span style="margin-top: 8px;">{{ fixedWidgetsData.most_used_interaction_zone.visitor_count }} Interactions</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="card counter-card-small">
                            <div class="card-header counter-card-header">
                                <p class="card-title counter-card-title">
                                    <b>Least</b> Used Checkout
                                </p>
                            </div>
                            <div class="card-body count">
                                <div v-if="isLoading">
                                    <div class="loader"></div>
                                </div>
                                <div v-else style="text-align: center; color:#dd8725">
                                    <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                        <p style="margin-bottom: 0px"><b> {{ fixedWidgetsData.least_used_interaction_zone.zone_name }} </b></p>
                                        <span style="margin-top: 8px;">{{ fixedWidgetsData.least_used_interaction_zone.visitor_count }} Interactions</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product-zone-block">
                <div class="card" >
                    <div class="card-header">
                        <h6 class="card-title" style="color: #004F6E">Product Zone Intelligence</h6>
                    </div>

                    <div class="card-body grid-container-product-data">
                        <div class="card-stack">
                            <div class="card counter-card-small">
                                <div class="card-header counter-card-header">
                                    <p class="card-title counter-card-title">
                                        Top <b>Avg. Duration</b>
                                    </p>
                                </div>
                                <div class="card-body count">
                                    <div v-if="isLoading">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else style="text-align: center; color: #004F6E">
                                        <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                            <p style="margin-bottom: 0px;"><b> {{ fixedWidgetsData.best_product_zone_by_avg_duration.zone_name }} </b></p>
                                            <span style="margin-top: 8px;">Average Duration: {{ formattedDurationTime(fixedWidgetsData.best_product_zone_by_avg_duration.avg_duration) }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card counter-card-small">
                                <div class="card-header counter-card-header">
                                    <p class="card-title counter-card-title">
                                        <b>Top</b> Visitor Count
                                    </p>
                                </div>
                                <div class="card-body count">
                                    <div v-if="isLoading">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else style="text-align: center; color: #004F6E">
                                        <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                            <p style="margin-bottom: 0px;"><b> {{ fixedWidgetsData.best_product_zone_by_visitors.zone_name }} </b></p>
                                            <span style="margin-top: 8px;">{{ fixedWidgetsData.best_product_zone_by_visitors.visitor_count }} Visitors</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card counter-card-small">
                                <div class="card-header counter-card-header">
                                    <p class="card-title counter-card-title">
                                        <b>Lowest</b> Visitor Count
                                    </p>
                                </div>
                                <div class="card-body count">
                                    <div v-if="isLoading">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else style="text-align: center; color: #004F6E">
                                        <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                            <p style="margin-bottom: 0px;"><b> {{ fixedWidgetsData.worst_product_zone_by_visitors.zone_name }} </b></p>
                                            <span style="margin-top: 8px;">{{ fixedWidgetsData.worst_product_zone_by_visitors.visitor_count }} Visitors</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="chart-container">
                            <div v-if="isLoading" class="loader-overlay">
                                <div class="loader"></div>
                            </div>
                            <div v-else>
                                <charts-fixed-widgets-product class="full-width-chart" :totalDurationPerProductZone="fixedWidgetsData.total_duration_per_product_zone" :percentageByDurationRangeInProductZone="fixedWidgetsData.percentage_by_duration_range_in_product_zone" :productZonePresent="true"></charts-fixed-widgets-product>
                            </div>
                        </div>
                    </div>

                    <!-- Chart Row -->
                    <div class="card-container-pdd-row">
                        <div class="entrance-chart-container">
                            <charts-dashboard-pdd :chartData="bestProductAvgDur" :selectedDuration="selectedDuration" :zoneType="'Product'" :chartType="'AvgDur'"></charts-dashboard-pdd>
                        </div>
                        <div class="entrance-chart-container">
                            <charts-dashboard-pdd :chartData="bestProductCount" :selectedDuration="selectedDuration" :zoneType="'Product'" :chartType="'Counts'"></charts-dashboard-pdd>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product-zone-block" style="margin-top: 100px;">
                <div class="card">
                    <div class="card-header">
                        <h6 class="card-title" style="color: #62B5D9">Demo Zone Intelligence</h6>
                    </div>

                    <div class="card-body grid-container-product-data">
                        <div class="card-stack">
                            <div class="card counter-card-small">
                                <div class="card-header counter-card-header">
                                    <p class="card-title counter-card-title">
                                        Top <b>Avg. Duration</b>
                                    </p>
                                </div>
                                <div class="card-body count">
                                    <div v-if="isLoading">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else style="text-align: center; color: #62B5D9">
                                        <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                            <p style="margin-bottom: 0px;"><b> {{ fixedWidgetsData.best_demo_zone_by_avg_duration.zone_name }} </b></p>
                                            <span style="margin-top: 8px;">Average Duration: {{ formattedDurationTime(fixedWidgetsData.best_demo_zone_by_avg_duration.avg_duration) }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card counter-card-small">
                                <div class="card-header counter-card-header">
                                    <p class="card-title counter-card-title">
                                        <b>Top</b> Visitor Count
                                    </p>
                                </div>
                                <div class="card-body count">
                                    <div v-if="isLoading">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else style="text-align: center; color: #62B5D9">
                                        <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                            <p style="margin-bottom: 0px;"><b> {{ fixedWidgetsData.best_demo_zone_by_visitors.zone_name }} </b></p>
                                            <span style="margin-top: 8px;">{{ fixedWidgetsData.best_demo_zone_by_visitors.visitor_count }} Visitors</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card counter-card-small">
                                <div class="card-header counter-card-header">
                                    <p class="card-title counter-card-title">
                                        <b>Lowest</b> Visitor Count
                                    </p>
                                </div>
                                <div class="card-body count">
                                    <div v-if="isLoading">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else style="text-align: center; color: #62B5D9">
                                        <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                            <p style="margin-bottom: 0px;"><b> {{ fixedWidgetsData.worst_demo_zone_by_visitors.zone_name }} </b></p>
                                            <span style="margin-top: 8px;">{{ fixedWidgetsData.worst_demo_zone_by_visitors.visitor_count }} Visitors</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="chart-container">
                            <div v-if="isLoading" class="loader-overlay">
                                <div class="loader"></div>
                            </div>
                            <div v-else>
                                <charts-fixed-widgets-demo class="full-width-chart" :totalDurationPerDemoZone="fixedWidgetsData.total_duration_per_demo_zone" :percentageByDurationRangeInDemoZone="fixedWidgetsData.percentage_by_duration_range_in_demo_zone" :demoZonePresent="true"></charts-fixed-widgets-demo>
                            </div>
                        </div>
                    </div>

                    <!-- Chart Row -->
                    <div class="card-container-pdd-row">
                        <div class="entrance-chart-container">
                            <charts-dashboard-pdd :chartData="bestDemoAvgDur" :selectedDuration="selectedDuration" :zoneType="'Demo'" :chartType="'AvgDur'"></charts-dashboard-pdd>
                        </div>
                        <div class="entrance-chart-container">
                            <charts-dashboard-pdd :chartData="bestDemoCount" :selectedDuration="selectedDuration" :zoneType="'Demo'" :chartType="'Counts'"></charts-dashboard-pdd>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product-zone-block" style="margin-top: 100px;">
                <div class="card">
                    <div class="card-header">
                        <h6 class="card-title" style="color: #4F7EB3">Display Zone Intelligence</h6>
                    </div>

                    <div class="card-body grid-container-product-data">
                        <div class="card-stack">
                            <div class="card counter-card-small">
                                <div class="card-header counter-card-header">
                                    <p class="card-title counter-card-title">
                                        Top <b>Avg. Duration</b>
                                    </p>
                                </div>
                                <div class="card-body count">
                                    <div v-if="isLoading">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else style="text-align: center; color: #4F7EB3">
                                        <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                            <p style="margin-bottom: 0px;"><b> {{ fixedWidgetsData.best_display_zone_by_avg_duration.zone_name }} </b></p>
                                            <span style="margin-top: 8px;">Average Duration: {{ formattedDurationTime(fixedWidgetsData.best_display_zone_by_avg_duration.avg_duration) }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card counter-card-small">
                                <div class="card-header counter-card-header">
                                    <p class="card-title counter-card-title">
                                        <b>Top</b> Visitor Count
                                    </p>
                                </div>
                                <div class="card-body count">
                                    <div v-if="isLoading">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else style="text-align: center; color: #4F7EB3">
                                        <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                            <p style="margin-bottom: 0px;"><b> {{ fixedWidgetsData.best_display_zone_by_visitors.zone_name }} </b></p>
                                            <span style="margin-top: 8px;">{{ fixedWidgetsData.best_display_zone_by_visitors.visitor_count }} Visitors</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card counter-card-small">
                                <div class="card-header counter-card-header">
                                    <p class="card-title counter-card-title">
                                        <b>Lowest</b> Visitor Count
                                    </p>
                                </div>
                                <div class="card-body count">
                                    <div v-if="isLoading">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else style="text-align: center; color: #4F7EB3">
                                        <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                            <p style="margin-bottom: 0px;"><b> {{ fixedWidgetsData.worst_display_zone_by_visitors.zone_name }} </b></p>
                                            <span style="margin-top: 8px;">{{ fixedWidgetsData.worst_display_zone_by_visitors.visitor_count }} Visitors</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="chart-container">
                            <div v-if="isLoading" class="loader-overlay">
                                <div class="loader"></div>
                            </div>
                            <div v-else>
                                <charts-fixed-widgets-display class="full-width-chart" :totalDurationPerDisplayZone="fixedWidgetsData.total_duration_per_display_zone" :percentageByDurationRangeInDisplayZone="fixedWidgetsData.percentage_by_duration_range_in_display_zone" :displayZonePresent="true"></charts-fixed-widgets-display>
                            </div>
                        </div>

                    </div>

                    <!-- Chart Row -->
                    <div class="card-container-pdd-row">
                        <div class="entrance-chart-container">
                            <charts-dashboard-pdd :chartData="bestDisplayAvgDur" :selectedDuration="selectedDuration" :zoneType="'Display'" :chartType="'AvgDur'"></charts-dashboard-pdd>
                        </div>
                        <div class="entrance-chart-container">
                            <charts-dashboard-pdd :chartData="bestDisplayCount" :selectedDuration="selectedDuration" :zoneType="'Display'" :chartType="'Counts'"></charts-dashboard-pdd>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeatmapDashboard from './HeatmapDashboard.vue'
import ChartsDashboardEntrance from './ChartsDashboardEntrance.vue'
import ChartsDashboardPdd from './ChartsDashboardPdd.vue'
import ChartsFixedWidgetsProduct from './ChartsFixedWidgetsProduct.vue'
import ChartsFixedWidgetsDemo from './ChartsFixedWidgetsDemo.vue'
import ChartsFixedWidgetsDisplay from './ChartsFixedWidgetsDisplay.vue'

import DurationDropdown from './DurationDropdown.vue'

import { onMounted, onBeforeUnmount, ref, computed, watch } from 'vue'

export default {
    name: 'FixedWidgets',

    components: {
        HeatmapDashboard,
        ChartsDashboardEntrance,
        ChartsDashboardPdd,
        ChartsFixedWidgetsProduct,
        ChartsFixedWidgetsDemo,
        ChartsFixedWidgetsDisplay,
        DurationDropdown
    },

    setup(props, { emit }) {
        const fixedWidgetsData = ref({})

        const pddChartData = ref({})
        const bestProductAvgDur = ref({})
        const bestProductCount = ref({})
        const bestDemoAvgDur = ref({})
        const bestDemoCount = ref({})
        const bestDisplayAvgDur = ref({})
        const bestDisplayCount = ref({})

        const windowWidth = ref(window.innerWidth)

        const dateRangeMode = ref("duration")
        const selectedDuration  = ref(30)

        const heatmapData = ref([])
        const entranceChartData = ref({})

        // Separate loading states for each widget
        const isLoading = ref(true)

        // Computed property to format the highestTotalHour value
        const formattedHighestTotalHour = computed(() => {
    
            if (!fixedWidgetsData.value.highest_total_hour) return { formattedDate: "", formattedTime: "" }

            const date = new Date(fixedWidgetsData.value.highest_total_hour)

            if (isNaN(date.getTime())) {
                console.error("Invalid Date Format:", fixedWidgetsData.value.highest_total_hour)
                return { formattedDate: "Invalid Date", formattedTime: "Invalid Time" }
            }

            // Extract date components
            const weekday = date.toLocaleDateString('en-GB', { weekday: 'short' })
            const day = date.getDate()
            const month = date.toLocaleDateString('en-GB', { month: 'short' })

            const formattedDate = `${weekday}, ${day} ${month}`
            
            // Extract time and compute the next hour
            const startHour = date.getHours()
            
            const endHour = (startHour + 1) % 24 // Ensure it stays within 24 hours

            const formattedTime = `${startHour}h-${endHour}h`

            return {
                formattedDate,
                formattedTime
            }
        })

        // Computed property to format the highestTotalDay value
        const formattedHighestTotalDay = computed(() => {

            if (!fixedWidgetsData.value.highest_total_day) return { formattedDate: ""}

            const date = new Date(fixedWidgetsData.value.highest_total_day)

            if (isNaN(date.getTime())) {
                console.error("Invalid Date Format:", fixedWidgetsData.value.highest_total_day)
                return { formattedDate: "Invalid Date"}
            }

            // Extract date components
            const weekday = date.toLocaleDateString('en-GB', { weekday: 'short' })
            const day = date.getDate()
            const month = date.toLocaleDateString('en-GB', { month: 'short' })

            const formattedDate = `${weekday}, ${day} ${month}`

            return {
                formattedDate
            }
        })

        const formattedBusiestHour = computed(() => {
            if (fixedWidgetsData.value.busiest_hour === undefined || fixedWidgetsData.value.busiest_hour === null) {
                return ""
            }

            const startHour = parseInt(fixedWidgetsData.value.busiest_hour, 10)
            const endHour = (startHour + 1) % 24 // Calculate the end hour, wrapping around at 24

            return `${startHour}h-${endHour}h`
        })

        const currentWeekdayString = computed(() => {
            if (fixedWidgetsData.value.current_weekday >= 0 && fixedWidgetsData.value.current_weekday <= 6) {
                const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
                return daysOfWeek[fixedWidgetsData.value.current_weekday]
            } else {
                return 'Same Weekday'
            }
        })

        const formattedAverageDurationTime = computed (() => {
            if (bestProductZoneByAvgDuration.value.avg_duration < 60) {
                return `${bestProductZoneByAvgDuration.value.avg_duration.toFixed(0)} sec`
            } else if (bestProductZoneByAvgDuration.value.avg_duration < 3600) {
                const minutes = Math.floor(bestProductZoneByAvgDuration.value.avg_duration / 60)
                const remainingSeconds = bestProductZoneByAvgDuration.value.avg_duration % 60
                return `${minutes} min ${remainingSeconds.toFixed(0)} sec`
            } else {
                const hours = Math.floor(bestProductZoneByAvgDuration.value.avg_duration / 3600)
                const remainingMinutes = Math.floor((bestProductZoneByAvgDuration.value.avg_duration % 3600) / 60)
                const remainingSeconds = bestProductZoneByAvgDuration.value.avg_duration % 60
                return `${hours} h ${remainingMinutes} min ${remainingSeconds.toFixed(0)} sec`
            }
        })

        const formattedDurationTime = computed(() => {
            return (seconds) => {
                if (seconds < 60) {
                    return `${seconds.toFixed(0)} sec`
                } else if (seconds < 3600) {
                    const minutes = Math.floor(seconds / 60)
                    const remainingSeconds = seconds % 60
                    return `${minutes} min ${remainingSeconds.toFixed(0)} sec`
                } else {
                    const hours = Math.floor(seconds / 3600)
                    const remainingMinutes = Math.floor((seconds % 3600) / 60)
                    const remainingSeconds = seconds % 60
                    return `${hours} h ${remainingMinutes} min ${remainingSeconds.toFixed(0)} sec`
                }
            }
        })

        const computedHeight = computed(() => {
            const baseHeight = 50 // Fixed height for title and fixed stuff
            const additionalHeight = 25 // Height per array member
            const rowCount = heatmapData.value?.heatmap_matrix?.[0]?.length || 0

            const calculatedHeight = baseHeight + additionalHeight * rowCount

            let additionalHeightForChart

            if (rowCount < 12 ) {
                additionalHeightForChart = 300 - (rowCount * 25)
            } else {
                additionalHeightForChart = 0
            }

            // Add 300px when width is below 1500px
            return windowWidth.value < 1500 ? calculatedHeight + 400 : calculatedHeight + additionalHeightForChart
        })

        // Utility function to generate a random number between min and max
        const getRandomNumber = (min, max, decimals = 0) => {
            return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + Math.ceil(min)
        }

        // Utility function to pick a random element from an array
        const getRandomElement = (array) => {
        return array[Math.floor(Math.random() * array.length)]
        }

        const getRandomDate = (daysBack, hoursBack, includeTime = false) => {
            const endDate = new Date() // Today's date
            endDate.setDate(endDate.getDate() - 2) // Adjust to yesterday
            endDate.setHours(0, 0, 0, 0) // Reset time to start of the day

            const startDate = new Date();
            startDate.setDate(startDate.getDate() - daysBack) // Start of the range

            // Generate a random timestamp between startDate and endDate
            const randomTimestamp = startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime())
            const randomDate = new Date(randomTimestamp)

            if (includeTime) {
                // Generate a random hour within the day
                randomDate.setHours(getRandomNumber(9, 17), getRandomNumber(0, 59), 0, 0)
            }

            return includeTime ? randomDate.toISOString() : randomDate.toISOString().split('T')[0]
        }

        const calculateAverageVisitors = (chartData) => {
            // Extract all `zone_in_count` values from the chartData object
            const values = Object.values(chartData)

            // Sum all the values
            const total = values.reduce((sum, value) => sum + value, 0)

            // Calculate the average
            const average = total / values.length

            return Math.round(average) // Round to the nearest whole number
        }

        const calculateAverageSameWeekday = (chartData) => {
            const today = new Date();
            const todayWeekday = today.getDay() // 0 = Sunday, 6 = Saturday

            // Filter values with the same weekday as today
            const sameWeekdayValues = Object.entries(chartData)
                .filter(([date]) => {
                    const weekday = new Date(date).getDay()
                    return weekday === todayWeekday
                })
                .map(([, value]) => value) // Extract the values

            // Sum the filtered values
            const total = sameWeekdayValues.reduce((sum, value) => sum + value, 0)

            // Calculate the average
            const average = sameWeekdayValues.length > 0 ? total / sameWeekdayValues.length : 0

            return Math.round(average) // Round to the nearest whole number
        }

        const calculateTrendWithClassification = (chartData) => {
            // Extract the data points (days and totals) and filter out zeros
            const entries = Object.entries(chartData).filter(([, value]) => value !== 0);
            const days = entries.map(([date], index) => index); // Use indices as days
            const totals = entries.map(([, value]) => value);

            if (totals.length === 0 || totals.length === 1) {
                return "stable" // Default trend if no valid data
            }

            // Calculate means
            const meanX = days.reduce((sum, x) => sum + x, 0) / days.length
            const meanY = totals.reduce((sum, y) => sum + y, 0) / totals.length

            // Calculate slope (m) and intercept (b)
            let numerator = 0
            let denominator = 0
            for (let i = 0; i < days.length; i++) {
                numerator += (days[i] - meanX) * (totals[i] - meanY)
                denominator += (days[i] - meanX) ** 2
            }

            const slope = numerator / denominator
            const intercept = meanY - slope * meanX

            // Calculate r-squared
            const totalVariance = totals.reduce((sum, y) => sum + (y - meanY) ** 2, 0)
            const residualVariance = totals.reduce((sum, y, i) => {
                const predictedY = slope * days[i] + intercept
                return sum + (y - predictedY) ** 2
            }, 0)
            const rSquared = 1 - residualVariance / totalVariance

            // Determine trend classification based on the slope
            let trend
            if (slope > 1.0) {
                trend = "high upward"
            } else if (slope > 0.5) {
                trend = "medium upward"
            } else if (slope > 0.1) {
                trend = "light upward"
            } else if (slope >= -0.1) {
                trend = "stable"
            } else if (slope >= -0.5) {
                trend = "light downward"
            } else if (slope >= -1.0) {
                trend = "medium downward"
            } else {
                trend = "high downward"
            }

            return trend
        }

        const calculateHighestTotalHour = (heatmapData) => {
            const { heatmap_matrix, minHour } = heatmapData

            // Count non-zero values in the array with key 0
            const zeroRowsCount = heatmap_matrix.filter(row => row.every(value => value === 0)).length
            // If there are 6 or more non-zero values, retain random generated values
            
            if (zeroRowsCount === 1 && selectedDuration.value !== 7) {
                return {
                    highest_total_hour: getRandomDate(0, 0, true), // Replace with your existing random logic
                    highest_total_hour_count: getRandomNumber(368/9, 472/9), // Replace with your existing random logic
                };
            }

            // Find the maximum value, day index, and hour index for 7 or fewer days
            let maxCount = 0;
            let maxDayIndex = -1;
            let maxHourIndex = -1;

            // Iterate over hours (rows)
            for (let dayIndex = 0; dayIndex < heatmap_matrix.length; dayIndex++) {
                for (let hourIndex = 0; hourIndex < heatmap_matrix[dayIndex].length; hourIndex++) {
                    const dayValue = heatmap_matrix[dayIndex][hourIndex];
                    if (dayValue > maxCount) {
                        maxCount = dayValue;
                        maxHourIndex = hourIndex;  // Column index represents the day
                        maxDayIndex = dayIndex; // Row index represents the hour
                    }
                }
            }

            // Calculate the corresponding hour and date
            const highestHour = `${(minHour + maxHourIndex).toString().padStart(2, '0')}:00`;
            const today = new Date();
            const currentWeekday = today.getDay()
            // Map the `maxDayIndex` to the correct date
            let dayOffset;
            if (currentWeekday === 0) {
                // If today is Sunday, adjust for start of the week
                dayOffset = 6 - maxDayIndex; // Count backward from Saturday
            } else if (maxDayIndex > currentWeekday - 1) {
                // If `maxDayIndex` is in the next week's range, wrap around
                dayOffset = currentWeekday - 1 + (7 - maxDayIndex);
            } else {
                // Normal case: `maxDayIndex` is within this week's range
                dayOffset = currentWeekday - 1 - maxDayIndex;
            }
            const highestDay = new Date(today);
            highestDay.setDate(today.getDate() - dayOffset); // Map index to date

            const highestDayFormatted = highestDay.toISOString().split("T")[0];

            return {
                highest_total_hour: `${highestDayFormatted} ${highestHour}`,
                highest_total_hour_count: maxCount,
            };
        };

        const calculateHighestTotalDay = (entranceChartData) => {
            let highestValue = -Infinity; // Start with the smallest possible number
            let highestDate = null;

            // Iterate over the entrance chart data to find the highest value and its date
            for (const [date, count] of Object.entries(entranceChartData)) {
                if (count > highestValue) {
                    highestValue = count;
                    highestDate = date; // Save the date corresponding to the highest value
                }
            }

            // Return the results
            return {
                highest_total_day: highestDate,
                highest_total_day_count: highestValue,
            };
        };

        const calculateBusiestHour = (heatmapData) => {
            const { heatmap_matrix, minHour } = heatmapData

            // Initialize an array to store the average for each key (hour)
            const hourAverages = new Array(heatmap_matrix[0].length).fill(0).map((_, hourIndex) => {
                let sum = 0; // To sum up the values for the current hour
                let count = 0; // To count the non-zero values

                // Iterate over each day (column) for the current hour
                heatmap_matrix.forEach((dayRow) => {
                    const value = dayRow[hourIndex];
                    if (value > 0) { // Only include non-zero values
                        sum += value;
                        count++;
                    }
                });

                // Calculate the average if there are non-zero values
                return count > 0 ? sum / count : 0; // Avoid division by zero
            });

            // Find the key (hour index) with the highest average
            let maxAverage = 0;
            let busiestHourIndex = -1;

            hourAverages.forEach((average, index) => {
                if (average > maxAverage) {
                    maxAverage = average;
                    busiestHourIndex = index;
                }
            });

            const busiestHour  = `${(minHour + busiestHourIndex).toString().padStart(2, '0')}:00`

            return {
                busiest_hour: busiestHour , // The key (hour) with the highest average
                average_on_busiest_hour: Math.round(maxAverage), // The average value for that hour
            };
        };

        // Function to generate random data
        const generateRandomData = () => {
            const demoZoneNames = ['Demo Robotmaaier', 'Demo Gereedschapswagen']
            const displayZoneNames = ['Promo Hoge Druk Reiniger', 'Promo Veiligheidsschoenen']
            const productZoneNames = ['Rek Veiligheiddschoenen', 'Metabo zone', 'Dewalt zone']
            const interactionZoneNames = ['Kassa 1', 'Kassa 2']
            const checkoutZoneNames = ['Kassa 1 Wachtzone', 'Kassa 2 Wachtzone']

            // Helper function to ensure best and worst zones are different
            const getBestAndWorstZones = (zoneNames) => {
                const bestZone = getRandomElement(zoneNames) // Select a random best zone
                const remainingZones = zoneNames.filter((zone) => zone !== bestZone) // Exclude the best zone
                const worstZone = getRandomElement(remainingZones) // Select a random worst zone
                return { bestZone, worstZone }
            }

            // Function to generate percentage by duration range
            const generatePercentageByDurationRange = (zoneNames, ranges) => {
                return zoneNames.reduce((acc, zone) => {
                let remainingPercentage = 100 // Start with 100% to distribute
                const percentages = {}

                // Assign random values for all but the last range
                for (let i = 0; i < ranges.length - 1; i++) {
                    const randomPercentage = getRandomNumber(0, remainingPercentage, 2); // Generate a random percentage
                    percentages[ranges[i]] = randomPercentage;
                    remainingPercentage -= randomPercentage; // Subtract from the remaining percentage
                }

                // Assign the remaining percentage to the last range to ensure the sum is 100
                percentages[ranges[ranges.length - 1]] = remainingPercentage.toFixed(2) * 1

                acc[zone] = percentages; // Add the zone data
                return acc;
                }, {})
            }

            const durationRanges = [
                '0-5 sec',
                '6-15 sec',
                '16-60 sec',
                '1-2 min',
                '2-5 min',
                '5+ min',
            ]

            return {
                average_interaction_time: getRandomNumber(20, 40, 2),
                average_on_busiest_hour: calculateBusiestHour(heatmapData.value).average_on_busiest_hour,
                average_same_weekday: calculateAverageSameWeekday(entranceChartData.value),
                average_visitors: calculateAverageVisitors(entranceChartData.value),
                average_waiting_time: getRandomNumber(30, 110, 2),
                best_demo_zone_by_avg_duration: {
                    avg_duration: getRandomNumber(110, 180, 2),
                    zone_name: 'Dewalt Gereedschapswagen Demo',
                },
                best_display_zone_by_avg_duration: {
                    avg_duration: getRandomNumber(4, 10, 2),
                    zone_name: 'Promo Houtbewerkingsmachines',
                },
                best_product_zone_by_avg_duration: {
                    avg_duration: getRandomNumber(170, 300, 2),
                    zone_name: 'Rek Veiligheiddschoenen',
                },
                best_demo_zone_by_visitors: { visitor_count: getRandomNumber(50, 100), zone_name: 'Demo Robotmaaier' },
                worst_demo_zone_by_visitors: { visitor_count: getRandomNumber(20, 50), zone_name: 'Dewalt Gereedschapswagen Demo' },
                best_display_zone_by_visitors: { visitor_count: getRandomNumber(50, 100), zone_name: 'Promo Houtbewerkingsmachines' },
                worst_display_zone_by_visitors: { visitor_count: getRandomNumber(20, 50), zone_name: 'Promo Veiligheidsschoenen' },
                best_product_zone_by_visitors: { visitor_count: getRandomNumber(100, 200), zone_name: 'Dewalt zone' },
                worst_product_zone_by_visitors: { visitor_count: getRandomNumber(50, 100), zone_name: 'Rek Veiligheiddschoenen' },
                busiest_hour: calculateBusiestHour(heatmapData.value).busiest_hour,
                current_weekday: new Date().getDay() === 0 ? 6 : new Date().getDay() - 1,
                date: new Date().toISOString(),
                fastest_checkout_zone: 'Kassa 1',
                fastest_interaction_zone: 'Kassa 1',
                highest_total_day: calculateHighestTotalDay(entranceChartData.value).highest_total_day,
                highest_total_day_count: calculateHighestTotalDay(entranceChartData.value).highest_total_day_count,
                highest_total_hour: calculateHighestTotalHour(heatmapData.value).highest_total_hour,
                highest_total_hour_count: calculateHighestTotalHour(heatmapData.value).highest_total_hour_count,
                least_used_interaction_zone: {
                    visitor_count: getRandomNumber(20, 100),
                    zone_name: 'Kassa 1',
                },
                max_interaction_duration: getRandomNumber(180, 250, 2),
                max_interaction_zone: getRandomElement(interactionZoneNames),
                max_waiting_duration: getRandomNumber(400, 500, 2),
                max_waiting_zone: getRandomElement(checkoutZoneNames),
                most_typical_interaction_time: [getRandomNumber(25, 40), getRandomNumber(50, 75)],
                most_typical_waiting_time: [getRandomNumber(35, 55), getRandomNumber(50, 75)],
                most_used_interaction_zone: {
                    visitor_count: getRandomNumber(100, 200),
                    zone_name: 'Kassa 2',
                },
                percentage_by_duration_range_in_demo_zone: generatePercentageByDurationRange(demoZoneNames, durationRanges),
                percentage_by_duration_range_in_display_zone: generatePercentageByDurationRange(displayZoneNames, durationRanges),
                percentage_by_duration_range_in_product_zone: generatePercentageByDurationRange(productZoneNames, durationRanges),
                slowest_checkout_zone: 'Kassa 2',
                slowest_interaction_zone: 'Kassa 2',
                total_duration_per_demo_zone: demoZoneNames.reduce((acc, zone) => {
                    acc[zone] = getRandomNumber(2500, 60000, 2)
                    return acc;
                    }, {}),
                total_duration_per_display_zone: displayZoneNames.reduce((acc, zone) => {
                    acc[zone] = getRandomNumber(300, 600, 2)
                    return acc;
                }, {}),
                total_duration_per_product_zone: productZoneNames.reduce((acc, zone) => {
                    acc[zone] = getRandomNumber(2500, 120000, 2)
                    return acc;
                }, {}),
                trend: calculateTrendWithClassification(entranceChartData.value),
            }
        }

        const adjustDataForTimeframe = (data, timeframe) => {
            let multiplier;

            switch (timeframe) {
                case 7:
                    multiplier = 7
                    break
                case 30:
                    multiplier = 26
                    break
                case 365:
                    multiplier = 315
                    break
                case 1000:
                    const currentDay = new Date().getDay()
                    multiplier = currentDay === 0 ? 7 : currentDay
                    break
                case 2000:
                    const today = new Date().getDate()
                    multiplier = today
                    break
                default:
                    multiplier = 1
            }

            const keysToScale = [
                'most_typical_interaction_time',
                'most_typical_waiting_time',
                'best_demo_zone_by_visitors',
                'worst_demo_zone_by_visitors',
                'best_display_zone_by_visitors',
                'worst_display_zone_by_visitors',
                'best_product_zone_by_visitors',
                'worst_product_zone_by_visitors',
                'total_duration_per_demo_zone',
                'total_duration_per_display_zone',
                'total_duration_per_product_zone',
            ]

            const deepScale = (obj) => {
                if (Array.isArray(obj)) {
                return obj.map((val, idx) => (idx === 1 ? val * multiplier : val))
                } else if (typeof obj === 'object' && obj !== null) {
                return Object.keys(obj).reduce((scaledObj, key) => {
                    scaledObj[key] = keysToScale.includes(key)
                    ? deepScale(obj[key])
                    : obj[key]
                    return scaledObj
                }, {})
                }
                return obj
            };

            const adjustedData = deepScale(data)

            return adjustedData
        }

        const generateHeatmapData = () => {
            const days = 7 // Number of days (rows)
            const hours = 17 - 9 + 1 // Number of hours (columns) between 9 AM and 5 PM (inclusive)
            const minHour = 9
            const maxHour = 17

            // Define the general range for values
            const minValue = Math.ceil(9) // Minimum value
            const maxValue = Math.floor(300 / 9) // Maximum value

            // Define the higher range for the second column
            const higherMinValue = Math.ceil(368 / 9) // Higher minimum value
            const higherMaxValue = Math.floor(472 / 9) // Higher maximum value

            // Function to generate a random value within a given range
            const getRandomValue = (min, max) => {
                return Math.floor(Math.random() * (max - min + 1)) + min
            }

            // Function to generate a day's data
            const generateDayData = (dayIndex) => {
                if (dayIndex === 6) {
                    // Return an array of zeros for key 6
                    return new Array(hours).fill(0)
                }

                return new Array(hours).fill(0).map((_, index) => {
                    if (index === 2) {
                        // Use higher range for the second column
                        return getRandomValue(higherMinValue, higherMaxValue)
                    } else {
                        // Use normal range for other columns
                        return getRandomValue(minValue, maxValue)
                    }
                })
            }

            // Generate the heatmap matrix
            const heatmapMatrix = new Array(days)
                .fill(null)
                .map((_, dayIndex) => generateDayData(dayIndex))

            return {
                heatmap_matrix: heatmapMatrix,
                minHour,
                maxHour,
            }
        }

        const adjustHeatmapForTimeframe = (heatmapData, timeframe) => {
            const { heatmap_matrix, minHour, maxHour } = heatmapData
            const currentDate = new Date()
            const currentDay = currentDate.getDay() - 1 // 0 = Monday, 1 = Tuesday, ..., 6 = Sunday
            const today = currentDate.getDate()
            const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate()

            switch (timeframe) {
                case 7: // Past 7 days
                    return heatmapData // No adjustment needed

                case 30: // Past 30 days
                    return {
                        ...heatmapData,
                        heatmap_matrix: heatmap_matrix.map(row => row.map(val => val * 4)),
                    }

                case 365: // Past 365 days
                    return {
                        ...heatmapData,
                        heatmap_matrix: heatmap_matrix.map(row => row.map(val => val * 50)),
                    }

                case 1000: // This week
                    return {
                        ...heatmapData,
                        heatmap_matrix: heatmap_matrix.map((row, dayIndex) =>
                            dayIndex < currentDay ? row : row.map(() => 0)
                        ),
                    }

                case 2000: // This month
                    if (today <= 7) {
                        // If the month is less than a week old, set rows for non-existent weekdays to zero
                        return {
                            ...heatmapData,
                            heatmap_matrix: heatmap_matrix.map((row, dayIndex) =>
                                today > dayIndex ? row : row.map(() => 0)
                            ),
                        }
                    } else {
                        // Count occurrences of each weekday in this month
                        const weekdayCounts = Array(7).fill(0);
                        for (let day = 1; day <= today; day++) {
                            const weekday = new Date(currentDate.getFullYear(), currentDate.getMonth(), day).getDay();
                            weekdayCounts[weekday]++;
                        }
                        // Scale rows based on weekday occurrences
                        return {
                            ...heatmapData,
                            heatmap_matrix: heatmap_matrix.map((row, dayIndex) =>
                                row.map(val => val * (weekdayCounts[dayIndex] || 0))
                            ),
                        }
                    }

                case 1: // Yesterday
                    const yesterdayIndex = (currentDay - 1 + 7) % 7;
                    return {
                        ...heatmapData,
                        heatmap_matrix: heatmap_matrix.map((row, dayIndex) =>
                            dayIndex === yesterdayIndex ? row : row.map(() => 0)
                        ),
                    }

                default:
                    return heatmapData // Default case, no adjustment
            }
        }

        const generateDateValues = (minValue, maxValue) => {
            const today = new Date() // Current date
            const startDate = new Date(today) // Clone today's date
            startDate.setDate(today.getDate() - 366) // Set to 366 days back from today (365 from yesterday)
            const endDate = new Date(today) // Clone today's date
            endDate.setDate(today.getDate() - 1) // Set to yesterday
            
            const legalHolidays = [
                '2024-01-01', // New Year's Day
                '2024-04-01', // Easter Monday
                '2024-05-01', // Labour Day
                '2024-05-09', // Ascension Day
                '2024-05-20', // Whit Monday
                '2024-07-21', // Belgian National Day
                '2024-08-15', // Assumption Day
                '2024-11-01', // All Saints' Day
                '2024-11-11', // Armistice Day
                '2024-12-25', // Christmas Day
                '2024-12-26', // Boxing Day
                '2025-01-01', // New Year's Day
            ];

            const result = {}

            // Function to check if a date is a legal holiday
            const isLegalHoliday = (date) => {
                const dateStr = date.toISOString().split('T')[0] // Format date as YYYY-MM-DD
                return legalHolidays.includes(dateStr)
            }

            // Loop from startDate to endDate (ascending order)
            for (
                let date = new Date(startDate);
                date <= endDate;
                date.setDate(date.getDate() + 1) // Move to the next day
            ) {
                const dayOfWeek = date.getDay(); // 0 = Sunday
                const isSunday = dayOfWeek === 0

                // Generate random value between 250 and 400 if not Sunday or legal holiday
                const value = isSunday || isLegalHoliday(date) ? 0 : Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue

                // Add to result with the date as the key
                result[date.toISOString()] = value
            }

            return result // Object with dates as keys
        }

        const adjustChartDataForTimeframe = (data, timeframe) => {
            const today = new Date()
            const yesterday = new Date(today)
            yesterday.setDate(today.getDate() - 1) // Set to yesterday

            const result = {}

            switch (timeframe) {
                case 1: {
                    // Only keep yesterday's value
                    const yesterdayStr = yesterday.toISOString();
                    Object.keys(data).forEach((key) => {
                        if (key.startsWith(yesterdayStr.split('T')[0])) {
                            result[key] = data[key]
                        }
                    })
                    break
                }
                case 7: {
                    // Filter for the last 7 days (including yesterday)
                    const startDate = new Date(yesterday);
                    startDate.setDate(yesterday.getDate() - 7) // Go back 6 days (7 total including yesterday)

                    Object.keys(data).forEach((key) => {
                        const date = new Date(key)
                        if (date >= startDate && date <= yesterday) {
                            result[key] = data[key]
                        }
                    })
                    break
                }
                case 30: {
                    // Filter for the last 30 days (including yesterday)
                    const startDate = new Date(yesterday)
                    startDate.setDate(yesterday.getDate() - 29) // Go back 29 days (30 total including yesterday)

                    Object.keys(data).forEach((key) => {
                        const date = new Date(key)
                        if (date >= startDate && date <= yesterday) {
                            result[key] = data[key]
                        }
                    })
                    break
                }
                case 365: {
                    // No filter, use the base object as-is
                    return data
                }
                case 1000: {
                    // Filter for this week (starting from Monday)
                    const dayOfWeekYesterday = yesterday.getDay() // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
                    const daysSinceMonday = (dayOfWeekYesterday + 7) % 7 // Calculate how many days to go back to Monday
                    const startDate = new Date(yesterday)
                    startDate.setDate(yesterday.getDate() - daysSinceMonday)

                    Object.keys(data).forEach((key) => {
                        const date = new Date(key)
                        if (date >= startDate && date <= yesterday) {
                            result[key] = data[key]
                        }
                    })
                    break
                }
                case 2000: {
                    // Filter for this month (starting from the 1st of the month)
                    const startDate = new Date(yesterday.getFullYear(), yesterday.getMonth(), 1) // 1st day of the month

                    Object.keys(data).forEach((key) => {
                        const date = new Date(key)
                        if (date >= startDate && date <= yesterday) {
                            result[key] = data[key]
                        }
                    })
                    break
                }
                default:
                    throw new Error("Invalid timeframe selected")
            }

            return result
        }

        const generateStructuredData = () => {
            // Generate date values for a year (365 days from yesterday)
            const generateZoneData = (zoneName, zoneType, minValue, maxValue) => {
                const dateValues = generateDateValues(minValue, maxValue) // Use the existing function
                const zoneData = {}

                Object.entries(dateValues).forEach(([date, value]) => {
                    zoneData[date] = {
                        [zoneName]: {
                            average_duration: value, // Random value within the range
                            zone_in_count: Math.floor(value / 10), // Derived value for demonstration
                            zone_type: zoneType, // Fixed zone type
                        },
                    }
                })

                return zoneData
            }

            // Generate count data for best demo, display, and product counts with a consistent zone name and specific zone_type
            const generateCountData = (zoneNames, minValue, maxValue, zoneType) => {
                const dateValues = generateDateValues(minValue, maxValue) // Use the existing function
                const countData = {}

                Object.entries(dateValues).forEach(([date, value]) => {
                    countData[date] = {
                        [zoneNames]: {
                            average_duration: value, // Random value within the range
                            zone_in_count: value, // Random value within the range
                            zone_type: zoneType, // Set the specific zone type
                        }
                    }
                })

                return countData
            }

            // Generate data for each zone type
            const demoData = generateZoneData('Demo Gereedschapswagen', 'Demo Zone', 50, 100)
            const displayData = generateZoneData('Promo Hoge Druk Reiniger', 'Display Zone', 50, 100)
            const productData = generateZoneData('Rek Veiligheiddschoenen', 'Product Zone', 50, 100)

            // Generate count data with consistent zone names and appropriate zone types
            const demoCountData = generateCountData('Demo Robotmaaier', 50, 100, 'Demo Zone')
            const displayCountData = generateCountData('Promo Houtbewerkingsmachines', 50, 100, 'Display Zone')
            const productCountData = generateCountData('Dewalt zone', 50, 100, 'Product Zone')

            // Combine all the generated data into the required structure
            return {
                pdd_chart_data: {
                    bestDemoAvgDur: demoData,
                    bestDisplayAvgDur: displayData,
                    bestProductAvgDur: productData,
                    bestDemoCount: demoCountData,
                    bestDisplayCount: displayCountData,
                    bestProductCount: productCountData,
                },
            }
        }
        

        onMounted(async () => {
            window.addEventListener('resize', updateWindowWidth)

            const entranceChartResult = generateDateValues(250, 400)
            entranceChartData.value = adjustChartDataForTimeframe(entranceChartResult, 30)

            const dataHeatmp = generateHeatmapData()
            heatmapData.value = adjustHeatmapForTimeframe(dataHeatmp, 30)

            pddChartData.value = generateStructuredData()
            bestProductAvgDur.value = adjustChartDataForTimeframe(pddChartData.value.pdd_chart_data.bestProductAvgDur, 30)
            bestProductCount.value = adjustChartDataForTimeframe(pddChartData.value.pdd_chart_data.bestProductCount, 30)
            bestDemoAvgDur.value = adjustChartDataForTimeframe(pddChartData.value.pdd_chart_data.bestDemoAvgDur, 30)
            bestDemoCount.value = adjustChartDataForTimeframe(pddChartData.value.pdd_chart_data.bestDemoCount, 30)
            bestDisplayAvgDur.value = adjustChartDataForTimeframe(pddChartData.value.pdd_chart_data.bestDisplayAvgDur, 30)
            bestDisplayCount.value = adjustChartDataForTimeframe(pddChartData.value.pdd_chart_data.bestDisplayCount, 30)

            const oneDayGeneratedData = generateRandomData()
            fixedWidgetsData.value = adjustDataForTimeframe(oneDayGeneratedData, 30)

            isLoading.value = false
        })

        const updateWindowWidth = () => {
            windowWidth.value = window.innerWidth;
        }

        const pause = (milliseconds) => {
            return new Promise((resolve) => setTimeout(resolve, milliseconds))
        }


        const handleUpdateDuration = async (newDuration) => {
            // Separate loading states for each widget
            isLoading.value = true

            selectedDuration.value  = newDuration
            emit('selectedDuration', newDuration)

            bestProductAvgDur.value = adjustChartDataForTimeframe(pddChartData.value.pdd_chart_data.bestProductAvgDur, selectedDuration.value)
            bestProductCount.value = adjustChartDataForTimeframe(pddChartData.value.pdd_chart_data.bestProductCount, selectedDuration.value)
            bestDemoAvgDur.value = adjustChartDataForTimeframe(pddChartData.value.pdd_chart_data.bestDemoAvgDur, selectedDuration.value)
            bestDemoCount.value = adjustChartDataForTimeframe(pddChartData.value.pdd_chart_data.bestDemoCount, selectedDuration.value)
            bestDisplayAvgDur.value = adjustChartDataForTimeframe(pddChartData.value.pdd_chart_data.bestDisplayAvgDur, selectedDuration.value)
            bestDisplayCount.value = adjustChartDataForTimeframe(pddChartData.value.pdd_chart_data.bestDisplayCount, selectedDuration.value)

            await pause(1000)
            
            isLoading.value = false

            const dataHeatmap = generateHeatmapData ()
            heatmapData.value = adjustHeatmapForTimeframe(dataHeatmap, selectedDuration.value)

            const entranceResult = generateDateValues(250, 400)
            entranceChartData.value = adjustChartDataForTimeframe (entranceResult, selectedDuration.value)

            const yesterdayData = fixedWidgetsData.value
            fixedWidgetsData.value = adjustDataForTimeframe(yesterdayData, selectedDuration.value)
            fixedWidgetsData.value.average_visitors = calculateAverageVisitors(entranceChartData.value)
            fixedWidgetsData.value.average_same_weekday = calculateAverageSameWeekday(entranceChartData.value)
            fixedWidgetsData.value.trend = calculateTrendWithClassification(entranceChartData.value)
            fixedWidgetsData.value.highest_total_hour = calculateHighestTotalHour(heatmapData.value).highest_total_hour
            fixedWidgetsData.value.highest_total_hour_count = calculateHighestTotalHour(heatmapData.value).highest_total_hour_count
            fixedWidgetsData.value.highest_total_day = calculateHighestTotalDay(entranceChartData.value).highest_total_day
            fixedWidgetsData.value.highest_total_day_count = calculateHighestTotalDay(entranceChartData.value).highest_total_day_count
            fixedWidgetsData.value.average_on_busiest_hour = calculateBusiestHour(heatmapData.value).average_on_busiest_hour
            fixedWidgetsData.value.busiest_hour = calculateBusiestHour(heatmapData.value).busiest_hour
        }

        onBeforeUnmount(() => {
            window.removeEventListener('resize', updateWindowWidth);
        })

        return {
            fixedWidgetsData,
            selectedDuration,
            dateRangeMode,
            handleUpdateDuration,
            heatmapData,
            entranceChartData,
            computedHeight,
            currentWeekdayString,
            bestProductAvgDur,
            bestProductCount,
            bestDemoAvgDur,
            bestDemoCount,
            bestDisplayAvgDur,
            bestDisplayCount,
            isLoading,
            formattedHighestTotalHour,
            formattedHighestTotalDay,
            formattedBusiestHour,
            formattedDurationTime,
            formattedAverageDurationTime,
        }
    }
}

</script>

<style scoped>
.card-main-header {
    display: flex;
    align-items: center; /* Align elements vertically in the center */
    gap: 25px; /* Add space between the title and dropdown */
}

.card-main-title {
    margin: 0; /* Ensure no extra margin */
    font-size: 1.25rem;
}

.grid-container-store-visitor-data {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive layout */
    gap: 20px;
    padding:20px;
}

.card-container-heatmap-row {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns: Heatmap (2 parts) and New Item (1 part) */
    gap: 20px; /* Space between columns */
    padding: 20px;
}

.card-container-pdd-row {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns: Heatmap (2 parts) and New Item (1 part) */
    gap: 100px; /* Space between columns */
    padding: 20px;
}

.heatmap-container {
    height: 100%; /* Ensure the heatmap fills its container */
    width: 100%; /* Full width */
}

.entrance-chart-container {
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    display: flex; /* Flexbox ensures proper scaling */
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Prevent overflow issues */
}

.card-container-first-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns for each block */
    gap: 20px;
}

.card-container-second-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns for each block */
    gap: 20px;
    margin-top: 20px;
}

.counter-card {
    width: 290px;
    height: 140px;
    box-sizing: border-box;
}

.counter-card-large {
    width: 340px;
    height: 140px;
    box-sizing: border-box;
}

.counter-card-small {
    width: 100%; /* Full width within each block */
    height: 105px;
    box-sizing: border-box;
}

.counter-card-header {
    padding: 2px;
    text-align: center;
}

.counter-card-title {
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: small;
    color: #000000;
    white-space: nowrap; /* Prevent text from wrapping */
    font-size: clamp(11px, 0.6vw, 14px) !important; /* Dynamically adjust font size */
}

.count {
    display: flex;
    justify-content: center;
    height: 30px;
    padding: 10px; /* Add padding to reduce gap */
}

.current-counter {
    font-size: clamp(48px, 2vw, 52px) !important; /* Dynamically adjust font size */
}

.count p {
    white-space: nowrap; /* Prevent text from wrapping */
    font-size: clamp(14px, 0.8vw, 18px) !important; /* Dynamically adjust font size */
}

.count span{
    white-space: nowrap; /* Prevent text from wrapping */
    font-size: clamp(10px, 0.8vw, 12px) !important; /* Dynamically adjust font size */
}

.grid-container-entrance-data {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    gap: 10px; /* Space between the grid items */
}

.grid-container-product-data {
    display: grid;
    grid-template-columns: 1fr 5fr;
    gap: 10px; /* Space between the grid items */
}

.product-zone-block {
    width: 100%;
    margin-top: 20px;
}

.card-stack {
    display: flex;
    flex-direction: column; /* Stack cards vertically */
    gap: 10px;
    margin-right: 20px;
}

.full-width-chart {
    height: 100%;
}

.chart-container {
    height: 100%;
    position: relative; /* Ensure the loader-overlay is scoped to this container */
}

.card-disabled {
    opacity: 0.5;
    pointer-events: none; /* Optional: Disable interactions */
    background-color: #ffffff; /* Light gray background */
}

.group-disabled {
    border: 2px dashed #dcdcdc; /* Dashed border to indicate unavailability */
    background-color: #ffffff;
    opacity: 0.7;
}

.loader-overlay {
    position: absolute; /* Cover the entire parent container */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; /* Vertically center the loader */
    justify-content: center; /* Horizontally center the loader */
    background-color: rgba(255, 255, 255, 0.8); /* Optional: semi-transparent background */
    z-index: 1000; /* Ensure the loader appears above other elements */
}

.loader {
  width: 20px;
  margin-top: 20px;
  padding: 2px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

/* Responsive wrapping for screen width below 1500px */
@media (max-width: 1500px) {
    .card-container-first-row,
    .card-container-second-row {
        grid-template-columns: 1fr; /* Wrap blocks on smaller screens */
    }
    .grid-container-entrance-data{
        grid-template-columns: repeat(2, 1fr); /* Wrap counter-cards within blocks */
    }
    .grid-container-product-data {
        grid-template-columns: 1fr; /* Wrap counter-cards within blocks */
    }

    .card-container-heatmap-row {
        display: grid; /* Ensure grid layout */
        grid-template-columns: 1fr; /* Single column layout */
        grid-template-rows: calc(var(--computed-height, 300px)) calc(var(--computed-height, 300px)); /* Divide height equally */
        gap: 20px; /* Small gap between items */
        height: calc(2 * var(--computed-height, 300px) + 20px); /* Total height accounting for gap */
    }

    .card-container-pdd-row {
        display: grid; /* Ensure grid layout */
        grid-template-columns: 1fr; /* Single column layout */
        grid-template-rows: calc(var(--computed-height, 300px)) calc(var(--computed-height, 300px)); /* Divide height equally */
        gap: 20px; /* Small gap between items */
        height: calc(2 * var(--computed-height, 300px) + 20px); /* Total height accounting for gap */
    }

    .heatmap-container
    .entrance-chart-container {
        width: 100%; /* Ensure full width */
        height: auto; /* Adjust height automatically */
    }
    .card-stack {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two cards per row in the stack */
        gap: 10px; /* Space between cards */
        margin-right: 0; /* Reset right margin for the stacked layout */
    }
    .counter-card-small {
        width: 100%; /* Make sure each card takes full available width within grid */
    }
}

/* Make charts in the product zone block wrap fully below 1200px */
@media (max-width: 1400px) {
    .chart-container {
        flex-direction: column; /* Stack charts vertically */
        gap: 1rem;
    }
    .chart-wrapper {
        flex: 1 1 100%; /* Make charts take full width */
        max-width: 100%;
    }
}

/* Further wrap counter cards within blocks for even smaller screens */
@media (max-width: 600px) {
    .grid-container-entrance-data,
    .grid-container-product-data {
        grid-template-columns: 1fr; /* Single column layout for counter-cards */
    }

    .card-stack {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px; /* Space between cards */
        margin-right: 0; /* Reset right margin for the stacked layout */
    }
}

@media (max-width: 850px) {
    .product-zone-block {
        grid-template-columns: 1fr; /* Stack Product Zone Intelligence and Charts vertically */
    }
}

/* Media query for screens less than 950px */
@media (max-width: 120px) {
    .card-container {
        justify-content: center;
    }
}

</style>