<template>
    <div class="card" style="margin-top: 10px; margin-bottom: 10px;">
        <div class="card-header">
            <h5 class="card-title" style="color: #B2C149">Today's Real-Time Data</h5>
        </div>

        <div class="card-body">
            <div class="card-container">
                <!-- Left Section: Stacked Cards -->
                <div class="left-section">
                    <div v-if="isEntranceZonePresent() || isCombinedEntranceExitZonePresent()"  class="card counter-card-extra" :class="{ 'card-disabled': !isEntranceZonePresent() && !isCombinedEntranceExitZonePresent() }">
                        <div class="card-header">
                            <h6 class="card-title" :style="{ color: !isEntranceZonePresent() && !isCombinedEntranceExitZonePresent() ? 'gray' : '#36910d' }">
                                Real-Time Visitor Count
                            </h6>
                        </div>
                        <div class="card-body">
                            <div v-if="isLoadingTodayCount" class="loader"></div>
                            <div v-else-if="!isEntranceZonePresent() && !isCombinedEntranceExitZonePresent()">
                                <p style="color: gray; text-align: center;">No Entrance Zones Defined</p>
                            </div>
                            <div v-else class="text-container centered-text">
                                <p class="current-counter"><b>{{ totalTodaysCount }}</b></p>
                            </div>
                        </div>
                    </div>

                    <div v-if="isEntranceZonePresent() || isCombinedEntranceExitZonePresent()"  class="card" :class="{ 'card-disabled': !isEntranceZonePresent() && !isCombinedEntranceExitZonePresent() }">
                        <div class="card-body">
                            <div v-if="isLoadingTodayCount" class="loader"></div>
                            <div v-else-if="!isEntranceZonePresent() && !isCombinedEntranceExitZonePresent()">
                                <p style="color: gray; text-align: center;">No Entrance Zones Defined</p>
                            </div>
                            <div v-else class="text-container centered-text gap-style">
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p :style="{ color: getDifferenceColor(totalTodaysCount - pastAverages['past_averages']['yesterday']) }">
                                        {{ formatDifference(totalTodaysCount - pastAverages['past_averages']['yesterday']) }}
                                    </p>
                                    <span style="font-size: x-small; color: #425461;">vs<br><b>Yesterday</b> Visitors</span>
                                </div>
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p :style="{ color: getDifferenceColor(totalTodaysCount - pastAverages['past_averages']['past_7_days']) }">
                                        {{ formatDifference(totalTodaysCount - pastAverages['past_averages']['past_7_days']) }}
                                    </p>
                                    <span style="font-size: x-small; color: #425461;">vs<br>Past <b>7</b> days Average</span>
                                </div>
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p :style="{ color: getDifferenceColor(totalTodaysCount - pastAverages['past_averages']['past_30_days']) }">
                                        {{ formatDifference(totalTodaysCount - pastAverages['past_averages']['past_30_days']) }}
                                    </p>
                                    <span style="font-size: x-small; color: #425461;">vs<br>Past <b>30</b> days Average</span>
                                </div>
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p :style="{ color: getDifferenceColor(totalTodaysCount - pastAverages['past_averages']['this_week']) }">
                                        {{ formatDifference(totalTodaysCount - pastAverages['past_averages']['this_week']) }}
                                    </p>
                                    <span style="font-size: x-small; color: #425461;">vs<br>This <b>week</b> Average</span>
                                </div>
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p :style="{ color: getDifferenceColor(totalTodaysCount - pastAverages['past_averages']['this_month']) }">
                                        {{ formatDifference(totalTodaysCount - pastAverages['past_averages']['this_month']) }}
                                    </p>
                                    <span style="font-size: x-small; color: #425461;">vs<br>This <b>month</b> Average</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="isEntranceZonePresent() || isCombinedEntranceExitZonePresent()"  class="card" :class="{ 'card-disabled': !isEntranceZonePresent() && !isCombinedEntranceExitZonePresent() }">
                        <div class="card-body">
                            <div v-if="isLoadingTodayCount" class="loader"></div>
                            <div v-else-if="!isEntranceZonePresent() && !isCombinedEntranceExitZonePresent()">
                                <p style="color: gray; text-align: center;">No Entrance Zones Defined</p>
                            </div>
                            <div v-else class="text-container centered-text gap-style">
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p :style="{ color: getDifferenceColor(totalTodaysCount - pastAverages['past_averages_same_weekday']['past_30_days']) }">
                                        {{ formatDifference(totalTodaysCount - pastAverages['past_averages_same_weekday']['past_30_days']) }}
                                    </p>
                                    <span style="font-size: x-small; color: #425461;">vs<br>Past <b>30</b> days {{ currentWeekdayString }}'s Average</span>
                                </div>
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p :style="{ color: getDifferenceColor(totalTodaysCount - pastAverages['past_averages_same_weekday']['past_365_days']) }">
                                        {{ formatDifference(totalTodaysCount - pastAverages['past_averages_same_weekday']['past_365_days']) }}
                                    </p>
                                    <span style="font-size: x-small; color: #425461;">vs<br>Past <b>365</b> days {{ currentWeekdayString }}'s Average</span>
                                </div>
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p :style="{ color: getDifferenceColor(totalTodaysCount - pastAverages['past_averages_same_weekday']['this_month']) }">
                                        {{ formatDifference(totalTodaysCount - pastAverages['past_averages_same_weekday']['this_month']) }}
                                    </p>
                                    <span style="font-size: x-small; color: #425461;">vs<br>This <b>month</b> {{ currentWeekdayString }}'s Average</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="!isCardEmpty(totalTodaysExit)" class="card counter-card-extra" :class="{ 'card-disabled': isCardEmpty(totalTodaysExit) }">
                        <div class="card-header">
                            <h6 class="card-title" :style="{ color: !isEntranceZonePresent() && !isCombinedEntranceExitZonePresent() ? 'gray' : '#B2C149' }">
                                Current Occupancy
                            </h6>
                        </div>
                        <div class="card-body count">
                            <div v-if="isLoadingTodayCount" class="loader"></div>
                            <div v-else-if="!isExitZonePresent() && !isCombinedEntranceExitZonePresent()">
                                <p style="color: gray; text-align: center;">No Exit Zones Defined</p>
                            </div>
                            <div v-else-if="isCardEmpty(totalTodaysExit)">
                                <p style="color: gray; text-align: center;">No data available</p>
                            </div>
                            <div v-else>
                                <p class="current-counter"><b>{{ todaysCurrentCount - totalTodaysExit }}</b></p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Right Section: Table -->
                <div class="right-section">
                    <div class="card-span-2 table-wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>Zone</th>
                                    <th>Current in Zone</th>
                                    <th>Today's Count</th>
                                    <th>Average Duration</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(zoneData, zoneName) in processedZones" :key="zoneName">
                                    <td :style="getZoneStyle(zoneData.flowId, zoneData.zoneType).style" :title="zoneData.zoneType">
                                        <b>{{ zoneName }}</b>
                                    </td>
                                    <td :style="getZoneStyleCurrentIn(zoneData.flowId, zoneData.zoneType)">
                                        <b>{{ flowZoneCounters[zoneData.flowId]?.[zoneName] || 0 }}</b>
                                    </td>
                                    <td :style="getZoneStyle(zoneData.flowId, zoneData.zoneType).style">
                                        {{ zoneData.todaysCount }}
                                    </td>
                                    <td :style="getZoneStyle(zoneData.flowId, zoneData.zoneType).style">
                                        {{ formattedDurationTime(zoneData.averageDuration) }}
                                    </td>
                                    <td v-if="getZoneStyle(zoneData.flowId, zoneData.zoneType).isStreamActive"
                                        :style="getZoneStyle(zoneData.flowId, zoneData.zoneType).style"
                                        class="icon-cell">
                                        <span v-if="cameraHeartBeat[zoneData.flowId]" class="ok">
                                            <i class="bi bi-check"></i>
                                        </span>
                                        <span v-else class="nok">
                                            <i class="bi bi-exclamation-triangle"></i>
                                        </span>
                                    </td>
                                    <td v-else class="icon-cell">
                                        <!-- Render an empty cell when the stream is not active -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onBeforeMount, onMounted, onBeforeUnmount, ref, computed, watch } from 'vue'

import io from 'socket.io-client'

import { useCameraStreamStore } from '@/stores/CameraStreamStore'
import { useUserStore } from '@/stores/UserStore'
import { useCompanyStore } from '@/stores/CompanyStore'

import { detection_zone_colors_by_name_solid } from '@/constants.js'
import { getCurrentCounters, getPastAverages } from "@/api/eventsAPI.js"

export default {
    name: 'CurrentLiveData',

    setup(props, { emit }) {
        const CameraStreamStore = useCameraStreamStore()
        const userStore = useUserStore()
        const companyStore = useCompanyStore()

        const cameraHeartBeat = ref({})
        const heartbeatTimeouts = ref({})

        const totalTodaysCount = ref(0)
        const totalTodaysExit = ref(0)
        const pastAverages = ref({})
        const currentWeekday = ref(null)
        const isLoadingTodayCount = ref(true)
        const isLoadingTodayCountTable = ref(true)
        const processedZones = ref({})
        const flowZoneCounters = ref({})
        const streamToFlowMap = ref({})
        const streamStatuses = ref({})

        const fetchInterval = ref(null)
        const isMounted = ref(true)
        let currentFetchController = null
        
        const socket = ref(null)
        const isConnectingSocket = ref(false)
        const socketConnected = ref(true)

        const socketioURL = computed(() => {
            return {
                URLPublic: companyStore.company.SocketioURLPublic
            }
        })

        const formattedDurationTime = computed(() => {
            return (seconds) => {
                if (typeof seconds !== 'number' || isNaN(seconds)) {
                    return 'N/A'; // Handle invalid input
                }

                if (seconds < 60) {
                    return `${seconds.toFixed(0)} sec`
                } else if (seconds < 3600) {
                    const minutes = Math.floor(seconds / 60)
                    const remainingSeconds = seconds % 60
                    return `${minutes} min ${remainingSeconds.toFixed(0)} sec`
                } else {
                    const hours = Math.floor(seconds / 3600)
                    const remainingMinutes = Math.floor((seconds % 3600) / 60)
                    const remainingSeconds = seconds % 60
                    return `${hours} h ${remainingMinutes} min ${remainingSeconds.toFixed(0)} sec`
                }
            }
        })

        const currentWeekdayString = computed(() => {
            if (currentWeekday.value >= 0 && currentWeekday.value <= 6) {
                const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
                return daysOfWeek[currentWeekday.value]
            } else {
                return 'Same Weekday'
            }
        })

        const stopInterval = () => {
            if (fetchInterval.value) {
                clearInterval(fetchInterval.value)
                fetchInterval.value = null
            }
        }

        const startInterval = () => {
            stopInterval() // Ensure any existing interval is cleared
            fetchInterval.value = setInterval(async () => {
                if (!isMounted.value) return // Exit if the component is not mounted
                await refreshData()
            }, 5000) // Refresh every 5 seconds
        }

        const refreshData = async () => {
            if (currentFetchController) {
                currentFetchController.abort() // Abort any ongoing fetch request
            }
            const controller = new AbortController()
            currentFetchController = controller
            try {
                const current_counters_data = await getCurrentCounters(0, { signal: controller.signal })
                if (isMounted.value) {
                    processedZones.value = processZonesData(current_counters_data)
                }
            } catch (error) {
                if (error.name === 'AbortError') {
                    console.log('Fetch aborted')
                } else {
                    console.error('Error fetching counters:', error)
                }
            }
        }

        const fetchStreamStatuses = async () => {
            const flowIds = Object.values(processedZones.value)
                .map(zone => zone.flowId) // Extract the flowId for each zone
                .filter((flowId, index, self) => self.indexOf(flowId) === index)
            
            for (const flowId of flowIds) {
                await CameraStreamStore.fetchInputCameraStreambyFlowId(flowId)
                const cameraStream = CameraStreamStore.inputcamerastream

                // Store the StreamStatus
                if (cameraStream && cameraStream.FlowId) {
                    streamStatuses.value[flowId] = cameraStream.StreamStatus
                }
            }
        }

        const getZoneStyle = (flowId, zoneType) => {
            const isStreamActive = streamStatuses.value[flowId] ?? false; // Default to false
            return {
                isStreamActive, // Include the stream activity status
                style: {
                    color: isStreamActive ? '#425461' : '#D3D3D3',
                    padding: '8px',
                    textAlign: 'left',
                    fontSize: 'small',
                },
            }
        }

        const getZoneStyleCurrentIn = (flowId, zoneType) => {
            const isStreamActive = streamStatuses.value[flowId] ?? false; // Default to false
            return {
                color: isStreamActive ? getColorForZone(zoneType) : '#D3D3D3',
                padding: '8px',
                textAlign: 'left',
                fontSize: 'small',
            }
        }

        const formatDifference = (difference) => {
            const roundedValue = Math.round(difference)
            return roundedValue > 0 ? `+${roundedValue}` : `${roundedValue}`
        }

        const getDifferenceColor = (difference) => {
            return difference > 0 ? '#36910d' : '#C21500'
        }

        const isCardEmpty = (...values) => {
            // Check if all values in the card are null, 0, empty string, or empty object
            return values.every(
                (val) =>
                    val === null ||
                    val === "null" ||
                    val === 0 ||
                    val === "0" ||
                    val === "" ||
                    (typeof val === "object" && Object.keys(val).length === 0)
            )
        }

        const detectNetworkContext = async (publicURL) => {
            try {
                const controller = new AbortController()
                const signal = controller.signal
                const timeout = 5000 // 5 seconds timeout for checking public IP

                console.log('Trying public URL:', publicURL)
                const fetchPromise = fetch(publicURL, { signal })
                setTimeout(() => controller.abort(), timeout)

                const response = await fetchPromise
                if (response.ok) {
                    console.log('Public URL is accessible')
                    return publicURL
                }
            } catch (error) {
                console.error('Public Socket URL is not accessible')
                toast.error('Local device is not accessible.')
                throw new Error('Public Socket URL is not accessible')
            }
            throw new Error('Public Socket URL is not accessible')
        }

        const establishSocketConnection = async (publicURL) => {
            if (socket.value !== null) {
                console.log("Returning existing socket")
                return socket.value
            }

            if (isConnectingSocket.value) {
                console.log('Already connecting, waiting...')
                while (isConnectingSocket.value) {
                    await new Promise(r => setTimeout(r, 500))
                }
                return socket.value
            }

            console.log("Connecting socket ...")
            isConnectingSocket.value = true

            try {
                const url = await detectNetworkContext(publicURL)
                console.log('Network context detected, URL:', url)

                socket.value = io(url, {
                    transports: ['websocket'],
                    reconnection: true,
                    reconnectionAttempts: 5,
                    reconnectionDelay: 1000,
                    debug: true,
                    perMessageDeflate: false,
                    timeout: 120000,
                    maxPayload: 50 * 1024 * 1024,
                })

                setupSocketEvents(socket.value)

                return socket.value

            } catch (error) {
                console.error('Error establishing socket connection:', error)
                throw error
            } finally {
                console.log('Resetting serverConnection flag')
                isConnectingSocket.value = false
            }
        }

        const setupSocketEvents = (socketInstance) => {
            socketInstance.on('zone_counter', (data) => { 
                const streamId = data.stream_id
                const flowId = streamToFlowMap.value[streamId]

                if (flowId) {
                    flowZoneCounters.value = {
                        ...flowZoneCounters.value,
                        [flowId]: {
                            ...flowZoneCounters.value[flowId],
                            ...data.zone_counter,
                        },
                    }
                }
            })

            socketInstance.on('heartbeat', (data) => {
                const { streamId, heartbeat } = data

                // Ensure the flowId is mapped correctly to the streamId
                const flowId = streamToFlowMap.value[streamId]

                if (flowId) {
                    // Initialize or update the heartbeat value for the specific flowId
                    cameraHeartBeat.value[flowId] = heartbeat

                    // Clear any existing timeout for this flowId
                    if (heartbeatTimeouts.value[flowId]) {
                        clearTimeout(heartbeatTimeouts.value[flowId])
                    }

                    if (heartbeat) {
                        // Reset the heartbeat timeout for 5 seconds
                        heartbeatTimeouts.value[flowId] = setTimeout(() => {
                            cameraHeartBeat.value[flowId] = false
                        }, 5000)
                    }
                }
            })

            socketInstance.on('disconnection_message', (data) => {
                    console.log('message on disconnection: ', data)
                })

            socketInstance.on('error', (error) => {
            console.error('Error received from server:', error)     
            toast.error(`Error: ${error.message}`)
            })
        }

        
        const initializeSocketConnection = async () => {
            if (socketioURL.value.URLPublic) {
            try {
                socket.value = await establishSocketConnection(socketioURL.value.URLPublic)
                console.log('Socket connection Home established')
            } catch (error) {
                console.error('Failed to establish Home socket connection:', error)
                socketConnected.value = false
            }
            } else {
            console.error('socketioURL is not defined')
            socketConnected.value = false
            }
        }

        const initializeFlowData = () => {
            streamToFlowMap.value = CameraStreamStore.inputcamerastreams.reduce((acc, item) => {
                acc[item.CameraStreamId] = item.FlowId
                return acc
            }, {})
        }

        onBeforeMount(async () => {
            await companyStore.fetchCompanyDetails(userStore.user.CompanyId)
            await CameraStreamStore.fetchInputCameraStreams()
            await initializeSocketConnection()
            initializeFlowData()
        })

        onMounted(async () => {
            isMounted.value = true

            const current_counters_data = await getCurrentCounters(0)
            processedZones.value = processZonesData(current_counters_data)

            pastAverages.value = await getPastAverages()
            currentWeekday.value = pastAverages.value['current_weekday']

            isLoadingTodayCount.value = false

            // Initialize flowZoneCounters with default zero values
            for (const flowId in current_counters_data.current_counters_data) {
                const flowData = current_counters_data.current_counters_data[flowId]
                const counters = flowData.counters || {} // Get counters for this flowId

                // Ensure structure for the flowId
                if (!flowZoneCounters.value[flowId]) {
                    flowZoneCounters.value[flowId] = {}
                }

                // Initialize detection zones for the flowId
                for (const zoneName in counters) {
                    if (!flowZoneCounters.value[flowId][zoneName]) {
                        flowZoneCounters.value[flowId][zoneName] = 0 // Default value
                    }
                }
            }

            await fetchStreamStatuses(current_counters_data.current_counters_data)
            
            startInterval()
            
            isLoadingTodayCountTable.value = false
        })

        const processZonesData = (data) => {
            const zones = {}

            const flowData = data.current_counters_data

            if (!flowData) {
                console.warn("No 'current_counters_data' found")
                return zones
            }

            for (const flowId in flowData) {
                const flow = flowData[flowId]
                if (!flow.counters) continue

                for (const zoneName in flow.counters) {
                    const counter = flow.counters[zoneName]
                    const storeCounter =
                        flow.store_counters?.[zoneName]?.zone_in_count ?? null

                    // Use store counter if available, otherwise use normal counter
                    zones[zoneName] = {
                        flowId,
                        todaysCount: storeCounter || counter.zone_in_count || 0,
                        averageDuration: counter.average_duration || 0,
                        zoneType: counter.zone_type || 'Unknown Zone',
                    }
                }
            }

            totalTodaysCount.value = Object.values(zones)
                .filter(zone => zone.zoneType === "Entrance Zone" || zone.zoneType === "Combined Entrance Exit Zone")
                .reduce((sum, zone) => sum + zone.todaysCount, 0)

            return zones
        }

        const getColorForZone = (zoneType) => {
            return detection_zone_colors_by_name_solid[zoneType] || 'black'
        }


        
        const isEntranceZonePresent = () => {
            return Object.values(processedZones.value).some(zone => zone.zoneType === "Entrance Zone")
        }

        const isCombinedEntranceExitZonePresent = () => {
            return Object.values(processedZones.value).some(zone => zone.zoneType === "Combined Entrance Exit Zone")
        }

        const isExitZonePresent = () => {
            return false
        }

        onBeforeUnmount(() => {
            // Clear the interval when the component unmounts
            isMounted.value = false
            stopInterval()
            if (currentFetchController) {
                currentFetchController.abort()
            }
            if (socket.value) {
                socket.value.off('zone_counter')
                socket.value.off('error')
                socket.value.off('connect')
                socket.value.disconnect()
            }
        })

        return {
            isLoadingTodayCount,
            isLoadingTodayCountTable,
            cameraHeartBeat,
            formattedDurationTime,
            getColorForZone,
            formatDifference,
            getDifferenceColor,
            isCardEmpty,
            totalTodaysCount,
            totalTodaysExit,
            pastAverages,
            currentWeekdayString,
            processedZones,
            flowZoneCounters,
            getZoneStyle,
            getZoneStyleCurrentIn,
            isEntranceZonePresent,
            isCombinedEntranceExitZonePresent,
            isExitZonePresent,
        }
    }
}

</script>

<style scoped>
.counter-card {
    width: 290px;
    height: 140px;
    box-sizing: border-box;
}

.counter-card-large {
    width: 340px;
    height: 140px;
    box-sizing: border-box;
}

.counter-card-extra {
    width: 100%px;
    height: 180px;
    box-sizing: border-box;
}

.counter-card-small {
    width: 100%; /* Full width within each block */
    height: 105px;
    box-sizing: border-box;
}

.current-counter {
    font-size: clamp(48px, 2vw, 52px) !important; /* Dynamically adjust font size */
}

.centered-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensure it takes the full height of the card */
    text-align: center; /* Center text horizontally */
    color: #36910d; /* Matches the header text color */
}

.card-disabled {
    opacity: 0.5;
    pointer-events: none; /* Optional: Disable interactions */
    background-color: #ffffff; /* Light gray background */
}

.card-container {
    display: grid;
    grid-template-columns: 1fr 2fr; /* Left section 1/3, Right section 2/3 */
    gap: 20px;
    align-items: start; /* Align items to the top */
}

.gap-style {
    gap: 20px
}

.text-container.centered-text.gap-style {
  /* By default, they might be in a row, no wrap */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
  /* No wrap (if that’s desired above 850px) */
  flex-wrap: nowrap;
}



.left-section,
.right-section {
  min-width: 0; /* Allows them to shrink smaller than their contents */
  /* If content is long/wide, it may overflow. Let’s handle that next. */
}

.left-section {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between stacked cards */
}

.right-section {
    grid-column: 2 / 3; /* Ensures the table spans the right section */
}

.card-span-2 {
    grid-column: 2 / 3; /* Start at the second column and span to the end */
}

.table-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 0.375rem;
}

table {
    width: 100%; /* Ensure the table fills its container */
    border-collapse: collapse; /* Use 'separate' to allow border-radius to work */
    border-spacing: 0; /* Optional: Adjust spacing between cells */
    border-radius: 0.375rem; /* Adjust the radius to match the card's corners */
    overflow: hidden; /* Prevent content from overflowing the rounded corners */
    border: 1px solid rgba(0, 0, 0, 0.175); /* Optional: Adds a border around the table */
}

th, td {
    border: 1px solid rgba(0, 0, 0, 0.175); /* Ensures consistent borders */
    padding: 8px;
    text-align: left;
}

th {
    padding: 11px; /* Adds space around the content */
    text-align: center; /* Aligns text to the center */
    font-size: small; /* Adjusts font size */
    background-color: rgba(33, 37, 41, 0.03); /* Sets background color */
    color: #4F7EB3; /* Sets font color */
}

th:first-child {
    border-top-left-radius: 0.375rem; /* Rounded corner for the top-left */
}

th:last-child {
    border-top-right-radius: 0.375rem; /* Rounded corner for the top-right */
}

tr:last-child td:first-child {
    border-bottom-left-radius: 0.375rem; /* Rounded corner for the bottom-left */
}

tr:last-child td:last-child {
    border-bottom-right-radius: 0.375rem; /* Rounded corner for the bottom-right */
}

.icon-cell {
    text-align: center; /* Centers horizontally */
    vertical-align: middle; /* Centers vertically */
    padding: 8px; /* Adjust padding if needed */
}

.ok, .nok {
    display: inline-flex; /* Flexbox for proper alignment */
    align-items: center; /* Vertical alignment */
    justify-content: center; /* Horizontal alignment */
    height: 100%; /* Ensures full height for vertical centering */
    width: 100%; /* Ensures full width for alignment in the cell */
}

.ok .bi-check {
    color: green;
    font-size: 1.2em; /* Adjust icon size if needed */
}

.nok .bi-exclamation-triangle {
    color: #ffd32c;
    font-size: 1.2em; /* Adjust icon size if needed */
}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; /* Ensures it takes the full height of the parent */
}

.loader {
  width: 20px;
  margin-top: 20px;
  padding: 2px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

/* Responsive wrapping for screen width below 1500px */
@media (max-width: 1500px) {
    .card-container {
        grid-template-columns: 1fr; /* Cards and table stack vertically */
    }

    .left-section {
        grid-column: 1 / -1; /* Cards take full width */
    }

    .right-section {
        grid-column: 1 / -1; /* Table takes full width below cards */
    }

    .card {
        width: 100%; /* Ensure cards stretch to full width */
    }

    .gap-style {
        gap: 50px
    }
}

@media (max-width: 500px) {
  .text-container.centered-text.gap-style {
    /* Switch to wrapping below 850px */
    flex-wrap: wrap;
    justify-content: center; /* or 'flex-start' if you prefer */
  }

  /* Optionally, you can let each .text-container box go 100% width, or 50%, etc. */
  .text-container.centered-text.gap-style > .text-container {
    flex: 1 1 100%; 
    /* The line above will make each box take the full width. 
       If you want two per row, do `flex: 1 1 50%`. 
       If you want more precise control, pick an exact max-width, etc. */
    box-sizing: border-box;
  }

  .table-wrapper th,
  .table-wrapper td,
  .table-wrapper td * {
    font-size: 0.8rem !important;
  }
  
}

@media (max-width: 475px) {
  .table-wrapper th,
  .table-wrapper td,
  .table-wrapper td * {
    font-size: 0.7rem !important;
  }
  
}

@media (max-width: 440px) {
  .table-wrapper th,
  .table-wrapper td,
  .table-wrapper td * {
    font-size: 0.6rem !important;
  }
  
}

@media (max-width: 410px) {
  .table-wrapper th,
  .table-wrapper td,
  .table-wrapper td * {
    font-size: 0.5rem !important;
  }
  
}

@media (max-width: 375px) {
  .table-wrapper th,
  .table-wrapper td,
  .table-wrapper td * {
    font-size: 0.4rem !important;
  }
  
}
</style>