<template>
    <div class="container">
        <div class="container">
            <div v-if="!isLoading">

                <MDBTabs v-if="!isLoading" v-model="activeTab">
                    <hr style="margin: 15px 0px 15px 0px">
                    <!-- Tabs navs -->
                    <MDBTabNav tabsClasses="mb-3">
                        <MDBTabItem tabId="event-count" href="event-count">Event Count</MDBTabItem>
                        <MDBTabItem tabId="event-list-date-sorted" href="event-list-date-sorted">Event List - Date<i class="bi bi-arrow-down"></i></MDBTabItem>
                        <MDBTabItem tabId="event-list-duration-sorted" href="event-list-duration-sorted">Event List - Duration<i class="bi bi-arrow-down"></i> </MDBTabItem>
                    </MDBTabNav>
                    <!-- Tabs navs -->

                    <!-- Tabs content -->
                    <MDBTabContent>
                        <MDBTabPane tabId="event-count">

                            <MDBTabs v-model="activeZoneTab">

                                <!-- Detection Zone Tabs navs -->
                                <MDBTabNav tabsClasses="mb-3">
                                    <MDBTabItem v-for="zoneName in sortedZoneNames(tableZoneCountsData)" :key="zoneName" :tabId="normalizeZoneName(zoneName)" :href="normalizeZoneName(zoneName)">
                                        {{ zoneName }}
                                    </MDBTabItem>
                                </MDBTabNav>
                                <!-- Detection Zone Tabs navs -->
                            
                            
                                <!-- Detection Zone Tabs content -->
                                <MDBTabContent>
                                    <MDBTabPane v-for="zoneName in sortedZoneNames(tableZoneCountsData)" :key="zoneName" :tabId="normalizeZoneName(zoneName)">
                                
                                        <div class="table-container">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Zone In</th>
                                                        <th>Average Duration</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="tableZoneCountsData[zoneName].data.length > 0">
                                                    <tr v-for="(item, index) in tableZoneCountsData[zoneName].data" :key="index">
                                                        <td style="padding-left: 30px; font-size: small; color: #425461">
                                                            <b>{{ item.date }}</b>
                                                        </td>
                                                        <td style="text-align: center; font-size: small; color: #425461">
                                                            {{ item.zoneIn || '' }}
                                                        </td>
                                                        <td style="text-align: center; font-size: small; color: #425461">
                                                            {{ item.averageDuration !== '0 sec' ? item.averageDuration : '' }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <p v-else>No data to show</p>
                                            </table>
                                        </div>

                                    </MDBTabPane>
                                </MDBTabContent>

                            </MDBTabs>
                        </MDBTabPane>
                        
                        
                        <MDBTabPane tabId="event-list-date-sorted">
                            <MDBTabs v-model="activeZoneTab">

                                <!-- Detection Zone Tabs navs -->
                                <MDBTabNav tabsClasses="mb-3">
                                    <MDBTabItem v-for="zoneName in sortedZoneNames(EventListSortedByDate)" :key="zoneName" :tabId="normalizeZoneName(zoneName)" :href="normalizeZoneName(zoneName)">
                                        {{ zoneName }}
                                    </MDBTabItem>
                                </MDBTabNav>
                                <!-- Detection Zone Tabs navs -->

                                <!-- Detection Zone Tabs content -->
                                <MDBTabContent>
                                    <MDBTabPane v-for="zoneName in sortedZoneNames(EventListSortedByDate)" :key="zoneName" :tabId="normalizeZoneName(zoneName)">
                                        <div class="table-container">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>ID</th>
                                                        <th>Duration (sec)</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="EventListSortedByDate[zoneName].length > 0">
                                                    <tr v-for="(item, index) in EventListSortedByDate[zoneName]" :key="index">
                                                        <td style="padding-left: 30px; font-size: small; color: #425461"><b>{{ item.date }}</b></td>
                                                        <td style="text-align: center; font-size: small; color: #425461">{{ item.trackId }}</td>
                                                        <td style="text-align: center; font-size: small; color: #425461">{{ item.duration }}</td>
                                                    </tr>
                                                </tbody>
                                                <p v-else>No data to show</p>
                                            </table>
                                        </div>
                                    </MDBTabPane>
                                </MDBTabContent>
                            </MDBTabs>
                        </MDBTabPane>


                        <MDBTabPane tabId="event-list-duration-sorted">
                            <MDBTabs v-model="activeZoneTab">

                                <!-- Detection Zone Tabs navs -->
                                <MDBTabNav tabsClasses="mb-3">
                                    <MDBTabItem v-for="zoneName in sortedZoneNames(EventListSortedByDuration)" :key="zoneName" :tabId="normalizeZoneName(zoneName)" :href="normalizeZoneName(zoneName)">
                                        {{ zoneName }}
                                    </MDBTabItem>
                                </MDBTabNav>
                                <!-- Detection Zone Tabs navs -->

                                <!-- Detection Zone Tabs content -->
                                <MDBTabContent>
                                    <MDBTabPane v-for="zoneName in sortedZoneNames(EventListSortedByDuration)" :key="zoneName" :tabId="normalizeZoneName(zoneName)">
                                        <div class="table-container">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>ID</th>
                                                        <th>Duration (sec)</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="EventListSortedByDuration[zoneName].length > 0">
                                                    <tr v-for="(item, index) in EventListSortedByDuration[zoneName]" :key="index">
                                                        <td style="padding-left: 30px; font-size: small; color: #425461"><b>{{ item.date }}</b></td>
                                                        <td style="text-align: center; font-size: small; color: #425461">{{ item.trackId }}</td>
                                                        <td style="text-align: center; font-size: small; color: #425461">{{ item.duration }}</td>
                                                    </tr>
                                                </tbody>
                                                <p v-else>No data to show</p>
                                            </table>
                                        </div>
                                    </MDBTabPane>
                                </MDBTabContent>
                            </MDBTabs>
                        </MDBTabPane>


                    </MDBTabContent>

                </MDBTabs>

            </div>

            <div v-if="isLoading" class="loading-overlay">
                <div class="loader" style="margin-top: 20px;"></div>
            </div>

            
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch, toRefs } from 'vue'
import { MDBTabs, MDBTabNav, MDBTabContent, MDBTabItem, MDBTabPane } from 'mdb-vue-ui-kit'


import { getCountersListByDuration, getEventListByDuration, getcountersListByDateRange, getEventListByDateRange } from "@/api/eventsAPI.js"

export default {
    name: 'TableView',

    components: {
        MDBTabs,
        MDBTabNav,
        MDBTabContent,
        MDBTabItem,
        MDBTabPane
    },

    props: {
        flowId: Number,
        startDate: Object,
        endDate: Object,
        dataGranularity: String,
        duration: Number,
        dateRangeMode: String,
        minimumDurationThreshold: Number
    },

    setup(props, { emit }) {
        const activeTab = ref('event-count')
        const activeZoneTab = ref('')
        
        const tableZoneCountsData = ref([])
        const EventListSortedByDate = ref([])
        const EventListSortedByDuration = ref([])
        const zoneTypes = ref([])

        const isLoading = ref(false)
        const firstLoad = ref(true)

        const isMounted = ref(true)
        let currentFetchController = null
        let latestRequestId = 0

        const { flowId, duration, dataGranularity, startDate, endDate, minimumDurationThreshold } = toRefs(props)

        const localDateRangeMode = ref(props.dateRangeMode)

        const today = ref(new Date())


        const refreshData = () => {
            if (!isMounted.value) return

            if (localDateRangeMode.value === 'duration') {
                fetchEventDataByDuration()
            } else if (localDateRangeMode.value === 'dateRange') {
                fetchEventDataByDateRange()
            }
        }

        const debounce = (func, wait) => {
            let timeOut
            return (...args) => {
                clearTimeout(timeOut)
                timeOut = setTimeout(() => func.apply(this, args), wait)
            }
        }

        onMounted(() => {
            isMounted.value = true

            isLoading.value = true

            refreshData()  // Fetch data when component is mounted
        })

        onUnmounted(() => {
            isMounted.value = false

            if (currentFetchController) {
                currentFetchController.abort(); // Abort any ongoing fetch request
            }
        })

        watch(duration, debounce((newValue) => {
            if (newValue) {
                isLoading.value = true
                refreshData()
            }
        }, 300))

        watch(() => props.dateRangeMode, (newValue) => {
            localDateRangeMode.value = newValue
        })

        watch(localDateRangeMode, debounce((newValue) => {
            if (newValue) {
                isLoading.value = true
                refreshData()
            }
        }, 300))

        watch(() => props.minimumDurationThreshold, debounce((newValue) => {
            if (newValue || newValue === 0) { // Handle zero and other values
                isLoading.value = true
                refreshData()
            }
        }, 300))

        watch(() => dataGranularity.value, debounce((newValue) => {
            if (newValue) {
                isLoading.value = true
                refreshData()
            }
        }, 300))

        watch(([startDate, endDate]), debounce ((newValues, prevValues) => {
            if (newValues.every(value => value !== null)) { // Both dates are selected
                isLoading.value = true
                refreshData()
            }
        }, 300))

        // Watch for changes in activeTab
        watch(activeTab, (newValue) => {
            if (newValue === 'event-list-date-sorted' || newValue === 'event-list-duration-sorted') {
                emit('tabStatusChange', true) // Emit true for these tabs
            } else {
                emit('tabStatusChange', false) // Emit false for other tabs
            }
        })

        const fetchEventDataByDuration = async () => {
            if (currentFetchController) {
                currentFetchController.abort(); // Abort the previous request
            }

            const controller = new AbortController()
            currentFetchController = controller // Update the current controller
            const requestId = ++latestRequestId

            if (flowId.value) {
                try {
                    if (controller.signal.aborted || !isMounted.value) {
                        return  // Abort early if the signal is already aborted
                    }
                    
                    let result = await getCountersListByDuration(flowId.value, duration.value, dataGranularity.value, { signal: controller.signal })
                    if (isMounted.value && requestId === latestRequestId) {  // Check for the latest request) {
                        createCountsTable(result)
                        
                        let eventListByDurationResponse = await getEventListByDuration(flowId.value, duration.value, { signal: controller.signal })
                        processEventListDataSortedByDate(eventListByDurationResponse)
                        processEventListDataSortedByDuration(eventListByDurationResponse)
                        
                        isLoading.value = false
                    }
                } catch (error) {
                    if (error.name === 'AbortError') {
                        console.log('Fetch aborted')
                    } else {
                        console.error('Error fetching event data:', error)
                    }
                }
            }
        }

        const fetchEventDataByDateRange = async () => {
            if (currentFetchController) {
                currentFetchController.abort(); // Abort the previous request
            }

            const controller = new AbortController()
            currentFetchController = controller // Update the current controller
            const requestId = ++latestRequestId

            // Check if both dates are selected and flowId is available
            if (startDate.value && endDate.value && flowId.value) {
                try {
                    if (controller.signal.aborted || !isMounted.value) {
                        return  // Abort early if the signal is already aborted
                    }
                    
                    let result = await getcountersListByDateRange(flowId.value, startDate.value, endDate.value, dataGranularity.value, { signal: controller.signal })
                    
                    if (isMounted.value && requestId === latestRequestId) {  // Check for the latest request) {
                        createCountsTable(result)

                        let eventListByDateRangeResponse = await getEventListByDateRange(flowId.value, startDate.value, endDate.value, { signal: controller.signal })
                        processEventListDataSortedByDate(eventListByDateRangeResponse)
                        processEventListDataSortedByDuration(eventListByDateRangeResponse)
                        
                        
                        isLoading.value = false
                    }
                } catch (error) {
                    if (error.name === 'AbortError') {
                        console.log('Fetch aborted')
                    } else {
                        console.error('Error fetching event data:', error)
                    }
                }
            }
        }

        const createCountsTable = async (result) => {
            if (!isMounted.value) return

            const counters = result.counters_data.counters_list
            const storeCounters = result.counters_data.store_counters_list

            let groupedData = {}

            if (counters && typeof counters === 'object') {
                // Iterate through each date in the counts object
                Object.keys(counters).forEach(date => {
                    const eventData = counters[date]
                    
                    // Iterate through zones for each date
                    Object.keys(eventData).forEach(zoneName => {
                        const zoneData = eventData[zoneName]
                        const zoneType = zoneData.zone_type
                
                        // Initialize if not already present
                        if (!groupedData[zoneName]) {
                            groupedData[zoneName] = {
                                zoneType: zoneType,
                                data: []
                            }
                        }

                        // Determine zone_in value: Prefer store_counters if present
                        const zoneInCount = storeCounters[date]?.[zoneName]?.zone_in_count ?? zoneData.zone_in_count

                        // Add row data
                        groupedData[zoneName].data.push({
                            date: formatDate(date, dataGranularity.value),
                            zoneIn: zoneInCount,
                            averageDuration: formatDuration(Math.round(zoneData.average_duration)),
                        })
                    })
                })

                tableZoneCountsData.value = groupedData
            } else {
                console.error('Unexpected response, event_list is not an array:', counters);
            }
        }

        watch(() => tableZoneCountsData.value, (newValue) => {
            if (!isMounted.value) return

            const zoneNames = Object.keys(newValue)
            if (firstLoad.value && zoneNames.length > 0) {
                activeZoneTab.value = normalizeZoneName(zoneNames[0])
                firstLoad.value = false
            }
        })

        const processEventListDataSortedByDate = (eventList) => {
            if (!isMounted.value) return

            const groupedData = {}

            eventList.forEach(event => {
                const rawDate = new Date(event.Date);

                const formattedZoneInDate = rawDate.toLocaleString('en-GB', {
                    timeZone: 'Europe/Paris',
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    weekday: 'short'
                }).replace(/\//g, '-');

                if (!groupedData[event.DetectionZoneName]) {
                    groupedData[event.DetectionZoneName] = []
                }
                
                groupedData[event.DetectionZoneName].push({
                    date: formattedZoneInDate,  // Formatted date for display
                    rawDate: rawDate,          // Full date object for sorting
                    detectionZone: event.DetectionZoneName,
                    trackId: event.TrackId,
                    duration: Math.round(event.Duration), // Rounded duration for display
                    rawDuration: event.Duration          // Raw duration for sorting
                })
            })

            // Sort by rawDate first, then by duration
            Object.keys(groupedData).forEach(zoneName => {
                groupedData[zoneName].sort((a, b) => {
                    if (a.rawDate < b.rawDate) return -1
                    if (a.rawDate > b.rawDate) return 1
                    if (a.rawDuration   > b.rawDuration  ) return -1
                    if (a.rawDuration   < b.rawDuration  ) return 1
                    return 0
                })
            })

            EventListSortedByDate.value = groupedData
        }

        const processEventListDataSortedByDuration = (eventList) => {
            if (!isMounted.value) return

            const groupedData = {}

            eventList.forEach(event => {
                const rawDate = new Date(event.Date);

                const formattedZoneInDate = rawDate.toLocaleString('en-GB', {
                    timeZone: 'Europe/Paris',
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    weekday: 'short'
                }).replace(/\//g, '-');

                if (!groupedData[event.DetectionZoneName]) {
                    groupedData[event.DetectionZoneName] = []
                }
                
                groupedData[event.DetectionZoneName].push({
                    date: formattedZoneInDate,  // Formatted date for display
                    rawDate: rawDate,          // Full date object for sorting
                    detectionZone: event.DetectionZoneName,
                    trackId: event.TrackId,
                    duration: Math.round(event.Duration), // Rounded duration for display
                    rawDuration: event.Duration          // Raw duration for sorting
                })
            })

            // Sort by rawDate first, then by duration
            Object.keys(groupedData).forEach(zoneName => {
                groupedData[zoneName].sort((a, b) => {
                    if (a.rawDuration   > b.rawDuration  ) return -1
                    if (a.rawDuration   < b.rawDuration  ) return 1
                    if (a.rawDate < b.rawDate) return -1
                    if (a.rawDate > b.rawDate) return 1
                    return 0
                })
            })

            EventListSortedByDuration.value = groupedData
        }


        const sortedZoneNames = (data) => {
            return Object.keys(data).sort()
        }

        const formatDuration = (seconds) => {
            if (seconds < 60) {
                return `${seconds} sec`
            } else if (seconds < 3600) {
                const minutes = Math.floor(seconds / 60)
                const remainingSeconds = seconds % 60
                return `${minutes} min ${remainingSeconds} sec`
            } else {
                const hours = Math.floor(seconds / 3600)
                const minutes = Math.floor((seconds % 3600) / 60)
                const remainingSeconds = seconds % 60
                return `${hours} h ${minutes} min ${remainingSeconds} sec`
            }
        }

        const formatDate = (dateStr, datagranularity = 'daily') => {
            const date = new Date(dateStr)
            const day = date.toLocaleString('en-us', { weekday: 'short' })
            const dayOfMonth = date.getDate().toString().padStart(2, '0')
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const year = date.getFullYear()
            
            if (datagranularity === 'hourly') {
                const hours = date.getHours().toString().padStart(2, '0')
                const minutes = date.getMinutes().toString().padStart(2, '0')
                const seconds = date.getSeconds().toString().padStart(2, '0')
                return `${day} ${dayOfMonth}-${month}-${year} - ${hours}:${minutes}:${seconds}`
            } else {
                return `${day} ${dayOfMonth}-${month}-${year}`
            }
        }

        const formatTime = (timeStr) => {
            const [hours, minutes, seconds] = timeStr.split(':').map(Number)
            const formattedHours = hours.toString().padStart(2, '0')
            const formattedMinutes = minutes.toString().padStart(2, '0')
            const formattedSeconds = seconds.toString().padStart(2, '0')
            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
        }

        const normalizeZoneName = (name) => {
            return name.replace(/[^a-zA-Z0-9]/g, '_');
        }


        return {
            activeTab,
            activeZoneTab,
            tableZoneCountsData,
            EventListSortedByDate,
            EventListSortedByDuration,
            zoneTypes,
            sortedZoneNames,
            formatDuration,
            formatDate,
            formatTime,
            normalizeZoneName,
            flowId,
            startDate,
            endDate,
            today,
            dataGranularity,
            duration,
            localDateRangeMode,
            isLoading
        }

    }
}
</script>

<style scoped>

/* Active tab with red background and white text */
.nav-tabs {
  --bs-nav-tabs-link-active-color: #ffffff !important;
  --bs-nav-tabs-link-active-bg: #4F7EB3 !important;
  --bs-nav-tabs-border-color: #000000 !important;
  --bs-nav-tabs-link-hover-border-color: #000000 !important;
}

/* Inactive tab with white background and black text */
.nav {
  --bs-nav-tabs-link-active-border-color: #000000 !important;
  --bs-nav-link-hover-color: #4F7EB3 !important;
  --bs-nav-link-color: #425461 !important;
}

.table-container {
    max-height: 600px; /* Adjust the height as needed */
    overflow-y: auto;
    margin-top: 20px;
    border: 1px solid #ddd; /* Optional: adds a border around the table */
}

table {
    width: 100%; /* Ensure the table fills the container */
    border-collapse: collapse; /* Optional: for styling */
}

/* Additional styling for your table (optional) */
table, th, td {
    border: 1px solid #ddd; /* Adds borders to your table cells */
}

td {
    padding: 8px; /* Adds space around your table content */
    text-align: left; /* Aligns text to the left */
}

th{
    padding: 8px;/* Adds space around your table content */
    text-align: center; /* Aligns text to the left */
    font-size: small;
    background-color: #B2C149;
    color: #ffffff;
}

.loading-overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10; /* Ensure it is above other content */
}

.loader {
  width: 80px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

</style>