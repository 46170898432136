<template>
    <div class="card" style="margin-top: 10px; margin-bottom: 10px;">
        <div class="card-header">
            <h5 class="card-title" style="color: #B2C149">Today's Real-Time Data</h5>
        </div>

        <div class="card-body">
            <div class="card-container">
                <!-- Left Section: Stacked Cards -->
                <div class="left-section">
                    <div class="card counter-card-extra">
                        <div class="card-header">
                            <h6 class="card-title" style="color: #36910d">
                                Real-Time Visitor Count
                            </h6>
                        </div>
                        <div class="card-body">
                            <div v-if="isLoadingTodayCount" class="loader"></div>
                            <div v-else class="text-container centered-text">
                                <p class="current-counter"><b>{{ totalTodaysCount }}</b></p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div v-if="isLoadingTodayCount" class="loader"></div>
                            <div v-else class="text-container centered-text gap-style">
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p :style="{ color: getDifferenceColor(totalTodaysCount - pastAverages['past_averages']['yesterday']) }">
                                        {{ formatDifference(totalTodaysCount - pastAverages['past_averages']['yesterday']) }}
                                    </p>
                                    <span style="font-size: x-small; color: #425461;">vs<br><b>Yesterday</b> Visitors</span>
                                </div>
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p :style="{ color: getDifferenceColor(totalTodaysCount - pastAverages['past_averages']['past_7_days']) }">
                                        {{ formatDifference(totalTodaysCount - pastAverages['past_averages']['past_7_days']) }}
                                    </p>
                                    <span style="font-size: x-small; color: #425461;">vs<br>Past <b>7</b> days Average</span>
                                </div>
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p :style="{ color: getDifferenceColor(totalTodaysCount - pastAverages['past_averages']['past_30_days']) }">
                                        {{ formatDifference(totalTodaysCount - pastAverages['past_averages']['past_30_days']) }}
                                    </p>
                                    <span style="font-size: x-small; color: #425461;">vs<br>Past <b>30</b> days Average</span>
                                </div>
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p :style="{ color: getDifferenceColor(totalTodaysCount - pastAverages['past_averages']['this_week']) }">
                                        {{ formatDifference(totalTodaysCount - pastAverages['past_averages']['this_week']) }}
                                    </p>
                                    <span style="font-size: x-small; color: #425461;">vs<br>This <b>week</b> Average</span>
                                </div>
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p :style="{ color: getDifferenceColor(totalTodaysCount - pastAverages['past_averages']['this_month']) }">
                                        {{ formatDifference(totalTodaysCount - pastAverages['past_averages']['this_month']) }}
                                    </p>
                                    <span style="font-size: x-small; color: #425461;">vs<br>This <b>month</b> Average</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div v-if="isLoadingTodayCount" class="loader"></div>
                            <div v-else class="text-container centered-text gap-style">
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p :style="{ color: getDifferenceColor(totalTodaysCount - pastAverages['past_averages_same_weekday']['past_30_days']) }">
                                        {{ formatDifference(totalTodaysCount - pastAverages['past_averages_same_weekday']['past_30_days']) }}
                                    </p>
                                    <span style="font-size: x-small; color: #425461;">vs<br>Past <b>30</b> days {{ currentWeekdayString }}'s Average</span>
                                </div>
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p :style="{ color: getDifferenceColor(totalTodaysCount - pastAverages['past_averages_same_weekday']['past_365_days']) }">
                                        {{ formatDifference(totalTodaysCount - pastAverages['past_averages_same_weekday']['past_365_days']) }}
                                    </p>
                                    <span style="font-size: x-small; color: #425461;">vs<br>Past <b>365</b> days {{ currentWeekdayString }}'s Average</span>
                                </div>
                                <div class="text-container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                                    <p :style="{ color: getDifferenceColor(totalTodaysCount - pastAverages['past_averages_same_weekday']['this_month']) }">
                                        {{ formatDifference(totalTodaysCount - pastAverages['past_averages_same_weekday']['this_month']) }}
                                    </p>
                                    <span style="font-size: x-small; color: #425461;">vs<br>This <b>month</b> {{ currentWeekdayString }}'s Average</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Right Section: Table -->
                <div class="right-section">
                    <div class="card-span-2 table-wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>Zone</th>
                                    <th>Current in Zone</th>
                                    <th>Today's Count</th>
                                    <th>Average Duration</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(zoneData, zoneName) in processedZones" :key="zoneName">
                                    <td style="color: #425461; padding: 8px; text-align: left; font-size: small" :title="zoneData.zoneType">
                                        <b>{{ zoneName }}</b>
                                    </td>
                                    <td :style="getZoneStyleCurrentIn(zoneData.flowId, zoneData.zoneType)">
                                        <b>{{ zoneData.current }}</b>
                                    </td>
                                    <td style="color: #425461; padding: 8px; text-align: left; font-size: small">
                                        {{ zoneData.todaysCount }}
                                    </td>
                                    <td style="color: #425461; padding: 8px; text-align: left; font-size: small">
                                        {{ formattedDurationTime(zoneData.averageDuration) }}
                                    </td>
                                    <td
                                        style="color: #425461; padding: 8px; text-align: left; font-size: small"
                                        class="icon-cell">
                                        <span class="ok">
                                            <i class="bi bi-check"></i>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onBeforeMount, onMounted, onBeforeUnmount, ref, computed } from 'vue'

import { detection_zone_colors_by_name_solid } from '@/constants.js'

export default {
    name: 'CurrentLiveData',

    setup(props, { emit }) {
        let intervalIds = []
        const zones = []

        const totalTodaysCount = ref(0)
        const pastAverages = ref({})
        const currentWeekday = ref(null)
        const isLoadingTodayCount = ref(true)
        const isLoadingTodayCountTable = ref(true)
        const processedZones = ref({})


        // Utility function to generate a random integer between min and max (inclusive)
        const getRandomInt = (min, max) => {
            return Math.floor(Math.random() * (max - min + 1)) + min
        }

        // Function to generate the pastAverages object
        const generatePastAverages = () => {
            const min = 227 // Set your desired minimum value
            const max = 368 // Set your desired maximum value

            // Get the current weekday (0 for Monday, 6 for Sunday)
            const currentDate = new Date()
            currentWeekday.value = currentDate.getDay() === 0 ? 6 : currentDate.getDay() - 1

            return {
                current_weekday: currentWeekday.value,
                past_averages: {
                past_7_days: getRandomInt(min, max).toFixed(2),
                past_30_days: getRandomInt(min, max).toFixed(2),
                past_365_days: getRandomInt(min, max).toFixed(2),
                this_month: getRandomInt(min, max).toFixed(2),
                this_week: getRandomInt(min, max).toFixed(2),
                yesterday: getRandomInt(0, min).toFixed(2),
                },
                past_averages_same_weekday: {
                past_7_days: getRandomInt(min, max).toFixed(2),
                past_30_days: getRandomInt(min, max).toFixed(2),
                past_365_days: getRandomInt(min, max).toFixed(2),
                this_month: getRandomInt(min, max).toFixed(2),
                this_week: null, // Always null as per your requirements
                yesterday: null, // Always null as per your requirements
                },
            }
        }

        // Function to initialize the zones with default values
        const initializeZones = () => {
            zones.forEach((zone) => {
                const minCount = zone.minCount || 0
                const maxCount = zone.maxCount || 0
                processedZones.value[zone.name] = {
                current: 0,
                todaysCount: zone.name === 'Ingang' ? totalTodaysCount.value : getRandomInt(minCount, maxCount), // Align Entrance with totalTodaysCount
                averageDuration: zone.averageDuration,
                zoneType: zone.zoneType,
                }
            })
        }

        // Function to generate a random `currentValue` with 70% probability for 0
        const getWeightedRandomCurrentValue = () => {
            const random = Math.random() // Generate a random number between 0 and 1
            if (random < 0.5) {
                return 0 // x% chance
            } else {
                return getRandomInt(1, 3) // 30% chance to get 1, 2, or 3
            }
        }

        // Function to start updating zones at random intervals
        const startZoneUpdates = () => {
            zones.forEach((zone) => {
                // Initialize a property to store the previous value for each zone
                let previousCurrent = 0

                const intervalId = setInterval(() => {
                    let currentValue

                    // Ensure `currentValue` is not the same as `previousCurrent`, unless it's 0
                    do {
                        currentValue = getWeightedRandomCurrentValue();
                    } while (currentValue !== 0 && currentValue === previousCurrent)

                    const zoneData = processedZones.value[zone.name]

                    // Handle cases when `currentValue` is greater than 0
                    if (currentValue > 0) {
                        // Check if there is a positive difference (simulates someone leaving the zone)
                        if (previousCurrent > currentValue) {
                            const difference = previousCurrent - currentValue

                            // Add the difference to `todaysCount`
                            zoneData.todaysCount += difference

                            // Update the total count for "Entrance"
                            if (zone.name === 'Ingang') {
                                totalTodaysCount.value += difference
                            }
                        }

                        // Update the `current` value
                        zoneData.current = currentValue

                        // Store the current value as previous for the next interval
                        previousCurrent = currentValue
                    } else {
                        // Handle cases when `currentValue` is 0 (reset the zone)
                        if (previousCurrent > 0) {
                            // Add the previous value to `todaysCount` (simulating all people left)
                            zoneData.todaysCount += previousCurrent

                            // Update the total count for "Entrance"
                            if (zone.name === 'Ingang') {
                                totalTodaysCount.value += previousCurrent
                            }

                            // Reset `current` and `previousCurrent`
                            zoneData.current = 0
                            previousCurrent = 0
                        }
                    }
                }, getRandomInt(zone.minInterval, zone.maxInterval))

                intervalIds.push(intervalId)
            })
        }


        const formattedDurationTime = computed(() => {
            return (seconds) => {
                if (typeof seconds !== 'number' || isNaN(seconds)) {
                    return 'N/A'; // Handle invalid input
                }

                if (seconds < 60) {
                    return `${seconds.toFixed(0)} sec`
                } else if (seconds < 3600) {
                    const minutes = Math.floor(seconds / 60)
                    const remainingSeconds = seconds % 60
                    return `${minutes} min ${remainingSeconds.toFixed(0)} sec`
                } else {
                    const hours = Math.floor(seconds / 3600)
                    const remainingMinutes = Math.floor((seconds % 3600) / 60)
                    const remainingSeconds = seconds % 60
                    return `${hours} h ${remainingMinutes} min ${remainingSeconds.toFixed(0)} sec`
                }
            }
        })

        const currentWeekdayString = computed(() => {
            if (currentWeekday.value >= 0 && currentWeekday.value <= 6) {
                const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
                return daysOfWeek[currentWeekday.value]
            } else {
                return 'Same Weekday'
            }
        })

        const getZoneStyleCurrentIn = (flowId, zoneType) => {
            return {
                color: getColorForZone(zoneType),
                padding: '8px',
                textAlign: 'left',
                fontSize: 'small',
            }
        }

        const formatDifference = (difference) => {
            const roundedValue = Math.round(difference)
            return roundedValue > 0 ? `+${roundedValue}` : `${roundedValue}`
        }

        const getDifferenceColor = (difference) => {
            return difference > 0 ? '#36910d' : '#C21500'
        }


        onMounted(async () => {
            // Set the initial value
            isLoadingTodayCount.value = true
            isLoadingTodayCountTable.value = true
            totalTodaysCount.value = getRandomInt(27, 343)
            isLoadingTodayCount.value = false
            pastAverages.value = generatePastAverages();

            zones.push(
                { name: 'Ingang', averageDuration: 2, zoneType: 'Entrance Zone', minCount: 27, maxCount: 343, minInterval: 1000, maxInterval: 3000 },
                { name: 'Demo Robotmaaier', averageDuration: 43, zoneType: 'Demo Zone', minCount: Math.floor(totalTodaysCount.value * 0.1), maxCount: Math.floor(totalTodaysCount.value * 0.12), minInterval: 6000, maxInterval: 30000 },
                { name: 'Demo Gereedschapswagen', averageDuration: 136, zoneType: 'Demo Zone', minCount: Math.floor(totalTodaysCount.value * 0.05), maxCount: Math.floor(totalTodaysCount.value * 0.1), minInterval: 6000, maxInterval: 30000 },
                { name: 'Promo Hoge Druk Reiniger', averageDuration: 6, zoneType: 'Display Zone', minCount: Math.floor(totalTodaysCount.value * 0.22), maxCount: Math.floor(totalTodaysCount.value * 0.23), minInterval: 500, maxInterval: 8000 },
                { name: 'Promo Veiligheidsschoenen', averageDuration: 4, zoneType: 'Display Zone', minCount: Math.floor(totalTodaysCount.value * 0.18), maxCount: Math.floor(totalTodaysCount.value * 0.24), minInterval: 1000, maxInterval: 8000 },
                { name: 'Rek Veiligheiddschoenen', averageDuration: 204, zoneType: 'Product Zone', minCount: Math.floor(totalTodaysCount.value * 0.45), maxCount: Math.floor(totalTodaysCount.value * 0.5), minInterval: 6000, maxInterval: 30000 },
                { name: 'Metabo zone', averageDuration: 41, zoneType: 'Product Zone', minCount: Math.floor(totalTodaysCount.value * 0.2), maxCount: Math.floor(totalTodaysCount.value * 0.35), minInterval: 4000, maxInterval: 30000 },
                { name: 'Dewalt zone', averageDuration: 77, zoneType: 'Product Zone', minCount: Math.floor(totalTodaysCount.value * 0.25), maxCount: Math.floor(totalTodaysCount.value * 0.3), minInterval: 5000, maxInterval: 30000 },
                { name: 'Kassa 1 Wachtzone', averageDuration: 37, zoneType: 'Checkout Waiting Zone', minCount: Math.floor(totalTodaysCount.value * 0.08), maxCount: Math.floor(totalTodaysCount.value * 0.09), minInterval: 2000, maxInterval: 30000 },
                { name: 'Kassa 2 Wachtzone', averageDuration: 143, zoneType: 'Checkout Waiting Zone', minCount: Math.floor(totalTodaysCount.value * 0.08), maxCount: Math.floor(totalTodaysCount.value * 0.09), minInterval: 2000, maxInterval: 250000 },
                { name: 'Kassa 1', averageDuration: 23, zoneType: 'Checkout Interaction Zone', minCount: Math.floor(totalTodaysCount.value * 0.08), maxCount: Math.floor(totalTodaysCount.value * 0.09), minInterval: 2000, maxInterval: 30000 },
                { name: 'Kassa 2', averageDuration: 36, zoneType: 'Checkout Interaction Zone', minCount: Math.floor(totalTodaysCount.value * 0.08), maxCount: Math.floor(totalTodaysCount.value * 0.09), minInterval: 2000, maxInterval: 30000 },
            )
            initializeZones()
            startZoneUpdates()
            
            isLoadingTodayCountTable.value = false
        })


        const getColorForZone = (zoneType) => {
            return detection_zone_colors_by_name_solid[zoneType] || 'black'
        }
        

        onBeforeUnmount(() => {
            // Clear any pending timeouts to prevent memory leaks
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
            intervalIds.forEach((id) => clearInterval(id))
            
        })

        return {
            isLoadingTodayCount,
            isLoadingTodayCountTable,
            formattedDurationTime,
            getColorForZone,
            formatDifference,
            getDifferenceColor,
            totalTodaysCount,
            pastAverages,
            currentWeekdayString,
            processedZones,
            getZoneStyleCurrentIn,
        }
    }
}

</script>

<style scoped>
.counter-card-extra {
    width: 100%px;
    height: 180px;
    box-sizing: border-box;
}

.current-counter {
    font-size: clamp(48px, 2vw, 52px) !important; /* Dynamically adjust font size */
}

.centered-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensure it takes the full height of the card */
    text-align: center; /* Center text horizontally */
    color: #36910d; /* Matches the header text color */
}

.card-container {
    display: grid;
    grid-template-columns: 1fr 2fr; /* Left section 1/3, Right section 2/3 */
    gap: 20px;
    align-items: start; /* Align items to the top */
}

.gap-style {
    gap: 20px
}

.text-container.centered-text.gap-style {
  /* By default, they might be in a row, no wrap */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
  /* No wrap (if that’s desired above 850px) */
  flex-wrap: nowrap;
}

.left-section,
.right-section {
  min-width: 0; /* Allows them to shrink smaller than their contents */
  /* If content is long/wide, it may overflow. Let’s handle that next. */
}

.left-section {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between stacked cards */
}

.right-section {
    grid-column: 2 / 3; /* Ensures the table spans the right section */
}

.card-span-2 {
    grid-column: 2 / 3; /* Start at the second column and span to the end */
}

.table-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 0.375rem;
}

table {
    width: 100%; /* Ensure the table fills its container */
    border-collapse: collapse; /* Use 'separate' to allow border-radius to work */
    border-spacing: 0; /* Optional: Adjust spacing between cells */
    border-radius: 0.375rem; /* Adjust the radius to match the card's corners */
    overflow: hidden; /* Prevent content from overflowing the rounded corners */
    border: 1px solid rgba(0, 0, 0, 0.175); /* Optional: Adds a border around the table */
}

th, td {
    border: 1px solid rgba(0, 0, 0, 0.175); /* Ensures consistent borders */
    padding: 8px;
    text-align: left;
}

th {
    padding: 11px; /* Adds space around the content */
    text-align: center; /* Aligns text to the center */
    font-size: small; /* Adjusts font size */
    background-color: rgba(33, 37, 41, 0.03); /* Sets background color */
    color: #4F7EB3; /* Sets font color */
}

th:first-child {
    border-top-left-radius: 0.375rem; /* Rounded corner for the top-left */
}

th:last-child {
    border-top-right-radius: 0.375rem; /* Rounded corner for the top-right */
}

tr:last-child td:first-child {
    border-bottom-left-radius: 0.375rem; /* Rounded corner for the bottom-left */
}

tr:last-child td:last-child {
    border-bottom-right-radius: 0.375rem; /* Rounded corner for the bottom-right */
}

.icon-cell {
    text-align: center; /* Centers horizontally */
    vertical-align: middle; /* Centers vertically */
    padding: 8px; /* Adjust padding if needed */
}

.ok {
    display: inline-flex; /* Flexbox for proper alignment */
    align-items: center; /* Vertical alignment */
    justify-content: center; /* Horizontal alignment */
    height: 100%; /* Ensures full height for vertical centering */
    width: 100%; /* Ensures full width for alignment in the cell */
}

.ok .bi-check {
    color: green;
    font-size: 1.2em; /* Adjust icon size if needed */
}

.loader {
  width: 20px;
  margin-top: 20px;
  padding: 2px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

/* Responsive wrapping for screen width below 1500px */
@media (max-width: 1500px) {
    .card-container {
        grid-template-columns: 1fr; /* Cards and table stack vertically */
    }

    .left-section {
        grid-column: 1 / -1; /* Cards take full width */
    }

    .right-section {
        grid-column: 1 / -1; /* Table takes full width below cards */
    }

    .card {
        width: 100%; /* Ensure cards stretch to full width */
    }

    .gap-style {
        gap: 50px
    }
}

@media (max-width: 500px) {
  .text-container.centered-text.gap-style {
    /* Switch to wrapping below 850px */
    flex-wrap: wrap;
    justify-content: center; /* or 'flex-start' if you prefer */
  }

  /* Optionally, you can let each .text-container box go 100% width, or 50%, etc. */
  .text-container.centered-text.gap-style > .text-container {
    flex: 1 1 100%; 
    /* The line above will make each box take the full width. 
       If you want two per row, do `flex: 1 1 50%`. 
       If you want more precise control, pick an exact max-width, etc. */
    box-sizing: border-box;
  }

  .table-wrapper th,
  .table-wrapper td,
  .table-wrapper td * {
    font-size: 0.8rem !important;
  }
  
}

@media (max-width: 475px) {
  .table-wrapper th,
  .table-wrapper td,
  .table-wrapper td * {
    font-size: 0.7rem !important;
  }
  
}

@media (max-width: 440px) {
  .table-wrapper th,
  .table-wrapper td,
  .table-wrapper td * {
    font-size: 0.6rem !important;
  }
  
}

@media (max-width: 410px) {
  .table-wrapper th,
  .table-wrapper td,
  .table-wrapper td * {
    font-size: 0.5rem !important;
  }
  
}

@media (max-width: 375px) {
  .table-wrapper th,
  .table-wrapper td,
  .table-wrapper td * {
    font-size: 0.4rem !important;
  }
  
}
</style>