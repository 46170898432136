<template>
  <div>
    <div class="dropdown-container position-relative">
      <i class="bi bi-calendar4 position-absolute dropdown-icon-left"></i>
      <select class="form-select form-select-sm dropdown-with-icon-left" v-model="localSelectedDuration" @change="handleSelection">
        <option :value="1">Yesterday</option>
        <option :value="7">Last 7 Days</option>
        <option :value="30">Last 30 Days</option>
        <option :value="365">Last 365 Days</option>
        <option :value="1000">This Week</option>
        <option :value="2000">This Month</option>
      </select>
    </div>
  </div>
</template>

<script>
import { ref, watch, toRefs } from 'vue';

export default {
  name: 'DurationDropdown',

  props: {
    dateRangeMode: String,
    selectedDuration: {
      type: Number,
      default: 30, // Default to "Last 30 Days"
    },
  },

  emits: ['updateDateRangeMode', 'updateDuration', 'clearDatePicker'],

  setup(props, { emit }) {
    const localSelectedDuration = ref(props.selectedDuration);
    const { dateRangeMode } = toRefs(props);

    const handleSelection = () => {
      emit('updateDuration', localSelectedDuration.value);
      emit('updateDateRangeMode', 'duration');
      emit('clearDatePicker', true);
    };

    watch(dateRangeMode, (newMode) => {
      if (newMode === 'dateRange') {
        localSelectedDuration.value = null; // Allow user to select any value
      }
    });

    return {
      localSelectedDuration,
      dateRangeMode,
      handleSelection,
    };
  },
};
</script>

<style scoped>
.dropdown-container {
  position: relative;
  width: 160px; /* Adjust width as needed */
}

.form-select {
  color: #4F7EB3;
  padding: 5px 10px;
  border-radius: 4px;
}

.dropdown-with-icon-left {
  padding-left: 2.5rem; /* Add padding to make room for the icon */
}

.dropdown-icon-left {
  left: 10px; /* Position icon inside the select box on the left */
  top: 50%; /* Center vertically */
  transform: translateY(-50%);
  pointer-events: none; /* Prevent interaction with the icon */
  color: #425461; /* Adjust color if needed */
  font-size: 1rem; /* Adjust size if needed */
}
</style>