<template>
    <div>
        <div v-if="isLoading" style="display: flex; justify-content: center;">
            <div class="loader" style="margin-top: 400px;"></div>
        </div>
            
        <div v-if="!isLoading" class="card">
            <div class="card-header">
                <div><h3 class="card-title">Newell DIY's Intelligence Hub</h3></div>
                <button class="toggle-sidebar">Export Report</button>
            </div>

            <div class="dashboard-wrapper">
                <div class="container">
                    <storalytic-demo-current-live-data></storalytic-demo-current-live-data>
                </div>
                <div class="container">
                    <storalytic-demo-fixed-widgets @selectedDuration="handleUpdateDuration"></storalytic-demo-fixed-widgets>
                </div>

            </div>
        </div>

    </div>
</template>
  
<script>
import { ref, onMounted, defineComponent,  } from 'vue'

import StoralyticDemoFixedWidgets from './StoralyticDemoFixedWidgets.vue'
import StoralyticDemoCurrentLiveData from './StoralyticDemoCurrentLiveData.vue'

export default defineComponent({
    name: 'Dashboard',

    components: {
        StoralyticDemoFixedWidgets,
        StoralyticDemoCurrentLiveData,
    },

    setup() {
        const selectedDurationFixedWidgetData = ref(30)
        const isLoading = ref(true)
        
        const pause = (milliseconds) => {
            return new Promise((resolve) => setTimeout(resolve, milliseconds))
        }

        onMounted(async () => {
            await pause(1000)
            isLoading.value = false
        })

        const handleUpdateDuration = async (newDuration) => {
            selectedDurationFixedWidgetData.value = newDuration
            await fetchFixedWidgetData()
        }


        const changeDuration = (newDuration) => {
            duration.value = newDuration
        }

        return {
            changeDuration,
            handleUpdateDuration,
            isLoading,

        }
    }
})
</script>
  
<style scoped>
.dashboard-wrapper {
    min-height: 100vh; /* Cover full viewport height */
    height: auto; /* Allow it to adjust based on content */
    display: flex;
    flex-direction: column;
    overflow: visible;
}

.container {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    overflow: visible; /* Ensure the container itself does not scroll */
    min-height: 0; /* Allows the container to shrink and expand based on content */
}

.card {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible; /* Ensure the card itself does not scroll */
    min-height: 0; /* Allows the card to shrink and expand based on content */
}

.card-title {
  color: #4F7EB3 !important;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Center content vertically */
    padding: 10px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ccc;
}

.toggle-sidebar {
  background-color: #4f7eb3;
  color: white;
  border: none;
  padding: 4px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.75rem;
}

.toggle-sidebar:hover {
  background-color: #b2c149;
}

.close-btn {
  background-color: #4f7eb3;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.close-btn:hover {
  background-color: #b2c149;
  color: #fff;
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  right: -450px;
  width: 450px;
  height: 100vh;
  background-color: white;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: right 0.3s ease;
  z-index: 1000;
  padding-top: 0px;
  margin-top: 0px;
}

.loader {
    width: 80px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #B2C149;
    --_m: 
        conic-gradient(#0000 10%,#000),
        linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

@media (min-width: 1600px) {
    .container {
        max-width: 1520px;
    }
}

</style>