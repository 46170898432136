<template>
    <div>
        <!-- Hamburger Menu for small screens -->
        <div class="hamburger" @click="toggleSidebar" v-if="isSmallScreen && !sidebarOpen">
            &#9776; <!-- This is the hamburger icon (3 lines) -->
        </div>
        
        <!-- Sidebar -->
        <div class="sidebar" :class="{ 'open': sidebarOpen, 'small-screen': isSmallScreen }" v-if="!isSmallScreen || sidebarOpen">

            <!-- Close button for small screens -->
            <div class="close-btn" @click="toggleSidebar" v-if="isSmallScreen">
                &times; <!-- Close Icon (X) -->
            </div>

            <div class="sidebar-logo">
                <img :src="logo_storalytic" alt="Logo Storalytic" />
            </div>

            <!-- Company name and subscription type at the top -->
            <div class="company-info">
                <div>Newell DIY</div>
                <div class="subscription-type">Base Subscription</div>
                <hr class="thin-divider">
            </div>
            

            <!-- Navigation links -->
            <div class="main-links">
                <p class="nav-link-active" style="margin-bottom: 2px;"><i>Dashboard</i></p>
                
                <hr class="thin-divider">

                <p class="nav-link" style="margin-bottom: 2px; margin-top: 10px;"><i class="bi bi-lightning-fill"></i> AI Store Analyzer</p>
                
                <hr class="thin-divider">
                
                <p class="nav-link" style="margin-bottom: 2px;">All Camera Overview</p>
                
                <div>
                    <!-- Cameras toggle with triangle icon -->
                    <div class="expandable-header" @click="toggleFlowList">
                        <span :class="{'triangle-icon': true, 'expanded': showFlowList}"></span>
                        Cameras
                    </div>
                    
                    <!-- Flow names list under Camera Overview, visible only if expanded -->
                    <div v-if="showFlowList" class="flow-list">
                        <div v-for="(flow, index) in flowStore" :key="index">
                            <p class="flow-link"  style="margin-bottom: 2px;">{{ flow }}</p>
                        </div>
                    </div>
                </div>
                
                <hr class="thin-divider">
                
                <p class="nav-link" style="margin-bottom: 2px;">External Data</p>

                <hr class="thin-divider">

                <p class="nav-link" style="margin-bottom: 2px;">Connect New Camera View</p>
                <p class="nav-link" style="margin-bottom: 2px;">Connect New External Data Source</p>

                <hr class="thin-divider">

            </div>

            <!-- Footer section with connection status, settings, and user info -->
            <div class="sidebar-footer">
                
                <hr class="thin-divider">

                <!-- Connection status -->
                <div class="connection-status" style="padding-top: 5px;">
                        <h6 style="font-size: 0.76rem;">EdgAlytic Connected: <span class="status-indicator status-running"></span></h6>
                </div>

                <hr class="thin-divider">

                <!-- Settings link and user info -->
                <div class="settings-container">
                    <p class="nav-link" style="font-size: 0.76rem;">
                        <i class="bi bi-gear-fill"></i> Settings
                    </p>
                    <div class="user-info">
                        <i class="bi bi-person-fill"></i>
                        Allen Newell - Admin : 
                        <a class="logout-link">Logout</a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
  
<script>
    import { ref, onMounted, onBeforeUnmount } from 'vue'

    import { useToast } from 'vue-toastification'

    import image from "./icons/logo_storalytic.png"

    export default {
        setup(props, { emit }) {
            const toast = useToast()
            const flowStore = ref([]) 

            const showFlowList = ref(false)
            const toggleFlowList = () => {
                showFlowList.value = !showFlowList.value;

            }

            // State for sidebar visibility and screen size
            const sidebarOpen = ref(false)
            const isSmallScreen = ref(window.innerWidth <= 768)
            const toggleSidebar = () => {
                sidebarOpen.value = !sidebarOpen.value
            }

            const pause = (milliseconds) => {
                return new Promise((resolve) => setTimeout(resolve, milliseconds))
            }

            // Add resize event listener when component is mounted
            onMounted( async () => {
                flowStore.value = ['Camera Ingang', 'Camera Robotmaaiers', 'Camera Dewalt Zone', 'Camera Metabo Zone', 'Camera Veiligheidschoenen','Camera Kassa Zone' ]
                window.addEventListener('resize', handleResize)
                handleResize() // Set initial state based on current window size
                
                await pause(2000)
                
                toast.success('Connected with Local Demo Device')
            })

            // Handling screen resize
            const handleResize = () => {
                isSmallScreen.value = window.innerWidth <= 768
                if (!isSmallScreen.value) {
                    sidebarOpen.value = true // Keep sidebar open on large screens
                } else {
                    sidebarOpen.value = false // Hide sidebar initially on small screens
                }
            }
  
            onBeforeUnmount(() => {
                // Remove the resize event listener when component is unmounted
                window.removeEventListener('resize', handleResize)
            })

            return {
                page: 'home',
                logo_storalytic: image,
                flowStore,
                toggleFlowList,
                showFlowList,
                sidebarOpen,
                isSmallScreen,
                toggleSidebar,
            }
        }
    }
</script>
  
<style scoped>
/* Sidebar container */
.sidebar {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1100;
    padding: 10px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease; /* Smooth slide-in transition */
}

.sidebar.small-screen {
    height: 90vh; /* Only 90% height on small screens */
    transform: translateX(-100%); /* Initially hidden off-screen */
}

.sidebar.open.small-screen {
    transform: translateX(0); /* Slide in on small screens */
}

/* Sidebar content styling */
.sidebar-content {
    padding: 10px;
}

/* Header company info */
.company-info {
    font-size: 0.9rem;
    color: #425461;
    font-weight: bold;
    padding-left: 10px;
}

.main-links {
    padding-left: 10px;
}

.subscription-type {
    color: #9FC8E3;
}

/* Divider lines */
.thin-divider {
    margin: 5px 0;
    border: none;
    border-top: 1px solid #B2C149;
}

/* Sidebar links */
.nav-link {
    color: #425461;
    padding: 8px 0;
    text-decoration: none;
    font-size: 0.85rem;
    display: block;
}

.nav-link-active {
    color: #b2c149;
    padding: 8px 0;
    text-decoration: none;
    font-size: 0.85rem;
    display: block;
}

.nav-link:hover {
    color: #b2c149;
}

/* Flow list styling */
.flow-list {
    padding-left: 20px;
    font-size: 0.8rem;
}

.flow-link {
    color: #425461;
    padding: 3px 0;
    text-decoration: none;
}

.flow-link:hover {
    color: #b2c149;
}

/* Footer with logo and settings */
.sidebar-footer {
    margin-top: auto;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Ensure children expand to full width */
    padding-left: 10px;
    font-size: 0.72rem;
    color: #425461;
}

.sidebar-logo img {
    width: 100px;
    height: auto;
    margin-bottom: 10px;
}

/* Connection status styling */
.connection-status {
    color: #425461;
    padding-left: 0px; /* Adjust padding for alignment */
}

.status-indicator {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.status-running {
    background-color: #6C9046;
}

/* Settings and user info */
.settings-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
    padding-left: 0px; /* Add padding to match alignment */
}

.user-info {
    margin: 5px 0;
    color: #425461;
    display: inline; /* Ensure inline display */
    font-size: 0.76rem;
}

.logout-link {
    color: #9FC8E3;
    cursor: pointer;
    text-decoration: none;
    display: inline; /* Ensure inline display */
    padding-left: 5px; /* Optional: add space after the colon */
}

.logout-link:hover {
    text-decoration: underline;
}

/* Styles for the expandable header */
.expandable-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.85rem;
    color: #425461;
    padding: 8px 10px;
}

/* Triangle icon with rotation */
.triangle-icon {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 8px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #425461;
    transform: rotate(-90deg);
    transition: transform 0.2s ease;
}

.triangle-icon.expanded {
    transform: rotate(0deg); /* Rotates to point down when expanded */
}

/* Flow list */
.flow-list {
    padding-left: 20px;
    font-size: 0.8rem;
}

.flow-link {
    color: #425461;
    padding: 3px 0;
    text-decoration: none;
}

.flow-link:hover {
    color: #b2c149;
}

/* Hamburger menu for small screens */
.hamburger {
    font-size: 24px;
    color: #425461;
    cursor: pointer;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1100; /* Above sidebar */
}

/* Close button for small screens */
.close-btn {
    font-size: 24px;
    color: #425461;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 20px;
}


/* Adjustments for small screens */
@media (max-width: 768px) {
    /* Sidebar should be hidden initially */
    .sidebar {
        width: 60vw;
    }
}


</style>