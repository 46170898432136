import { apiClientEVENTS } from '@/config_sessions'

import { useToast } from 'vue-toastification'
const toast = useToast()


const authHeader = () => ({
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    }
})

const handleAxiosError = (error) => {
    if (error.response) {
        const { status, data } = error.response
        const errorMessage = data.message || data.error || 'Unknown error'

        if (status === 401 && errorMessage.includes('Token')) {
            console.error('Token expired. Redirecting to login.')
            toast.error('Token expired. Please log in again.')
            // Redirect to login page
            window.location.href = '/login'
        } else {
            console.error('Server Error:', errorMessage)
            toast.error(`Server Error: ${errorMessage}`)
        }
    } else if (error.request) {
        console.error('Network Error:', error.request)
        toast.error('Network Error. Please try again later.')
    } else {
        console.error('Error:', error.message)
        toast.error(`Error: ${error.message}`)
    }
}


// Function to format the date and get the timezone offset
const formatDateWithOffset = (date) => {
    // Convert local machine date to UTC time zone
    const dateString = date.toISOString()
    
    // Get the offset in minutes (e.g., -120 for GMT+0200)
    const offsetMinutes = -date.getTimezoneOffset()
    
    // Convert offset to hours and minutes
    const offsetHours = Math.floor(offsetMinutes / 60)
    const offsetMins = offsetMinutes % 60
    const offsetString = `GMT${offsetHours >= 0 ? '+' : ''}${offsetHours.toString().padStart(2, '0')}${offsetMins.toString().padStart(2, '0')}`

    return { date: dateString, offset: offsetString }
}


export const getCurrentCounters = async (minimuDurationThreshold) => {
    try {
        const response = await apiClientEVENTS.get(`/get_current_counters/`, {
            params: {
                threshold: minimuDurationThreshold,
            },
            ...authHeader()
        })
            
        return response.data
    } catch (error) {
        console.error('Could not fetch current counters:', error)
        handleAxiosError(error)
        return {}
    }
}


export const getPastAverages = async () => {
    try {
        const response = await apiClientEVENTS.get(`/get_past_averages/`, {
            params: {
                
            },
            ...authHeader()
        })
            
        return response.data
    } catch (error) {
        console.error('Could not fetch current counters:', error)
        handleAxiosError(error)
        return {}
    }
}


export const getCurrentCountersByFlowId = async (flowId) => {
    try {
        const response = await apiClientEVENTS.get(`/get_current_counters/${flowId}`, {
            params: {
                
            },
            ...authHeader()
        })
            
        return response.data
    } catch (error) {
        console.error('Could not fetch current counters:', error)
        handleAxiosError(error)
        return {}
    }
}


export const getCountersByDuration = async (flowId, duration) => {
    
    try {
        const response = await apiClientEVENTS.get(`/get_counters_by_duration/${flowId}`, {
            params: {
                duration: duration
            },
            ...authHeader()
        })
            
        return response.data
    } catch (error) {
        console.error('Could not fetch counters by duration:', error)
        handleAxiosError(error)
        return {}
    }
}


export async function getCountersByDateRange(flowId, startDate, endDate) {
    if (!startDate || !endDate) {
        return {} // Return an empty object when startDate or endDate is null
    }
    const startDateFormatted = formatDateWithOffset(startDate)
    const endDateFormatted = formatDateWithOffset(endDate)

    console.log('ESD', startDate)
    console.log('EED', endDate)

    try {
        const response = await apiClientEVENTS.get(`/get_counters_by_date_range/${flowId}`, {
            params: {
                startDate: startDate,
                endDate: endDate,
                startDateOffset: startDateFormatted.offset, 
                endDateOffset: endDateFormatted.offset
            },
            ...authHeader()
        })
        return response.data
    } catch (error) {
        console.error('Could not fetch counters by date range:', error)
        handleAxiosError(error)
        return {}
    }
}


export const getCurrentCountersListByFlowId = async (flowId, dataGranularity) => {
    try {
        const response = await apiClientEVENTS.get(`/get_current_counters_list/${flowId}`, {
            params: {
                dataGranularity: dataGranularity,
            },
            ...authHeader()
        })
            
        return response.data
    } catch (error) {
        console.error('Could not fetch current counters:', error)
        handleAxiosError(error)
        return {}
    }
}


export const getCountersListByDuration = async (flowId, duration, dataGranularity) => {
    try {
        const response = await apiClientEVENTS.get(`/get_counters_list_by_duration/${flowId}`, {
            params: {
                duration: duration,
                dataGranularity: dataGranularity
            },
            ...authHeader()
        })
        return response.data
    } catch (error) {
        console.error('Could not fetch counters list by day by duration:', error)
        handleAxiosError(error)
        return {}
    }
}


export async function getcountersListByDateRange(flowId, startDate, endDate, dataGranularity) {
    if (!startDate || !endDate) {
        return {} // Return an empty object when startDate or endDate is null
    }

    const startDateFormatted = formatDateWithOffset(startDate)
    const endDateFormatted = formatDateWithOffset(endDate)

    try {
        const response = await apiClientEVENTS.get(`/get_counters_list_by_date_range/${flowId}`, {
            params: {
                startDate: startDate,
                endDate: endDate,
                dataGranularity: dataGranularity,
                startDateOffset: startDateFormatted.offset, 
                endDateOffset: endDateFormatted.offset
            },
            ...authHeader()
        })
        return response.data
    } catch (error) {
        console.error('Could not fetch counters list by day by date range:', error)
        handleAxiosError(error)
        return {}
    }
}


export const getEventListByDuration = async (flowId, duration) => {
    try {
        const response = await apiClientEVENTS.get(`/get_event_list_by_duration/${flowId}`, {
            params: {
                duration: duration,
            },
            ...authHeader()
        })
        return response.data
    } catch (error) {
        console.error('Could not fetch event list by duration:', error)
        handleAxiosError(error)
        return {}
    }
}


export const getEventListByDateRange = async (flowId, startDate, endDate) => {
    if (!startDate || !endDate) {
        return {} // Return an empty object when startDate or endDate is null
    }

    const startDateFormatted = formatDateWithOffset(startDate)
    const endDateFormatted = formatDateWithOffset(endDate)

    try {
        const response = await apiClientEVENTS.get(`/get_event_list_by_date_range/${flowId}`, {
            params: {
                startDate: startDate,
                endDate: endDate,
                startDateOffset: startDateFormatted.offset, 
                endDateOffset: endDateFormatted.offset
            },
            ...authHeader()
        })
        return response.data
    } catch (error) {
        console.error('Could not fetch event list by date range:', error)
        handleAxiosError(error)
        return {}
    }
}


export const getHeatmapByDuration = async (selectedDuration) => {
    try {
        const response = await apiClientEVENTS.get(`/get_heatmap_by_duration/`, {
            params: {
                selectedDuration: selectedDuration,
            },
            ...authHeader()
        })
        return response.data
    } catch (error) {
        console.error('Error fetching data for fixed dashboard widgets:', error)
        handleAxiosError(error)
        return {}
    }
}


export const getEntranceChartDataByDuration = async (selectedDuration) => {
    try {
        const response = await apiClientEVENTS.get(`/get_entrance_chart_data_by_duration/`, {
            params: {
                selectedDuration: selectedDuration,
            },
            ...authHeader()
        })
        return response.data
    } catch (error) {
        console.error('Error fetching data for fixed dashboard widgets:', error)
        handleAxiosError(error)
        return {}
    }
}


export const getTopPDDChartData = async (selectedDuration) => {
    try {
        const response = await apiClientEVENTS.get(`/get_top_pdd_chart_data/`, {
            params: {
                selectedDuration: selectedDuration,
            },
            ...authHeader()
        })
        return response.data
    } catch (error) {
        console.error('Error fetching data for fixed dashboard widgets:', error)
        handleAxiosError(error)
        return {}
    }
}

export const getFixedDashboardCountersValues = async (selectedDuration) => {
    try {
        const response = await apiClientEVENTS.get(`/get_fixed_dashboard_counters_values/`, {
            params: {
                selectedDuration: selectedDuration,
            },
            ...authHeader()
        })
        return response.data
    } catch (error) {
        console.error('Error fetching data for fixed dashboard widgets:', error)
        handleAxiosError(error)
        return {}
    }
}


export const sendMessageToAgent = async (userInput) => {
    try{
        const response = await apiClientEVENTS.get(`/send_message_to_agent/`, {
            params: {
                userInput: userInput,
            },
            ...authHeader()
        })
        return response.data

    } catch (error) {
        console.error('Error sending message to data analytics agent:', error)
        handleAxiosError(error)
        return {}
    }
}