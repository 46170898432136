<template>
    <div>
        <div v-if="isLoading" style="display: flex; justify-content: center;">
            <div class="loader" style="margin-top: 400px;"></div>
        </div>
            
        <div v-if="!isLoading" class="card">
            <div class="card-header">
                <div><h3 class="card-title">{{ CompanyStore.company.CompanyName }}’s Intelligence Hub</h3></div>
                <!-- <button class="toggle-sidebar" v-show="!isSidebarOpen" @click="toggleSidebar"> << Intalytic</button> -->
            </div>

            <div class="dashboard-wrapper">
                <div class="container">
                    <current-live-data></current-live-data>
                </div>
                <div class="container">
                    <fixed-widgets :fixed-widgets-data="fixedWidgetData" @selectedDuration="handleUpdateDuration"></fixed-widgets>
                </div>

            </div>
        </div>

        <!-- Side Bar for Chat Agent -->
        <div :class="['sidebar', { open: isSidebarOpen }]">
            <div class="sidebar-header">
                <h4 style="padding-top: 10px; padding-left: 10px;">Storalytic Intelligence Assistant</h4>
                <button class="toggle-sidebar close-btn" @click="toggleSidebar">X</button>
            </div>
            <div class="chat-container">
                <div class="chat-messages">
                    <div v-for="message in messages" :key="message.id" :class="message.sender">
                        <span style="font-size: xx-small;">{{ message.timestamp }}</span><br>
                        {{ message.sender }}: {{ message.text }}
                    </div>
                </div>
                <div class="chat-input">
                    <input
                        v-model="userInput"
                        @keydown.enter="sendMessage"
                        placeholder="Ask me anything about your store data..."
                    />
                    <button @click="sendMessage">Send</button>
                </div>
            </div>
        </div>

    </div>
</template>
  
<script>
import { ref, onMounted, onUnmounted, watch, defineComponent,  } from 'vue'

import { useUserStore } from '@/stores/UserStore'
import { useCompanyStore } from '@/stores/CompanyStore'

import FixedWidgets from './FixedWidgets.vue'
import CurrentLiveData from './CurrentLiveData.vue'

import {getFixedDashboardCountersValues, sendMessageToAgent} from "@/api/eventsAPI.js"

export default defineComponent({
    name: 'Dashboard',

    components: {
        FixedWidgets,
        CurrentLiveData,

    },

    setup() {
        const CompanyStore = useCompanyStore()
        const userStore = useUserStore()

        const controller = new AbortController()

        const selectedDurationFixedWidgetData = ref(30)
        const dateRangeMode = ref('duration')
        const clearDatePicker = ref(null)
        
        const dataGranularity = ref('daily')

        const fixedWidgetData = ref({})

        const isSidebarOpen = ref(false)
        const messages = ref([])
        const userInput = ref('')

        const isLoading = ref(true)

        onMounted(async () => {
            // Fetch user details to get the current user's CompanyId
            await userStore.fetchUserDetails()
            if (userStore.user.CompanyId) {
                await CompanyStore.fetchCompanyDetails(userStore.user.CompanyId)
            }

            // Fetch Fixed Widget Data
            await fetchFixedWidgetData()
            isLoading.value = false
        })

        watch(() => dateRangeMode.value, async (newValue) => {
            if (newValue) {
                try {
                    await fetchAndGenerateWidgets() // Ensure this is called
                    saveDashboardState()
                } catch (error) {
                    console.error('Error updating widgets:', error)
                }
            }
        })

        watch(() => dataGranularity.value, async (newValue) => {
            if (newValue) {
                try {
                    await fetchAndGenerateWidgets() // Ensure this is called
                    saveDashboardState()
                } catch (error) {
                    console.error('Error updating widgets:', error)
                }
            }
        })

        const fetchFixedWidgetData = async () => {
            try {
                const fullData = await getFixedDashboardCountersValues(selectedDurationFixedWidgetData.value, { signal: controller.signal })

                const newStoredData = {
                    current_weekday: fullData.current_weekday,
                    average_same_weekday: Math.round(fullData.average_same_weekday),
                    average_visitors: Math.round(fullData.average_visitors),
                    highest_total_hour: fullData.highest_total_hour,
                    highest_total_hour_count: fullData.highest_total_hour_count,
                    highest_total_day: fullData.highest_total_day,
                    highest_total_day_count: fullData.highest_total_day_count,
                    busiest_hour: fullData.busiest_hour,
                    average_on_busiest_hour: Math.round(fullData.average_on_busiest_hour),
                    trend: fullData.trend,
                    
                    best_product_zone_by_visitors: fullData.best_product_zone_by_visitors,
                    best_product_zone_by_avg_duration: fullData.best_product_zone_by_avg_duration,
                    worst_product_zone_by_visitors: fullData.worst_product_zone_by_visitors,
                    total_duration_per_product_zone: fullData.total_duration_per_product_zone,
                    percentage_by_duration_range_in_product_zone: fullData.percentage_by_duration_range_in_product_zone,

                    best_demo_zone_by_visitors: fullData.best_demo_zone_by_visitors,
                    best_demo_zone_by_avg_duration: fullData.best_demo_zone_by_avg_duration,
                    worst_demo_zone_by_visitors: fullData.worst_demo_zone_by_visitors,
                    total_duration_per_demo_zone: fullData.total_duration_per_demo_zone,
                    percentage_by_duration_range_in_demo_zone: fullData.percentage_by_duration_range_in_demo_zone,

                    best_display_zone_by_visitors: fullData.best_display_zone_by_visitors,
                    best_display_zone_by_avg_duration: fullData.best_display_zone_by_avg_duration,
                    worst_display_zone_by_visitors: fullData.worst_display_zone_by_visitors,
                    total_duration_per_display_zone: fullData.total_duration_per_display_zone,
                    percentage_by_duration_range_in_display_zone: fullData.percentage_by_duration_range_in_display_zone,

                    average_waiting_time: fullData.average_waiting_time,
                    max_waiting_duration: fullData.max_waiting_duration,
                    max_waiting_zone: fullData.max_waiting_zone,
                    most_typical_waiting_time: fullData.most_typical_waiting_time,
                    average_interaction_time: fullData.average_interaction_time,
                    max_interaction_duration: fullData.max_interaction_duration,
                    max_interaction_zone: fullData.max_interaction_zone,
                    most_typical_interaction_time: fullData.most_typical_interaction_time,
                    fastest_checkout_zone: fullData.fastest_checkout_zone,
                    slowest_checkout_zone: fullData.slowest_checkout_zone,
                    fastest_interaction_zone: fullData.fastest_interaction_zone,
                    slowest_interaction_zone: fullData.slowest_interaction_zone,
                    most_used_interaction_zone: fullData.most_used_interaction_zone,
                    least_used_interaction_zone: fullData.least_used_interaction_zone,
                    
                    date: fullData.last_updated_date // Add today's date to track when the data was fetched
                }

                fixedWidgetData.value = { ...newStoredData }
                console.log(fixedWidgetData.value)

            } catch (error) {
                console.error('Error fetching fixed widget data:', error)
            }
        }

        const handleUpdateDuration = async (newDuration) => {
            selectedDurationFixedWidgetData.value = newDuration
            await fetchFixedWidgetData()
        }


        /*----------------------------------------- Block: Side Bar Chat Agent ------------------------------------------*/

        const toggleSidebar = () => {
            isSidebarOpen.value = !isSidebarOpen.value
        };

        const sendMessage = async () => {
            if (!userInput.value) return

            // Add user message to chat
            messages.value.push({
                id: Date.now(),
                sender: 'You',
                text: userInput.value,
                timestamp: new Date().toLocaleString(),
            })

            let prompt = userInput.value

            // Clear input
            userInput.value = ''
            try {
                // Send query to backend
                const response = await sendMessageToAgent(prompt)

                // Add assistant response to chat
                messages.value.push({
                id: Date.now(),
                sender: 'IntAlytic',
                text: response,
                timestamp: new Date().toLocaleString(),
                })
            } catch (error) {
                messages.value.push({
                id: Date.now(),
                sender: 'IntAlytic',
                text: "Sorry, I couldn't process your request.",
                timestamp: new Date().toLocaleString(),
                })
            }
        }

        /*----------------------------------------- End of Side Bar Chat Agent ------------------------------------------*/


        const changeDuration = (newDuration) => {
            duration.value = newDuration
        }
        
        const changeDateRangeMode = (newMode) => {
            dateRangeMode.value = newMode
        }

        const updateClearDatePicker = (newValue) => {
            clearDatePicker.value = newValue
        }

        const updateDataGranularity = (granularity) => {
            dataGranularity.value = granularity
        }

        onUnmounted(() => {
            controller.abort()
        })

        return {
            CompanyStore,
            changeDuration,
            changeDateRangeMode,
            dateRangeMode,
            updateClearDatePicker,
            clearDatePicker,
            updateDataGranularity,
            dataGranularity,
            handleUpdateDuration,
            fixedWidgetData,
            isSidebarOpen,
            messages,
            userInput,
            toggleSidebar,
            sendMessage,
            isLoading,

        }
    }
})
</script>
  
<style scoped>
.dashboard-wrapper {
    min-height: 100vh; /* Cover full viewport height */
    height: auto; /* Allow it to adjust based on content */
    display: flex;
    flex-direction: column;
    overflow: visible;
}

.container {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    overflow: visible; /* Ensure the container itself does not scroll */
    min-height: 0; /* Allows the container to shrink and expand based on content */
}

.card {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible; /* Ensure the card itself does not scroll */
    min-height: 0; /* Allows the card to shrink and expand based on content */
}

.card-title {
  color: #4F7EB3 !important;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Center content vertically */
    padding: 10px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ccc;
}

.toggle-sidebar {
  background-color: #4f7eb3;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.toggle-sidebar:hover {
  background-color: #b2c149;
}

.close-btn {
  background-color: #4f7eb3;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.close-btn:hover {
  background-color: #b2c149;
  color: #fff;
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  right: -450px;
  width: 450px;
  height: 100vh;
  background-color: white;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: right 0.3s ease;
  z-index: 1000;
  padding-top: 0px;
  margin-top: 0px;
}

.sidebar.open {
  right: 0;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding: 5px;
  background-color: rgba(33, 37, 42, 0.03);
  border-bottom: 1px solid #ccc;
  color: #4f7eb3;
  padding-top: 10px;
}

.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: white;
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
  background-color: rgba(33, 37, 42, 0.03);
}

.chat-input input {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.chat-input button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #4f7eb3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #b2c149;
}

/* Chat Messages */
.user {
  text-align: right;
  margin-bottom: 10px;
}

.assistant {
  text-align: left;
  margin-bottom: 10px;
}

.loader {
    width: 80px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #B2C149;
    --_m: 
        conic-gradient(#0000 10%,#000),
        linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

@media (min-width: 1600px) {
    .container {
        max-width: 1520px;
    }
}

</style>