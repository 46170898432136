<template>
    <div class="chart-widget">
        <canvas ref="barChartCanvas"></canvas>
        <div v-if="isLoading" class="loading-overlay">
            <div class="loader"></div>
        </div>
    </div>
</template>

<script>
import { ref, watch, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { Chart, BarController, BarElement, CategoryScale, LinearScale, Title, Legend, Tooltip } from 'chart.js';

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Title, Legend, Tooltip);

export default {
    name: 'ChartsDashboardPdd',
    props: {
        chartData: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        selectedDuration: {
            type: Number,
            required: true,
        },
        zoneType: {
            type: String,
            required: true
        },
        chartType: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const barChartCanvas = ref(null)
        let barChartInstance = null
        const isLoading = ref(true)

        // Define the color palette
        const colorPalette = [
            '#425461', // Dark Blue
            '#4F7EB3', // Blue
            '#B2C149', // Green
            '#9FC8E3', // Light Blue
            '#6C9046', // Dark Green
            '#6B92C6', // Secondary Blue
        ]

        // Function to get a consistent color for a zone
        const getColorForZone = (zone) => {
            const index = Array.from(zone).reduce((acc, char) => acc + char.charCodeAt(0), 0) % colorPalette.length
            return colorPalette[index]
        }

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        font:
                        {
                            size:10
                        }
                    }
                },
                x: {
                    stacked: false,
                    ticks: {
                        font:
                        {
                            size:10
                        }
                    }
                }
            },
            plugins: {
                legend: {
                    display: false,
                    position: 'top',
                },
                title: {
                    display: true,
                    text: 'Total Store Visitors',
                },
                tooltip: {
                    enable: true
                },
            }
        }

        // Helper function to format dates as DD-MM-YYYY
        const formatDate = (timestamp) => {
            const date = new Date(timestamp)
            const day = String(date.getDate()).padStart(2, '0')
            const month = String(date.getMonth() + 1).padStart(2, '0')
            const year = date.getFullYear()
            return `${day}-${month}-${year}`
        }

        const formatChartData = (data) => {
            // Convert the incoming chartData (object) to Chart.js format
            // Extract labels and values based on chartType and props.zoneType
            const labels = []
            const values = []
            const backgroundColors = []
            let zoneName = ""

            for (const [date, zones] of Object.entries(data)) {
                labels.push(formatDate(date)); // Format the date as DD-MM-YYYY

                // Extract the first key (zone name) and its corresponding value
                const firstZoneName = Object.keys(zones)[0]
                const zoneData = zones[firstZoneName]

                // Set the zoneName (used for chart title)
                if (!zoneName) zoneName = firstZoneName

                // Push the appropriate value based on chartType
                if (props.chartType === 'AvgDur') {
                    values.push(zoneData?.average_duration || 0)
                } else if (props.chartType === 'Counts') {
                    values.push(zoneData?.zone_in_count || 0)
                }

                // Determine the background color for the zone
                backgroundColors.push(getColorForZone(firstZoneName))
            }

            // Update chart title dynamically
            options.plugins.title.text =
                props.chartType === 'AvgDur'
                    ? `Top Average Duration ${props.zoneType} Zone: ${zoneName}`
                    : `Top Visitors ${props.zoneType} Zone: ${zoneName}`

            return {
                labels, // Dates
                datasets: [
                    {
                        label:
                            props.chartType === 'AvgDur'
                                ? 'Average Duration (minutes)'
                                : 'Visitor Count',
                        data: values, // Extracted values
                        backgroundColor: backgroundColors, // Bar color
                        borderColor: backgroundColors, // Bar border color
                        borderWidth: 1,
                    },
                ],
            }
        }

        const initializeChart = () => {
            // isLoading.value = true;
            if (barChartCanvas.value) {
                const formattedData = formatChartData(props.chartData);

                barChartInstance = new Chart(barChartCanvas.value, {
                    type: 'bar',
                    data: formattedData,
                    options,
                });
            }
        };

        const resizeChart = () => {
            if (barChartInstance && barChartCanvas.value) {
                const parent = barChartCanvas.value.parentNode;
                barChartCanvas.value.width = parent.clientWidth;
                barChartCanvas.value.height = parent.clientHeight;
                barChartInstance.resize();
            }
        };

        const updateChartData = (newData) => {
            if (barChartInstance) {
                const formattedData = formatChartData(newData);

                // Update labels and datasets
                barChartInstance.data.labels = formattedData.labels;
                barChartInstance.data.datasets = formattedData.datasets;

                barChartInstance.update('none'); // Minimize redraw animation
            }

            isLoading.value = false
        };

        // Watch for changes in chartData and update the chart accordingly
        watch(
            () => props.chartData,
            (newData) => {
                isLoading.value = true; // Start loader
                nextTick(() => {
                    updateChartData(newData);
                });
            },
            { deep: true }
        );

        watch( () => props.selectedDuration, (newData) => {
            if (newData) {
                isLoading.value = true
            }
        })

        onMounted(() => {
            nextTick(() => {
                initializeChart();
                window.addEventListener('resize', resizeChart);
            });
        });

        onBeforeUnmount(() => {
            if (barChartInstance) {
                barChartInstance.destroy();
                barChartInstance = null;
            }
            window.removeEventListener('resize', resizeChart);
        });

        return {
            barChartCanvas,
            resizeChart,
            isLoading,
        };
    },
};
</script>

<style scoped>
.chart-widget {
    position: relative;
    width: 100%;
    height: 100%;
}

.chart-widget canvas {
    display: block;
  width: 100%;
  height: 100%;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

.loader {
  width: 20px;
  padding: 2px;
  margin-top: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: spinner 1s infinite linear;
}

@keyframes spinner {
  to {
    transform: rotate(1turn);
  }
}
</style>