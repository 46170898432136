<template>

    <div v-for="(zoneDetails, zoneName) in zoneTotalCounts" :key="zoneName" class="card counter-card">
        <div v-if="zoneDetails.zone_type == 'Combined Entrance Exit Zone'">
            <div class="card-header counter-card-header">
                <h6 class="card-title counter-card-title" style="font-size: small;">
                    Total in <span :style="{ color: getColorForZone(zoneDetails.zone_type) }">{{ zoneName }}</span>
                    <br>
                    <span style="font-size: xx-small;">{{ durationText }}</span>
                </h6>
            </div>
            <div class="card-body combined-count">
                <!-- Store IN Counter -->
                <div>
                    <div v-if="isLoading">
                        <div class="loader"></div>
                    </div>
                    <p v-else style="font-size: small">
                        In Store: <b>{{ storeTotalCounts[zoneName]?.zone_in_count || 0 }}</b>
                    </p>
                </div>
                <!-- Store OUT Counter -->
                <div>
                    <div v-if="isLoading">
                        <div class="loader"></div>
                    </div>
                    <p v-else style="font-size: small">
                        Out Store: <b>{{ storeTotalCounts[zoneName]?.zone_out_count || 0 }}</b>
                    </p>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="card-header counter-card-header">
                <h6 class="card-title counter-card-title" style="font-size: small;">
                    Total in <span :style="{ color: getColorForZone(zoneDetails.zone_type) }">{{ zoneName }}</span>
                    <br>
                    <span style="font-size: xx-small;">{{ durationText }}</span>
                </h6>
            </div>
            <div class="card-body count">
                <div v-if="isLoading">
                    <div class="loader"></div>
                </div>
                <p v-else style="font-size: small">
                    <b>{{ zoneDetails.zone_in }}</b>
                </p>
            </div>
        </div>
    </div>

    <div class="flex-break"></div>

    <div v-for="(zoneDetails, zoneName) in zoneAverageTime" :key="zoneName" class="card counter-card">
        <div class="card-header counter-card-header">
            <h6 class="card-title counter-card-title" style="font-size: small;">
                Average Time in <span :style="{ color: getColorForZone(zoneDetails.zone_type) }">{{ zoneName }}</span>
                <br>
                <span style="font-size: xx-small;">{{ durationText }}</span>
            </h6>
        </div>
        <div class="card-body count">
            <div v-if="isLoading">
                <div class="loader"></div>
            </div>
            <p v-else style="font-size: small"><b>{{ formattedAverageDurationTime(zoneDetails.average_duration) }}</b></p>
        </div>
    </div>
    
</template>

<script>
import { ref, onMounted, onUnmounted, watch, toRefs, computed } from 'vue'
import { useRoute } from 'vue-router'

import { detection_zone_colors_by_name_solid } from '@/constants.js'

import { useFlowStore } from '@/stores/FlowStore'

import { getCurrentCountersByFlowId, getCountersByDuration, getCountersByDateRange, } from "@/api/eventsAPI.js"

export default {
    name: 'Counters',

    components: {
        
    },

    props: {
        flowId: Number,
        startDate: Object,
        endDate: Object,
        duration: Number,
        isTodaySelected: Boolean,
        dateRangeMode: String,
        minimumDurationThreshold: Number
    },

    setup(props, { emit }) {
        const flowStore = useFlowStore()

        const route = useRoute()

        const detectionZoneNames = ref({})
        const zoneAverageTime = ref({})
        const zoneTotalCounts = ref({})
        const storeTotalCounts = ref({})

        const isLoading = ref(true)
        const isMounted = ref(true)
        let currentFetchController = null
        let latestRequestId = 0

        const { flowId, duration, isTodaySelected, startDate, endDate } = toRefs(props)

        const localDateRangeMode = ref(props.dateRangeMode)

        let fetchIntervalCounterData = null

        const stopInterval = () => {
            if (fetchIntervalCounterData) {
                clearInterval(fetchIntervalCounterData)
                fetchIntervalCounterData = null
            }
        }

        const startInterval = () => {
            if (isTodaySelected.value) { // Only start interval if isTodaySelected is true
                stopInterval() // Ensure any existing interval is cleared
                fetchIntervalCounterData = setInterval(refreshData, 5000) // Refresh every 5 seconds
            }
        }

        const refreshData = () => {
            if (!isMounted.value) return

            stopInterval(); // Stop the interval before making a new API call

            if (localDateRangeMode.value === 'duration') {
                fetchCountersByDuration()
            } else if (localDateRangeMode.value === 'dateRange') {
                fetchCountersByDateRange()
            }
        }

        const debounce = (func, wait) => {
            let timeOut
            return (...args) => {
                clearTimeout(timeOut)
                timeOut = setTimeout(() => func.apply(this, args), wait)
            }
        }

        const initializeZoneTotalCounts = () => {
            zoneTotalCounts.value = Object.keys(detectionZoneNames.value).reduce((acc, key) => {
                acc[key] = {
                zone_type: detectionZoneNames.value[key]['type'],
                zone_in: 0
                }
                return acc
            }, {})
        }

        const initializeZoneAverageTime = () => {
            zoneAverageTime.value = Object.keys(detectionZoneNames.value).reduce((acc, key) => {
                acc[key] = {
                zone_type: detectionZoneNames.value[key]['type'],
                average_duration: 0
                }
                return acc
            }, {})
        }

        onMounted(async() => {
            isMounted.value = true

            isLoading.value = true

            await flowStore.fetchCurrentFlow(parseInt(route.params.id))
            
            detectionZoneNames.value = flowStore.currentFlow.DetectionZone
            
            initializeZoneTotalCounts()
            initializeZoneAverageTime()
            
            refreshData()  // Fetch data when component is mounted
            
            startInterval() // Set up an interval to keep fetching data
        })
        
        watch(duration, debounce((newValue) => {
            if (newValue) {
                isLoading.value = true
                stopInterval() // Stop the interval before making a new API call
                refreshData()
            }
        }, 300))

        watch(() => props.dateRangeMode, (newValue) => {
            localDateRangeMode.value = newValue
        })

        watch(localDateRangeMode, debounce((newValue) => {
            if (newValue) {
                isLoading.value = true
                stopInterval() // Stop the interval before making a new API call
                refreshData()
            }
        }, 300))

        watch(() => isTodaySelected.value, (newValue) => {
            if (newValue) {
                // Start interval for "Today's Real-Time Data"
                localDateRangeMode.value = 'duration'
                isLoading.value = true
                startInterval()
            } else {
                // Fetch data once for "Historical Data" and stop the interval
                isLoading.value = true
                stopInterval()
            }
            refreshData()
        })

        watch(() => props.minimumDurationThreshold, debounce((newValue) => {
            if (newValue || newValue === 0) { // Handle zero and other values
                isLoading.value = true
                stopInterval(); // Stop the interval before making a new API call
                refreshData()
            }
        }, 300))

        watch(([startDate, endDate]), debounce ((newValues, prevValues) => {
            if (newValues.every(value => value !== null)) { // Both dates are selected
                isLoading.value = true
                stopInterval() // Stop the interval before making a new API call
                refreshData()
            }
        }, 300))
        
        const fetchCountersByDuration = async () => {
            if (currentFetchController) {
                currentFetchController.abort(); // Abort the previous request
            }

            const controller = new AbortController()
            currentFetchController = controller // Update the current controller
            const requestId = ++latestRequestId


            if (flowId.value) {
                try {
                    if (controller.signal.aborted || !isMounted.value) {
                        return  // Abort early if the signal is already aborted
                    }
                    
                    let counts

                    if (isTodaySelected.value) {
                        counts = await getCurrentCountersByFlowId(flowId.value, { signal: controller.signal })
                    } else {
                        counts = await getCountersByDuration(flowId.value, duration.value, { signal: controller.signal })
                    }

                    if (isMounted.value && requestId === latestRequestId) {  // Check for the latest request) {
                        processZoneCounts(counts)
                        isLoading.value = false
                        startInterval() // Restart the interval after the API call is complete
                    }
                } catch (error) {
                    isLoading.value = false
                    if (error.name === 'AbortError') {
                        console.log('Fetch aborted');
                    } else {
                        console.error('Error fetching event data:', error)
                    }
                    startInterval() // Restart the interval even if there is an error  
                }
            }
        }

        const fetchCountersByDateRange = async () => {
            if (currentFetchController) {
                currentFetchController.abort(); // Abort the previous request
            }

            const controller = new AbortController()
            currentFetchController = controller // Update the current controller
            const requestId = ++latestRequestId

            if (startDate.value && endDate.value && flowId.value) {
                try {
                    if (controller.signal.aborted || !isMounted.value) {
                        return  // Abort early if the signal is already aborted
                    }

                    let counts = await getCountersByDateRange(flowId.value, startDate.value, endDate.value, { signal: controller.signal })
                    
                    if (isMounted.value && requestId === latestRequestId) {  // Check for the latest request) {
                        processZoneCounts(counts)
                        isLoading.value = false
                    }
                } catch (error) {
                    isLoading.value = false
                    if (error.name === 'AbortError') {
                        console.log('Fetch aborted');
                    } else {
                        console.error('Error fetching event data:', error)
                    }
                }
            }
        }

        const processZoneCounts = (counts) => {
            if (!isMounted.value) return

            const counters = counts.counters_data?.counters || counters_data?.counters
            const storeCounters = counts.counters_data?.store_counters || counters_data?.store_counters

            // Update zoneAverageTime and zoneTotalCounts
            for (let key in zoneAverageTime.value) {
                if (counters.hasOwnProperty(key)) {
                    zoneAverageTime.value[key].average_duration = counters[key].average_duration
                    zoneTotalCounts.value[key].zone_in = counters[key].zone_in_count
                    zoneTotalCounts.value[key].zone_type = counters[key].zone_type
                } else {
                    zoneAverageTime.value[key].average_duration = 0
                    zoneTotalCounts.value[key].zone_in = 0
                }
            }

            // Update storeTotalCounts for Combined Entrance Exit Zones
            storeTotalCounts.value = {}; // Ensure storeTotalCounts is reset
            if (storeCounters) {
                for (let key in storeCounters) {
                    storeTotalCounts.value[key] = {
                        zone_in_count: storeCounters[key].zone_in_count || 0,
                        zone_out_count: storeCounters[key].zone_out_count || 0,
                        zone_type: storeCounters[key].zone_type || '',
                    }
                }
            }
        }
        
        const getColorForZone = (zoneType) => {
            return detection_zone_colors_by_name_solid[zoneType] || 'black'
        }

        const durationText = computed(() => {
            if(isTodaySelected.value){
                return 'Today'
            }
            else if (localDateRangeMode.value === 'duration') {
                return duration.value === 1 ? 'Yesterday' : `Last ${duration.value} Days`
            } else if (localDateRangeMode.value === 'dateRange') {
                const startDateFormatted = new Date(startDate.value).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
                const endDateFormatted = new Date(endDate.value).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
                return `From ${startDateFormatted} To ${endDateFormatted}`
            }
        })

        const formattedAverageDurationTime = (seconds) => {
            if (seconds < 60) {
                return `${seconds.toFixed(0)} sec`
            } else if (seconds < 3600) {
                const minutes = Math.floor(seconds / 60)
                const remainingSeconds = seconds % 60
                return `${minutes} min ${remainingSeconds.toFixed(0)} sec`
            } else {
                const hours = Math.floor(seconds / 3600)
                const remainingMinutes = Math.floor((seconds % 3600) / 60)
                const remainingSeconds = seconds % 60
                return `${hours} h ${remainingMinutes} min ${remainingSeconds.toFixed(0)} sec`
            }
        }

        onUnmounted(() => {
            isMounted.value = false

            stopInterval(); // Stop the interval
            if (currentFetchController) {
                currentFetchController.abort(); // Abort any ongoing fetch request
            }
        })

        return {
            getColorForZone,
            zoneAverageTime,
            zoneTotalCounts,
            storeTotalCounts,
            isLoading,
            durationText,
            formattedAverageDurationTime,
        }
    
    }
}

</script>

<style scoped>

.flex-break {
    flex-basis: 100%; /* Forces a new line */
    height: 0; /* No height */
}

.combined-count {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    gap: 5px; /* Space between Store IN and Store OUT sections */
}

.counter-card {
    flex: 1 1 200px; /* Fixed width for each counter card */
    box-sizing: border-box;
    min-width: 200px;
    max-height: 65px;
    margin-top: 0px;
}

.counter-card-header {
    padding: 2px;
    text-align: center;
}

.counter-card-title {
    margin: 0; /* Remove default margin */
    font-size: small;
    color: #000000 !important
}

.count {
    display: flex;
    justify-content: center;
    height: 30px;
    padding: 5px; /* Add padding to reduce gap */
}

.loader {
  width: 20px;
  padding: 2px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

</style>