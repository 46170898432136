<template>

  <!-- Conditional Sidebar -->
  <component
    :is="currentSidebar"
    :userId="userStore.user.UserId"
    :userName="userStore.user.UserName"
    :userSurname="userStore.user.UserSurname"
    :userRole="userStore.user.UserRole"
    :companyId="companyStore.company.CompanyId"
    :companyName="companyStore.company.CompanyName"
    :subscriptionType="companyStore.company.SubscriptionType"
  />

  <!-- Page content -->
  <div id="app" class="content">
    <RouterView :key="$route.params.id" />
  </div>

</template>

<script>
import { computed } from 'vue'
import { RouterView, useRoute } from 'vue-router'

import { useUserStore } from '@/stores/UserStore'
import { useCompanyStore } from '@/stores/CompanyStore'
import NavSidebar from './components/NavSidebar.vue'
import StoralyticDemoNavSidebar from "./components/StoralyticDemoNavSidebar.vue"

export default {
  components: {
    NavSidebar,
    RouterView,
    StoralyticDemoNavSidebar
  },
  
  setup() {
    const route = useRoute();

    // Dynamically determine if it's the "/storalytic_demo_store" path
    const isDemoStoreRoute = computed(() => route.path === "/storalytic_demo_store");

    // Only initialize userStore and companyStore when not in the demo store route
    const userStore = !isDemoStoreRoute.value ? useUserStore() : null;
    const companyStore = !isDemoStoreRoute.value ? useCompanyStore() : null;

    // Dynamically determine which sidebar to show based on the current route
    const currentSidebar = computed(() => {
        return isDemoStoreRoute.value ? "StoralyticDemoNavSidebar" : "NavSidebar";
    });

    // Conditionally return stores based on the route
    return {
        ...(userStore && { userStore }),
        ...(companyStore && { companyStore }),
        currentSidebar,
    };
  }
}
</script>

<style scoped>
/* Page content adjustments */
.content {
  margin-left: 250px; /* Adjust based on sidebar width */
  padding: 20px;
  width: calc(100% - 250px);
  margin-top: 50px;
}

@media (max-width: 768px) {
  .content {
    margin-left: 0px;
    width: 100%;
  }
}
</style>