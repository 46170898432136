<template>
    <div>
        <div class="container">
            <div class="chart-wrapper">
                <div class="chart-container">
                    <BarChart :chartData="chartDataCountsInOut" :options="chartOptionsCounts"/>
                    <BarChart :chartData="chartDataAverageInteractionTime" :options="chartOptionsAverageTime" />
                    <div v-if="isLoading" class="loading-overlay">
                        <div class="loader" style="margin-top: 50px;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch, toRefs } from 'vue'

import { detection_zone_colors_by_name_solid } from '@/constants.js'

import { BarChart } from 'vue-chart-3'
import { Chart, BarController, BarElement, CategoryScale, LinearScale, Title, Legend, Tooltip } from 'chart.js'

// Register the components going to use
Chart.register(BarController, BarElement, CategoryScale, LinearScale, Title, Legend, Tooltip)

import { getCurrentCountersListByFlowId, getCountersListByDuration, getcountersListByDateRange } from "@/api/eventsAPI.js"

export default {
    name: 'Chart',

    components: {
        BarChart,
    },

    props: {
        flowId: Number,
        startDate: Object,
        endDate: Object,
        dataGranularity: String,
        duration: Number,
        isTodaySelected: Boolean,
        dateRangeMode: String,
        minimumDurationThreshold: Number
    },

    setup(props, { emit }) {
        const chartData = ref({})
        const chartDataCountsInOut = ref({})
        const chartDataAverageInteractionTime = ref({})

        const isLoading = ref(false)

        let fetchIntervalChartData = null
        const isMounted = ref(true)
        let currentFetchController = null
        let latestRequestId = 0

        const { flowId, duration, isTodaySelected, dataGranularity, startDate, endDate, minimumDurationThreshold } = toRefs(props)

        const localDateRangeMode = ref(props.dateRangeMode)

        const today = ref(new Date())
        
        const stopInterval = () => {
            if (fetchIntervalChartData) {
                clearInterval(fetchIntervalChartData)
                fetchIntervalChartData = null
            }
        }

        const startInterval = () => {
            if (isTodaySelected.value) { // Only start interval if isTodaySelected is true
                stopInterval()
                fetchIntervalChartData = setInterval(refreshData, 5000)  // Refresh every 5 seconds
            }
        }

        const refreshData = () => {
            if (!isMounted.value) return;

            stopInterval();  // Stop the interval before making a new API call

            if (localDateRangeMode.value === 'duration') {
                fetchEventDataByDuration()
            } else if (localDateRangeMode.value === 'dateRange') {
                fetchEventDataByDateRange()
            }
        }

        const debounce = (func, wait) => {
            let timeOut
            return (...args) => {
                clearTimeout(timeOut)
                timeOut = setTimeout(() => func.apply(this, args), wait)
            }
        }

        onMounted(() => {
            isMounted.value = true
            
            isLoading.value = true

            refreshData()  // Fetch data when component is mounted
            startInterval() // Set up an interval to keep fetching data
        })

        onUnmounted(() => {
            isMounted.value = false  // Mark component as unmounted

            stopInterval(); // Stop the interval
            if (currentFetchController) {
                currentFetchController.abort() // Abort any ongoing fetch request
            }
        })


        watch(duration, debounce((newValue) => {
            if (newValue) {
                isLoading.value = true
                stopInterval() // Stop the interval before making a new API call
                refreshData()
            }
        }, 300))

        watch(() => props.dateRangeMode, (newValue) => {
            localDateRangeMode.value = newValue
        })

        watch(localDateRangeMode, debounce((newValue) => {
            if (newValue) {
                isLoading.value = true
                stopInterval() // Stop the interval before making a new API call
                refreshData()
            }
        }, 300))

        watch(() => isTodaySelected.value, (newValue) => {
            if (newValue) {
                // Start interval for "Today's Real-Time Data"
                localDateRangeMode.value = 'duration'
                isLoading.value = true
                startInterval()
            } else {
                // Fetch data once for "Historical Data" and stop the interval
                isLoading.value = true
                stopInterval()
            }
            refreshData()
        })

        watch(() => props.minimumDurationThreshold, debounce((newValue) => {
            if (newValue || newValue === 0) {
                isLoading.value = true
                stopInterval() // Stop the interval before making a new API call
                refreshData()
            }
        }, 300));

        watch(() => dataGranularity.value, debounce((newValue) => {
            if (newValue) {
                isLoading.value = true
                stopInterval() // Stop the interval before making a new API call
                refreshData()
            }
        }, 300));

        watch(([startDate, endDate]), debounce ((newValues, prevValues) => {
            if (newValues.every(value => value !== null)) { // Both dates are selected
                isLoading.value = true
                stopInterval() // Stop the interval before making a new API call
                refreshData()
            }
        }, 300))


        const fetchEventDataByDuration = async () => {
            if (currentFetchController) {
                currentFetchController.abort(); // Abort the previous request
            }

            const controller = new AbortController()
            currentFetchController = controller // Update the current controller
            const requestId = ++latestRequestId

            if (flowId.value) {
                try {
                    if (controller.signal.aborted || !isMounted.value) {
                        return  // Abort early if the signal is already aborted
                    }
                    
                    let result
                    
                    if (isTodaySelected.value) {
                        result = await getCurrentCountersListByFlowId(flowId.value, dataGranularity.value)
                    } else {
                        result = await getCountersListByDuration(flowId.value, duration.value, dataGranularity.value, { signal: controller.signal })
                    } 
                    if (isMounted.value && requestId === latestRequestId) {  // Check for the latest request
                        createCountsChart(result)
                        createAverageInteractionChart(result)
                        isLoading.value = false
                        startInterval() // Restart the interval after the API call is complete
                    }
                } catch (error) {
                    isLoading.value = false
                    if (error.name === 'AbortError') {
                        console.log('Fetch aborted');
                    } else {
                        console.error('Error fetching event data:', error)
                    }
                    startInterval() // Restart the interval even if there is an error
                }
            }
        }

        // New method to fetch data based on the date range
        const fetchEventDataByDateRange = async () => {
            if (currentFetchController) {
                currentFetchController.abort(); // Abort the previous request
            }

            const controller = new AbortController()
            currentFetchController = controller // Update the current controller
            const requestId = ++latestRequestId

            //Check if both dates are selected and flowId is available
            if (startDate.value && endDate.value && flowId.value) {
                try {
                    if (controller.signal.aborted || !isMounted.value) {
                        return  // Abort early if the signal is already aborted
                    }
                    let result = await getcountersListByDateRange(flowId.value, startDate.value, endDate.value, dataGranularity.value, { signal: controller.signal })

                    if (isMounted.value && requestId === latestRequestId) {
                        createCountsChart(result)
                        createAverageInteractionChart(result)
                        isLoading.value = false
                    }
                } catch (error) {
                    isLoading.value = false
                    if (error.name === 'AbortError') {
                        console.log('Fetch aborted');
                    } else {
                        console.error('Error fetching event data:', error)
                    }
                }
            }
        }

        const createLabels = (keys) => {
            let originalLabels = []
            let displayLabels = []

            if (dataGranularity.value === 'daily') {
                // Daily format: YYYY-MM-DD
                originalLabels = keys;
                displayLabels = keys.map((date) => {
                    const [year, month, day] = date.split('-')
                    return `${day}-${month}-${year}` // Convert to DD-MM-YYYY for display
                })
            } else if (dataGranularity.value === 'hourly') {
                // Hourly format: YYYY-MM-DD HH:mm:ss
                originalLabels = keys
                displayLabels = keys.map((dateTime) => {
                    const [date, time] = dateTime.split(' ')
                    const [year, month, day] = date.split('-')
                    const [hour] = time.split(':') // Extract the hour
                    return `${day}-${month}-${year}: ${hour}u` // Convert to DD-MM-YYYY h for display
                })
            }

            return { originalLabels, displayLabels }
        };

        const createCountsChart = async (result) => {
            if (!isMounted.value) return

            const counters = result.counters_data.counters_list
            const storeCounters = result.counters_data.store_counters_list

            const { originalLabels, displayLabels } = createLabels(Object.keys(counters))

            // Extract detection zone names and types
            const detectionZones = new Set()
            const zoneTypes = {}

            originalLabels.forEach((date) => {
                Object.keys(counters[date]).forEach((zone) => {
                    // Skip zones if they are in store_counters_list
                    if (!storeCounters[date]?.[zone]) {
                        detectionZones.add(zone)
                        zoneTypes[zone] = counters[date][zone].zone_type
                    }
                })
                if (storeCounters[date]) {
                    Object.keys(storeCounters[date]).forEach((zone) => {
                        detectionZones.add(`${zone} Store IN`)
                        detectionZones.add(`${zone} Store OUT`)
                        zoneTypes[`${zone} Store IN`] = storeCounters[date][zone].zone_type
                        zoneTypes[`${zone} Store OUT`] = storeCounters[date][zone].zone_type
                    });
                }
            });

            const detectionZoneArray = Array.from(detectionZones).sort()

            // Prepare datasets
            const datasets = detectionZoneArray.map((zone) => {
                const zoneType = zoneTypes[zone];
                const data = originalLabels.map((date) => {
                    if (zone.endsWith('Store IN')) {
                        const originalZone = zone.replace(' Store IN', '')
                        return storeCounters[date]?.[originalZone]?.zone_in_count || 0
                    } else if (zone.endsWith('Store OUT')) {
                        const originalZone = zone.replace(' Store OUT', '')
                        return storeCounters[date]?.[originalZone]?.zone_out_count || 0
                    } else if (storeCounters[date]?.[zone]) {
                        // Use `store_counters_list` if present
                        return storeCounters[date][zone].zone_in_count || 0
                    } else {
                        // Fallback to `counters_list`
                        return counters[date]?.[zone]?.zone_in_count || 0
                    }
                })
                return {
                    label: zone,
                    data: data,
                    backgroundColor: detection_zone_colors_by_name_solid[zoneType] || '#000000',
                }
            })

            // Chart data for detection zones
            chartDataCountsInOut.value = {
                labels: displayLabels,
                datasets: datasets,
            }
        }

        const createAverageInteractionChart = async (result) => {
            if (!isMounted.value) return

            const counters = result.counters_data.counters_list

            const { originalLabels, displayLabels } = createLabels(Object.keys(counters))

            // Extract detection zone names and types
            const detectionZones = new Set()
            const zoneTypes = {}

            originalLabels.forEach((date) => {
                Object.keys(counters[date]).forEach((zone) => {
                    detectionZones.add(zone)
                    zoneTypes[zone] = counters[date][zone].zone_type
                })
            })

            const detectionZoneArray = Array.from(detectionZones).sort()

            // Prepare datasets
            const datasets = detectionZoneArray.map((zone) => {
                const zoneType = zoneTypes[zone]
                const data = originalLabels.map((date) => counters[date][zone]?.average_duration || 0)
                return {
                    label: zone,
                    data: data,
                    backgroundColor: detection_zone_colors_by_name_solid[zoneType] || '#000000',
                }
            })

            // Chart data for detection zones
            chartDataAverageInteractionTime.value = {
                labels: displayLabels,
                datasets: datasets,
            }
        }

        const chartOptionsCounts = {
            responsive: true,  // Makes the chart responsive
            maintainAspectRatio: false,  // Control the aspect ratio
            scales: {
                y: {
                    beginAtZero: true  // Start the Y axis at 0
                },
                x: {
                    stacked: false // Group bars in case of multi-class. For stacked bar chart, set it to true.
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top',  // Position of the legend
                },
                title: {
                    display: true,
                    text: 'Total Zone In (#)',
                },
                tooltip: {
                    enable: true
                },
            }
        }

        const chartOptionsAverageTime = {
            responsive: true,  // Makes the chart responsive
            maintainAspectRatio: false,  // Control the aspect ratio
            scales: {
                y: {
                    beginAtZero: true,  // Start the Y axis at 0
                },
                x: {
                    stacked: false // Group bars in case of multi-class. For stacked bar chart, set it to true.
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top',  // Position of the legend
                },
                title: {
                    display: true,
                    text: 'Average Time In Zone (sec)',
                },
                tooltip: {
                    enable: true
                },
            }
        }


        return {
            chartData,
            chartDataCountsInOut,
            chartDataAverageInteractionTime,
            chartOptionsCounts,
            chartOptionsAverageTime,
            flowId,
            startDate,
            endDate,
            today,
            dataGranularity,
            duration,
            isLoading
        }

    }
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.chart-wrapper {
  position: relative;
  width: 100%;
}

.chart-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10; /* Ensure it is above other content */
}

.loader {
  width: 80px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

</style>