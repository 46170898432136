<template>
  <div class="heatmap-widget">
    <canvas ref="heatmapCanvas"></canvas>
    <div v-if="isLoading" class="loading-overlay">
      <div class="loader"></div>
    </div>
    <div v-else-if="highestValue === 0" class="heatmap-overlay">
      <div>
        <div v-if="isLoading" class="loading-overlay">
          <div class="loader"></div>
        </div>  
        <p v-else class="no-data-message">
          No entrance heatmap data available
        </p>
      </div>
    </div>
  </div>
</template>
  
<script>
import { ref, onMounted, onBeforeUnmount, watch, computed } from 'vue';
import { Chart } from 'chart.js';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';
import { color } from 'chart.js/helpers'

Chart.register(MatrixController, MatrixElement);

export default {
  name: 'HeatmapDashboard',
  
  props: {
    heatmapData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    selectedDuration: {
      type: Number,
      required: true,
    }
  },
    
  setup(props) {
    const heatmapCanvas = ref(null)
    let heatmapChart = null
    const highestValue = ref(0)
    const isLoading = ref(true)
    const screenWidth = ref(window.innerWidth)

    const currentDayLabels = computed(() =>
      screenWidth.value < 800
        ? ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] // Abbreviated labels
        : ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] // Full labels
    )
  
    const formatHeatmapData = () => {
      if (!props.heatmapData.heatmap_matrix || Object.keys(props.heatmapData.heatmap_matrix).length === 0) {
        return [] // Return an empty array if data is not ready
      }

      // Define the correct order of the days
      const dayOrder = currentDayLabels.value

      const formattedData = []
      props.heatmapData.heatmap_matrix.forEach((dayValues, dayIndex) => {
        const day = dayOrder[dayIndex]
        dayValues.forEach((value, hourOffset) => {
          const actualHour = hourOffset + props.heatmapData.minHour
          formattedData.push({
            x: day,
            y: `${actualHour.toString().padStart(2, '0')}:00`,
            d: `${day} ${actualHour.toString().padStart(2, '0')}:00`,
            v: value,
          })
        })
      })

        return formattedData
    }
  
    const scales = {
      y: {
        type: 'category',
        left: 'left',
        offset: true,
        labels: (() => {
          const hours = [];
          const totalHours = props.heatmapData.maxHour - props.heatmapData.minHour + 1;
          for (let i = 0; i < totalHours; i++) {
            const hour = (props.heatmapData.minHour + i) % 24; // Wrap around midnight
            hours.push(`${hour.toString().padStart(2, '0')}:00`);
          }
          return hours;
        })(),
        ticks: { font: {size:11}},
        grid: {
          display: false,
          drawBorder: false,
          tickLength: 0,
        },
        title: {
          display: true,
          font: { size: 12, weight: 'bold' },
          text: 'Hours',
          padding: 0,
        },
      },
      x: {
        type: 'category',
        position: 'top',
        offset: true,
        labels: currentDayLabels.value,
        ticks: {
          padding: 0,
          maxRotation: 0,
          minRotation: 0,
          autoSkip: false,
          font: {size:11}
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        title: {
            display: true,
            font: { size: 12, weight: 'bold' },
            text: 'Days of the Week',
        },
      },
    }
  
    const options = {
      maintainAspectRatio: false,  // Disable Chart.js aspect ratio management
      responsive: true,
      plugins: {
        legend: { display: false },
        tooltip: {
          displayColors: false,
          callbacks: {
            title() {
              return ''
            },
            label(context) {
              const v = context.dataset.data[context.dataIndex]
              return [`d: ${v.d}`, `v: ${v.v.toFixed(0)}`]
            },
          },
        },
      },
      scales: scales,
      layout: {
        padding: {
          top: 10,
        },
      },
    }
      
    const data = {
      datasets: [{
        data: formatHeatmapData(),
        
        backgroundColor({ raw }) {
          if (raw && typeof raw.v !== 'undefined') {
            //const alpha = (10 + raw.v) / (highestValue.value)
            const scaleFactor = 10; // Adjust as needed
            const adjustedValue = raw.v * scaleFactor
            const alpha = (10 + adjustedValue) / (highestValue.value * scaleFactor)
            
            const baseColor = 'rgba(66, 84, 97)'
            
            // Apply calculated transparency (alpha) to the base color
            return color(baseColor).alpha(alpha).rgbString()

          }
          return color('rgba(66, 84, 97)').alpha(0.2).rgbString() // Fallback color
        },

        borderColor({ raw }) {
          if (raw && typeof raw.v !== 'undefined') {
            const alpha = (10 + raw.v) / 60;
            
            const baseColor = 'rgba(66, 84, 97)';
            
            // Apply calculated transparency (alpha) to the base color
            return color(baseColor).alpha(alpha).darken(0.3).rgbString()

          }
          return color('rgba(66, 84, 97)').alpha(0.2).darken(0.3).rgbString() // Fallback color
        },
        borderWidth: 1,
        hoverBackgroundColor: 'black',
        hoverBorderColor: 'black',
        width: ({ chart }) => (chart.chartArea || {}).width / chart.scales.x.ticks.length - 3,
        height: ({ chart }) => (chart.chartArea || {}).height / chart.scales.y.ticks.length - 3,
      }],
    }

  
    const renderHeatmap = () => {
      if (heatmapChart) {
        // If the chart already exists, just update the data
        heatmapChart.data.datasets[0].data = formatHeatmapData()
        heatmapChart.options.scales.x.labels = [...currentDayLabels.value]
        heatmapChart.update({
          duration: 0,  // Disable the animation
        })
      } else {
        // If the chart doesn't exist, create it
        const ctx = heatmapCanvas.value.getContext('2d')

        const config = {
          type: 'matrix',
          data: data,
          options: options,
        }

        heatmapChart = new Chart(ctx, config)
      }

      // Add the 'loaded' class to the canvas once rendering is complete
      if (heatmapCanvas.value) {
        heatmapCanvas.value.classList.add('loaded')
      }

      isLoading.value = false
    }

    const resizeChart = () => {
      if (heatmapChart) {
        const parent = heatmapCanvas.value.parentNode
        heatmapCanvas.value.width = parent.clientWidth
        heatmapCanvas.value.height = parent.clientHeight
        heatmapChart.resize()
      }
    }

    watch( () => props.selectedDuration, (newData) => {
      if (newData) {
        isLoading.value = true
      }
    })

    watch(
      () => props.heatmapData,
      (newData) => {
        isLoading.value = true
        if (
          newData &&
          newData.heatmap_matrix &&
          Array.isArray(newData.heatmap_matrix) &&
          newData.heatmap_matrix.length > 0
        ) {
          // Update highest value
          highestValue.value = findHighestValue(newData.heatmap_matrix)

          // Update y-axis labels
          const totalHours =
            newData.maxHour - newData.minHour + 1
          const yAxisLabels = Array.from(
            { length: totalHours },
            (_, i) =>
              `${((i + newData.minHour) % 24).toString().padStart(2, '0')}:00`
          )

          // Update the heatmap data and y-axis labels dynamically
          if (heatmapChart) {
            heatmapChart.options.scales.y.labels = yAxisLabels;
            heatmapChart.data.datasets[0].data = formatHeatmapData()
            heatmapChart.update()
            isLoading.value = false
          } else {
            renderHeatmap()
          }
        }
      },
      { immediate: true, deep: true }
    )

    const findHighestValue = (heatmapData) => {
      if (
        !heatmapData ||
        !Array.isArray(heatmapData) ||
        heatmapData.length === 0
      ) {
        return 0
      }

      return Math.max(
        ...heatmapData.flatMap((dayValues) =>
          dayValues.map((value) => value)
        ),
        0
      )
    }

    const handleResize = () => {
      screenWidth.value = window.innerWidth; // Update screen width
      renderHeatmap(); // Re-render chart with updated labels
    }
  
    onMounted(async () => {
      window.addEventListener('resize', handleResize)

      const stopWatching = watch(
        () => props.heatmapData.heatmap_matrix,
        (newMatrix) => {
          if (newMatrix && newMatrix.length > 0) {
            highestValue.value = findHighestValue(props.heatmapData.heatmap_matrix)

              // Add a small delay before rendering to ensure dimensions stabilize
            setTimeout(() => {
              renderHeatmap();
              isLoading.value = false;
            }, 50)
            
            stopWatching() // Stop watching after initialization
          }
        },
        { immediate: true, deep: true } // Ensure we catch nested changes immediately
      )
    })

    onBeforeUnmount(() => {
      if (heatmapChart) {
        heatmapChart.destroy()
      }
      window.removeEventListener('resize', handleResize)
    })
  
    return {
      heatmapCanvas,
      resizeChart,
      isLoading,
      highestValue
    }
  },
}

</script>

<style scoped>
.heatmap-widget {
  position: relative;
  width: 100%;
  height: 100%;
}

.heatmap-widget canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  transition: opacity 0.2s ease-in-out;
  opacity: 0; /* Start hidden */
}

.heatmap-widget canvas.loaded {
  opacity: 1; /* Show when ready */
}

.heatmap-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  align-items: center;
  background-color: rgba(255, 255, 255);
  z-index: 10;
}

.no-data-message {
    color: gray;
    font-size: 14px; /* Adjust font size to match the design */
    font-style: italic; /* Optional for emphasizing no data */
    text-align: center; /* Center the message */
    margin-top: 10px; /* Add some spacing from the top */
    margin-bottom: 20px; /* Add some spacing from the bottom */
    line-height: 1.5; /* Improve readability with line height */
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

.loader {
  width: 20px;
  padding: 2px;
  margin-top: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: spinner 1s infinite linear;
}

@keyframes spinner {
  to {
    transform: rotate(1turn);
  }
}
</style>